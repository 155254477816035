<template>
  <div class="box">
    <div class="box__title" v-if="$slots.title">
      <slot name="title"/>
    </div>
    <div class="box__body">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextBox',
};
</script>

<style scoped lang="scss">
.box {
  &__title {
    margin-bottom: 2rem;
  }
}
</style>
