<template>
  <div class="field">
    <label class="label" :class="required ? 'is-required' : ''">{{ label }}</label>
    <div class="control">
      <input
        v-bind="$attrs"
        type="number"
        class="input"
        :value="modelValue"
        :class="(error) ? 'is-danger' : ''"
        :placeholder="label"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <p class="help is-danger" v-if="error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberType',
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>

</style>
