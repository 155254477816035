import LayoutServices from '@/app/Services/LayoutServices.vue';
import InfoTermsOfService from '@/app/Services/Views/InfoTermsOfService';
import InfoPrivacy from '@/app/Services/Views/InfoPrivacy';
import InfoVerwerkingsOvereenkomst from '@/app/Services/Views/InfoVerwerkingsOvereenkomst';
import InfoCookiePolicy from '@/app/Services/Views/InfoCookiePolicy';
import Homepage from '@/app/Services/Homepage.vue'
import NotFound from './NotFound';
import { getOGMetaTags } from '../../utils/metaTags';

export default [
  {
    path: '/info',
    component: LayoutServices,
    children: [
      {
        path: 'terms-of-use',
        component: InfoTermsOfService,
        meta: {
          description: 'Terms of service'
        },
        name: 'Terms'
      },
      {
        path: 'cookies',
        component: InfoCookiePolicy,
        meta: { description: 'Cookies' },
        name: 'Cookies'
      },
      {
        path: 'privacy',
        component: InfoPrivacy,
        meta: { description: 'Privacy' },
        name: 'Privacy'
      },
      {
        path: 'data-processing-agreement',
        component: InfoVerwerkingsOvereenkomst,
        meta: { description: 'VWO' },
        name: 'VWO'
      }
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: getOGMetaTags('Mykafka - Home'),
  },
];
