<template>
  <div class="wrapper">
    <Navigation/>
    <div class="homepage">
      <section class="hero section">
        <div class="container is-full-desktop">
          <div class="columns">
            <div class="column is-half hero__wrapper">
              <h1 class="title is-spaced hero__title is-1">
                Papierwerk, <br>da's verleden tijd.
              </h1>
              <div class="block">
                <p class="subtext has-text-weight-semibold">
                  MyKafka helpt verenigingen met het digitaliseren en automatiseren van papierwerk zoals
                  attesten voor de mutualiteit. Zo heeft u meer tijd om te focussen op de werking van uw
                  vereniging.
                </p>
              </div>
              <router-link to="/register" class="button is-primary mt-5">
                Digitaliseer nu
              </router-link>
              <ul class="hero__points p-0">
                <li>
                  <font-awesome-icon icon="check-circle" class="mr-2"/>
                  Makkelijk te gebruiken</li>
                <li>
                  <font-awesome-icon icon="check-circle" class="mr-2"/>
                  Bespaart talloze uren</li>
                <li>
                  <font-awesome-icon icon="check-circle" class="mr-2"/>
                  Privacy vriendelijk
                </li>
              </ul>
            </div>
            <div class="column hero__image is-hidden-mobile">
              <img src="../../assets/images/mykafka-homepage.jpeg" alt="MyKafka - Startpagina - pen en papier" class="has-border-radius">
            </div>
          </div>
        </div>
      </section>
      <section class="section section--social">
        <div class="level">
          <div class="level-item has-text-centered" v-for="count in counters" :key="count.number">
            <div>
              <p class="title" v-html="count.number"></p>
              <p class="heading">{{ count.text }}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="section mission" id="about">
        <div class="container">
          <div class="columns">
            <div class="column mission__image is-5">
              <span class="has-border-radius"></span>
              <img src="../../assets/images/mykafka-about.jpeg" alt="MyKafka - onze missie" class="has-border-radius">
            </div>
            <div class="column">
              <div class="intro">
                <p class="subtitle">onze missie</p>
                <h3 class="title is-spaced">
                  Met MyKafka willen we overbodige administratie wegwerken met behulp van onze eenvoudige en toegankelijke applicaties.
                </h3>
              </div>
              <div class="block">
                <p class="subtext">
                  MyKafka wil als betrouwbare partner organisaties ondersteunen om hun papierwerk tot een minimum te
                  herleiden. Het toegankelijk maken van administratieve vereenvoudigingen is dan ook ons doel.
                </p>
                <p class="subtext">
                  Dit doel willen we bereiken door een efficiënte en stabiele digitale oplossing te bieden die helpt met het
                  uitschijven van attesten. Zo hopen we Kafka tegen te gaan, en onszelf overbodig te maken.
                </p>
                <hr>
                <p class="is-bold is-italic">Het MyKafka team.<br>Lennert, Viktor, Nicolas en Bert</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section section--testimonials testimonials">
        <div class="container is-fullhd">
          <div class="intro">
            <p class="subtitle">Verenigingen aan het woord</p>
            <h3 class="title mb-5">
              Nog niet overtuigd?
              Ontdek hoe deze verenigingen hun papierwerk wegwerkte dankzij MyKafka.
            </h3>
          </div>
          <div class="columns">
            <div class="column">
              <TestimonialCard
                text="Dankzij MyKafka krijgen kunnen ouders zelfstandig attesten voor de mutualiteit opmaken."
                author="Scouts den 92ste Aartselaar - Reet"
              />
            </div>
            <div class="column">
              <TestimonialCard
                text="Door onze vele takken en activiteiten kon onze administratie soms zeer veel tijd in beslag nemen. Met het gebruik van MyKafka werd dit aanzienlijk minder."
                author="Chiro Aurora"
              />
            </div>
            <div class="column">
              <TestimonialCard
                text="MyKafka maakte onze administratie een stuk eenvoudiger waardoor wij tijd vrij kregen voor ons te focussen op onze kernactiviteit, onze ledenwerking."
                author="Gidsen Sint-Jan"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="cta">
        <div class="container is-fullhd has-text-centered has-border-radius">
          <h3 class="title">
            Start nu volledig gratis.
          </h3>
          <p>
            Uw vereniging inschrijven duurt maar enkele minuten en bespaard u talloze uren.
          </p>
          <router-link to="/register" class="button is-inverted is-rounded">
            Digitaliseer nu
          </router-link>
        </div>
      </section>
    </div>
  </div>
  <Footer/>
</template>
<script>

import Footer from '@/app/Services/Components/Navigation/Footer';
import Navigation from '@/app/Services/Components/Navigation/Navigation';
import TestimonialCard from './Components/TestimonialCard';

export default {
  name: 'Homepage',
  components: {
    TestimonialCard,
    Footer,
    Navigation
  },
  data() {
    return {
      counters: [
        {
          number: '10/09/2021',
          text: 'Succesvolle lancering'
        },
        {
          number: '175+',
          affix: '+',
          text: 'Bestanden gegenereerd'
        },
        {
          number: '&infin;',
          affix: '',
          text: 'Uren bespaard'
        },
        {
          number: '150+',
          affix: '+',
          text: 'Individuele aanvragen'
        },
      ],
    };
  },
};

</script>
<style lang='scss' scoped>
// Hero Section
// ------------
.hero {

  @include xl {
    &__wrapper {
      padding-right: 5%;
    }
  }

  &__title {
    margin-top: 10%;

    @include md {
      margin-top: 30%;
    }
  }

  &__points {
    list-style: none;
    margin-top: 2rem;

    li {
      margin-bottom: 1rem;

      svg {
        color: get-brand-color('primary', 'alt');
      }
    }
  }

  &__image {
    text-align: right;
    padding-top: 5rem;
    img {
      height: 600px;
      margin-top: 5rem;
    }
  }
}

#logo {
  width: 50%;
}

// Socialproof Section
// -------------------
.section--social {
  padding: 0 1.5rem;

  .level-item {
    margin: 2rem 0;
  }

  @include md {
    padding: 3rem 1.5rem;
    .level-item {
      margin: 0;
    }
  }
}

// Mission Section
// ---------------
.mission {
  margin: 1rem 0;

  .intro {
    margin: 5rem 0 2rem 0;
  }

  .block {
    padding: 1rem 0;
  }

  hr {
    width: 150px;
    border: none;
    border-top: .5px solid #bfbfbf;
  }

  &__image {
    position: relative;
    display: inline-block;

    img {
      z-index: 2;
      position: relative;
      height: 600px;
      object-fit: fill;
    }

    span {
      position: absolute;
      top: -30px;
      left: -30px;
      display: block;
      width: 70px;
      height: 160px;
      background: darken(get-brand-color('primary', 'dark'), 30%);
      z-index: 1;
    }
  }
}

// Steps Section
// -------------
.section--steps {
  margin: 1rem 0;
  padding: 4rem 0;
  background-color: get-brand-color('primary', 'light');
}

.steps {
  .intro {
    text-align: center;
    margin: 3rem auto 4rem auto;

    h3 {
      width: 50%;
      margin: auto;
    }

    p {
      margin-bottom: 2rem;
      text-transform: uppercase;
      font-weight: 600;
      font-size: .9rem;
    }
  }

  .level {
    justify-content: center;
    align-items: start;
    margin-bottom: 3rem;
  }

  &__item {
    max-width: 30%;

    .block {
      h3 {
        margin: 2rem 0 1rem 0;
      }

      p {
        width: 80%;
        margin: auto;
      }
    }
  }
}

// Testimonials Section
// --------------------
.testimonials {
  .intro {
    margin-bottom: 3rem;
    h3 {
      width: 75%;
    }
  }
}

// CTA Section
// -----------
.cta {
  margin: 3rem 0;
  .container {
    padding: 5rem 0;
    background: rgb(57,54,139);
    background: linear-gradient(235deg, rgba(57,54,139,1) 20%, rgba(7,5,69,1) 100%);
    color: $white;
  }

  p {
    margin-bottom: 2rem;
  }

  h3 {
    color: $white;
  }
}

// Shared
// ------
.subtitle {
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .9rem;
}

</style>
