/**
 * LOCALE STORAGE KEYS
 * mk_user_input
 * mk_selected_organization
 * mk_user
 */

/**
 * Get item from LocalStorage by key
 * @param key
 * @returns {string}
 */
export const getLocalStorageVariable = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

/**
 * Store item in LocalStorage
 * @param key
 * @param value
 */
export const setLocalStorageVariable = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Remove item from LocalStorage
 * @param key
 */
export const clearLocalStorageVariable = (key) => {
  localStorage.clear(key);
}
