<template>
  <div class="content">
    <h1 id="algemene-voorwaarden">Verwerkersovereenkomst</h1>

    <blockquote>
      TUSSEN <strong>MyKafka feitelijke vereniging</strong> met maatschappelijke zetel te Paul Gilsonlaan 33, 2650 Edegem.
      Hierna genoemd de <strong>Verwerker</strong>.
      <br><br>
      EN <strong>Geselecteerde vereniging</strong> met maatschappelijke zetel te <strong>Adres vereniging</strong>, en indien ingeschreven in de Kruis­punt­bank voor Ondernemingen terug te vinden onder het nummer <strong>KBO vereniging</strong>.
      Hierna genoemd de <strong>Verwerkingsverantwoordelijke</strong>.
      <br><br>
      De Verwerker en de Verwerkingsverantwoordelijkeworden hierna gezamenlijk de <strong>Partijen</strong> en afzonderlijk een <strong>Partij</strong> genoemd.
    </blockquote>

    <h3>1. DEFINITIES</h3>

    <ol>
      <li>
        <strong>Derde</strong>: een natuurlijke persoon of rechtspersoon, een overheidsinstantie, een dienst of een ander orgaan, niet zijnde de Betrokkene, noch de Verwerkingsverantwoordelijke, noch de Verwerker, noch de personen die onder rechtstreeks gezag van de Verwerkingsverantwoordelijke of de Verwerker gemachtigd zijn om de Persoonsgegevens te verwerken.
      </li>
      <li>
        <strong>Heersende Wetgeving</strong>: de Algemene Verordening Gegevensbescherming (GDPR), de Wet van 8 december 1992 tot bescherming van de persoonlijke levenssfeer ten opzichte van de verwerking van persoonsgegevens (zoals gewijzigd) (hierna &#39;de Wet van 8 december 1992&#39;) en de andere relevante vigerende wettelijke voorschriften.
      </li>
      <li>
        <strong>Hoofdovereenkomst</strong>: De contractuele relatie tussen Partijen op basis waarvan Verwerker Diensten verleent aan Verwerkingsverantwoordelijke en daarbij Persoonsgegevens verwerkt, dewelke kan bestaan – afhankelijk van de concrete situatie – op basis van de algemene voorwaarden van de Verwerker, dan wel op basis van de raamovereenkomst afgesloten tussen partijen.
      </li>
      <li>
        <strong>Inbreuk in verband met Persoonsgegevens</strong>: een inbreuk op de beveiliging die per ongeluk of op onrechtmatige wijze leidt tot de vernietiging, het verlies, de wijziging of de ongeoorloofde verstrekking van of de ongeoorloofde toegang tot doorgezonden, opgeslagen of anderszins verwerkte gegevens.
      </li>
      <li>
        <strong>Ontvanger</strong>: een natuurlijke persoon of rechtspersoon, een overheidsinstantie, een dienst of een ander orgaan, al dan niet een derde, aan wie/waaraan de Persoonsgegevens worden verstrekt.
      </li>
      <li>
        <strong>Persoonsgegevens</strong>: alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon (hierna genoemd de &#39; **Betrokkene**&#39;); als identificeerbaar wordt beschouwd een natuurlijke persoon die direct of indirect kan worden geïdentificeerd, met name aan de hand van een identificator zoals een naam, een identificatienummer, locatiegegevens, een online identificator of van een of meer elementen die kenmerkend zijn voor de fysieke, fysiologische, genetische, psychische, economische, culturele of sociale identiteit van die natuurlijke persoon.
      </li>
      <li>
        <strong>Toestemming van de Betrokkene</strong>: elke vrije, specifieke, geïnformeerde en ondubbelzinnige wilsuiting waarmee de Betrokkene door middel van een verklaring of een ondubbelzinnige actieve handeling de Verwerking van zijn Persoonsgegevens aanvaardt.
      </li>
      <li>
        <strong>Verwerker</strong>: een natuurlijke persoon of rechtspersoon, een overheidsinstantie, een dienst of een ander orgaan die/dat ten behoeve van de Verwerkingsverantwoordelijke Persoonsgegevens verwerkt.
      </li>
      <li>
        <strong>Verwerking</strong>: een bewerking of een geheel van bewerkingen met betrekking tot Persoonsgegevens of een geheel van Persoonsgegevens, al dan niet uitgevoerd via geautomatiseerde procedés, zoals het verzamelen, vastleggen, ordenen, structureren, opslaan, bijwerken of wijzigen, opvragen, raadplegen, gebruiken, verstrekken door middel van doorzending, verspreiden of op andere wijze ter beschikking stellen, aligneren of combineren, afschermen, wissen of vernietigen van gegevens.
      </li>
      <li>
        <strong>Verwerkingsverantwoordelijke</strong>: een natuurlijke persoon of rechtspersoon, een overheidsinstantie, een dienst of een ander orgaan die/dat, alleen of samen met anderen, het doel van en de middelen voor de Verwerking van Persoonsgegevens vaststelt.
      </li>
    </ol>

    <h3>VERWEGENDE DAT</h3>

    <ol>
      <li>
        De Verwerker actief is als interface tussen leden en de vereniging waarvan leden deel uitmaakt met als uitsluitende bedoeling administratieve documenten ten behoeve van leden te verstrekken in verband met lidmaatschap van de vereniging.
      </li>
      <li>
        De Verwerker treedt op als Verwerker van de aangeleverde Persoonsgegevens door de Verwerkingsverantwoordelijke.
      </li>
      <li>
        De Verwerkingsverantwoordelijke verantwoordelijk is voor de aan de Verwerker verstrekte en te verstrekken Persoonsgegevens. De Verwerker verwerkt de Persoonsgegevens slechts in opdracht van de Verwerkingsverantwoordelijke.
      </li>
      <li>
        De Verwerker de passende technische en organisatorische maatregelen biedt opdat de Verwerking aan de vereisten van de Heersende Wetgeving voldoet en de bescherming van de rechten van de Betrokkene worden gewaarborgd.
      </li>
      <li>
        Partijen de tussen hen onafhankelijke samenwerking wensen te formaliseren middels onderhavige overeenkomst (hierna de <strong>Verwerkersovereenkomst</strong>). De samenwerking kenmerkt zich door de afwezigheid van enige band van ondergeschiktheid, en dit overeenkomstig de volgende voorwaarden en modaliteiten.
      </li>
    </ol>

    <h3>WORDT OVEREENGEKOMEN EN AANVAARD HETGEEN VOLGT</h3>

    <h5>Artikel 1: Voorwerp</h5>

    <p>
      De Verwerkingsverantwoordelijke gaat met de Verwerker een Verwerkersovereenkomst aan en Partijen zullen elkaar in het kader van deze Verwerkersovereenkomst ondersteunen en bijstaan bij <strong>de Verwerking van Persoonsgege</strong> (hierna de <strong>Opdracht</strong>).
    </p>

    <p>
      De Verwerker kan de volgende <strong>persoonsgegevens</strong> <em>(bv. naam, voornaam, rijksregisternummer, telefoonnummer, adres, e-mailadres, rekeningnummer, afbeelding, gezinssamenstelling, opleiding, functie, etc.)</em> van de volgende <strong>betrokkenen</strong> <em>(bv. klanten, klantcontactpersonen, werknemers, leveranciers van de verwerkingsverantwoordelijke.)</em> verwerken op basis van volgende <strong>rechtsgronden</strong> <em>(toestemming van de betrokkene, noodzakelijkheid voor de uitvoering van de overeenkomst, wettelijke verplichting, bescherming van vitale belangen, taak van algemeen belang of gerechtvaardigd belang)</em> voor volgende <strong>doeleinden</strong> <em>(bv. om te voldoen aan wettelijke verplichtingen (klantenbeheer, etc.), om de overeenkomst te kunnen uitvoeren, om de betrokkene te voorzien van informatieve nieuwsberichten m.b.t. de onderneming, om de kwaliteit van diensten of informatie te verbeteren, etc.)</em>
    </p>

    <table>
      <thead>
      <tr>
        <th><strong>Betrokkenen</strong></th>
        <th><strong>Persoonsgegevens</strong></th>
        <th><strong>Rechtsgrond</strong></th>
        <th><strong>Doeleinde</strong></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Leden van de Verwerkingsverantwoordelijke</td>
        <td>Naam, voornaam, adres, e-mailadres, status lidmaatschap, deelname ledenactiviteiten</td>
        <td>Noodzakelijkheid voor de uitvoering van een overeenkomst</td>
        <td>Om de overeenkomst tussen partijen naar behoren te kunnen uitvoeren</td>
      </tr>
      <tr>
        <td><em>Verantwoordelijke</em> van de Verwerkingsverantwoordelijke</td>
        <td>Naam, voornaam, adres, e-mailadres</td>
        <td>Noodzakelijkheid voor de uitvoering van een overeenkomst</td>
        <td>Om de overeenkomst tussen partijen naar behoren te kunnen uitvoeren</td>
      </tr>
      </tbody>
    </table>

    <ol>
      <li>
        De Verwerkingsverantwoordelijke zorgt ervoor dat de Persoonsgegevens van de Betrokkene(n) op geldige wijze zijn verkregen en hij Toestemming van de Betrokkene(n) voor de Verwerking heeft verkregen. De Verwerker gaat de geldigheid van de Toestemming niet na en kan dan ook niet aansprakelijk worden gehouden voor enig frauduleus handelen van de Verwerkingsverantwoordelijke.
      </li>
      <li>
        De Persoonsgegevens worden uitsluitend verwerkt op basis van schriftelijke instructies van de Verwerkingsverantwoordelijke, tenzij een op de Verwerker van toepassing zijnde EU-rechtelijke of lidstaatrechtelijke bepaling hem tot Verwerking verplicht.
      </li>
    </ol>

    <h5>Artikel 2: Duur en beëindiging</h5>

    <ol>
      <li>
        De looptijd van deze Verwerkersovereenkomst is wordt gesloten voor de duur van de Hoofdovereenkomst.
      </li>
      <li>
        Na beëindiging van de Verwerkersovereenkomst, en/of na beëindiging van de dienstverlening aan de Verwerkingsverantwoordelijke, is de Verwerker gehouden om na een verzoek daartoe van de Verwerkingsverantwoordelijke en uiterlijk binnen drie maanden na beëindiging van de Verwerkers-overeenkomst de door de Verwerkingsverantwoordelijke verstrekte (Persoons)gegevens te verwijderen.
      </li>
    </ol>

    <h5>Artikel 3: Beveiligingseisen</h5>

    <ol>
      <li>
        De Verwerker zal zorgdragen voor passende technische en organisatorische maatregelen om Persoonsgegevens te beveiligen tegen verlies of tegen enige vorm van onrechtmatige verwerking. De te nemen maatregelen sluiten aan bij de stand van de techniek.
      </li>
      <li>
        De Verwerker heeft op heden volgende passende technische en organisatorische maatregelen genomen ten voordele van de Verwerkingsverantwoordelijke:
        <ul>
          <li>
            uitschrijven van een GDPA overeenkomst en bijhorend veiligheidsbeleid
          </li>
          <li>
            ontsluiten van data met Auth0
          </li>
          <li>
            aanstelling van een Data Protection Officer (<a href="mailto:info@mykafka.be">dpo@mykafka.be</a>)
          </li>
        </ul>
      </li>
      <li>
        De veiligheidsmaatregelen zijn adequaat, voldoen aan de relevante standaarden en kwaliteitseisen en bieden een passend beveiligingsniveau gelet op de risico&#39;s die de Verwerking en de aard van te beschermen gegevens met zich meebrengen.
      </li>
    </ol>

    <h5>Artikel 4: Inbreuk in verband met Persoonsgegevens</h5>

    <ol>
      <li>
        Enig (vermoeden van een) datalek zal onverwijld aan de andere Partij worden gemeld teneinde de vervolgstappen te bespreken. Een en ander moet worden afgesproken in het kader van de meldingsplicht die de Verwerkingsverantwoordelijke heeft. Een dergelijk (vermoeden van een) datalek moet niet alleen door de Verwerker aan de Verwerkingsverantwoordelijke worden gemeld, maar tevens zo spoedig mogelijk door de Verwerkingsverantwoordelijkeaan de toezichthoudende autoriteit. Dit is in het belang van het stoppen van een eventueel datalek.
      </li>
      <li>
        In geval van meldingen over inbreuken in verband met Persoonsgegevens brengt de Verwerker de Verwerkingsverantwoordelijke zonder onredelijke vertraging op de hoogte, zodra hij kennis heeft genomen van een inbreuk in verband met Persoonsgegevens.De Verwerker heeft geen plicht tot melding aan de toezichthoudende autoriteit.
      </li>
      <li>
        De Verwerkingsverantwoordelijke zal op zijn beurt binnen 72 uur na vaststelling van een (vermoedelijk) datalek de toezichthoudende autoriteit hiervan op de hoogte stellen. Indien er sprake is van gevoelige data dient eveneens de Betrokkene op de hoogte te worden gesteld.
      </li>
    </ol>

    <h5>Artikel 5: Geheimhouding</h5>

    <ol>
      <li>
        Op de Verwerkerrust ingevolge de Heersende Wetgeving een wettelijke geheimhoudingsverplichting. De Verwerker is gehouden de ontvangen Persoonsgegevens als vertrouwelijk te behandelen.
      </li>
      <li>
        De Verwerker verplicht zijn (oud-) werknemers en/of onderaannemers tot geheimhouding met betrekking tot alle Persoonsgegevens waarvan zij in het kader van de levering van haar diensten kennisnemen.
      </li>
      <li>
        De verstrekte Persoonsgegevens worden door de Verwerker niet zonder voorafgaande schriftelijke toestemming van deVerwerkingsverantwoordelijke aan Derden ter beschikking gesteld, tenzij de Verwerkingsverantwoordelijke daartoe krachtens enige wetsbepaling, voorschrift of andere regelgeving verplicht is.
      </li>
      <li>
        Indien de Verwerker een verzoek of een bevel van een Belgische of buitenlandse toezichthouder ontvangt om (inzage in) (Persoons)gegevens te verschaffen, zal de Verwerker deVerwerkingsverantwoordelijke onverwijld informeren. Bij de behandeling van het verzoek of bevel zal de Verwerker alle redelijke verzoeken van deVerwerkingsverantwoordelijke in acht nemen (waaronder de instructie om de behandeling van het verzoek of bevel geheel of gedeeltelijk aan deVerwerkingsverantwoordelijke over te laten) en alle redelijkerwijs benodigde medewerking verlenen. In het geval er sprake is van een verplichting van een overheid tot geheimhouding van een verzoek of bevel zal de Verwerker trachten zo veel mogelijk in het belang van deVerwerkingsverantwoordelijke te handelen.
      </li>
    </ol>

    <h5>Artikel 6: Andere Verwerkers</h5>

    <ol>
      <li>
        De Verwerker neemt geen andere Verwerker in dienst zonder voorafgaande specifieke of algemene schriftelijke toestemming van de Verwerkingsverantwoordelijke. In het geval van algemene schriftelijke toestemming licht de Verwerkerde Verwerkingsverantwoordelijke in over beoogde veranderingen inzake de toevoeging of vervanging van andere Verwerkers, waarbij de Verwerkingsverantwoordelijke de mogelijkheid wordt geboden tegen deze veranderingen bezwaar te maken.
      </li>
      <li>
        Wanneer de Verwerkereen andere Verwerker in dienst neemt om voor rekening van de Verwerkingsverantwoordelijke specifieke verwerkingsactiviteiten te verrichten, worden aan deze andere Verwerker dezelfde verplichtingen inzake gegevensbescherming opgelegd als die welke in deze Verwerkersovereenkomst zijn opgenomen, met name de verplichting afdoende garanties met betrekking tot het toepassen van passende technische en organisatorische maatregelen te bieden opdat de Verwerking aan de Heersende Wetgeving voldoet.
      </li>
      <li>
        Wanneer de andere Verwerker zijn verplichtingen inzake gegevensbescherming niet nakomt, blijft de Verwerker ten aanzien van de Verwerkingsverantwoordelijke volledig aansprakelijk voor het nakomen van de verplichtingen van die andere Verwerker.
      </li>
    </ol>

    <h5>
      Artikel 7: Betrokkene
    </h5>

    <ol>
      <li>
        De Verwerker zal handelen naar de aanwijzingen van de Verwerkingsverantwoordelijke met betrekking tot een verzoek van een Betrokkene omtrent diens Persoonsgegevens.
      </li>
      <li>
        In het geval een Betrokkene enig verzoek doet met betrekking tot zijn of haar Persoonsgegevens aan de Verwerker, zal de Verwerker een dergelijk verzoek onverwijld doorverwijzen naar de Verwerkingsverantwoordelijke in diens rol als verantwoordelijke.
      </li>
    </ol>

    <h5>Artikel 8: Ontbinding</h5>

    <p>
      Partijen hebben het recht om de Verwerkersovereenkomst te allen tijde, met onmiddellijke ingang, zonder voorafgaande rechterlijke machtiging of ingebrekestelling en zonder betaling van enige schadevergoeding, te ontbinden in de volgende gevallen: (i) bij staking van betaling of (de aanvraag van) een faillissement of enige reorganisatie onder de Wet van 31 januari 2009 door een van de Partijen; (ii) bij vereffening of stopzetting van de activiteiten van een van de Partijen.
    </p>

    <h5>Artikel 9: Aansprakelijkheid</h5>

    <ol>
      <li>
        Verwerker kan alleen aansprakelijk worden gesteld voor een aan haar toerekenbare inbreuk op deze VWO, of de bepalingen die rechtstreeks op de VWO van toepassing zijn op grond van de toepasselijke Gegevensbeschermingswet, in zoverre de Verwerkingsverantwoordelijke heeft voldaan aan zijn eigen verplichtingen ten aanzien van de Verwerker zoals uiteengezet in deze VWO en de toepasselijke Gegevensbeschermingswet.
      </li>
      <li>
        Indien de Verwerker en de Verwerkingsverantwoordelijke gezamenlijk aansprakelijk worden gehouden door de Betrokkene, vergoedt de Verwerkingsverantwoordelijke de Betrokkene volledig. De Verwerkingsverantwoordelijke heeft recht op een vergoeding van de Verwerker voor zover er sprake is van een toewijsbare en bewezen tekortkoming van de Verwerker van de VWO of een Gegevensbeschermingswet die specifiek gericht op is de Verwerker, voor zover (i) de Verwerkingsverantwoordelijke heeft voldaan aan zijn eigen verplichtingen ten aanzien van de Verwerker zoals uiteengezet in deze VWO of de toepasselijke Gegevensbeschermingswetgeving en (ii) in verhouding met de impactratio van de bewezen fout van de Verwerker.
      </li>
      <li>
        De aansprakelijkheidsbepaling zoals uiteengezet in de Hoofdovereenkomst is volledig van toepassing. Indien er geen aansprakelijkheidsbepaling in de Hoofdovereenkomst staat bepaald, is de aansprakelijkheid van de Verwerker beperkt tot één keer de jaarlijkse waarde van de Hoofdovereenkomst.
      </li>
      <li>
        Ingeval van overmacht worden de verplichtingen van de Verwerker opgeschort en is de Verwerker van rechtswege bevrijd en niet gehouden tot nakoming van enige verbintenis jegens de Verwerkingsverantwoordelijke.
      </li>
    </ol>

    <h5>Artikel 10: Intellectuele eigendom</h5>

    <p>Onverminderd enig andersluidende schriftelijke afspraak, blijven alle intellectuele eigendomsrechten met betrekking tot de door de Verwerker geleverde diensten of producten eigendom van de Verwerker en worden deze in geen geval aan de Verwerkingsverantwoordelijke overgedragen.</p>

    <h5>Artikel 11: Varia</h5>

    <ol>
      <li>
        Deze Verwerkersovereenkomst vervangt alle vroegere overeenkomsten, mondeling en/of schriftelijk, betreffende het voorwerp van deze Verwerkersovereenkomst en omvat het volledige akkoord tussen Partijen. Deze Verwerkersovereenkomst kan alleen gewijzigd worden bij een schriftelijke en door de Partijen getekende overeenkomst.
      </li>
      <li>
        Partijen verbinden zich ertoe niets bekend te maken met betrekking tot deze Verwerkersovereenkomst, tenzij in geval van (i) een wettelijke of reglementaire verplichting, (ii) een gerechtelijk onderzoek, of (iii) een gerechtelijke procedure. In dit geval dient de andere Partij op voorhand ingelicht te worden over de timing en de inhoud van de mededeling.
      </li>
      <li>
        Geen enkele Partij bij deze Verwerkersovereenkomst kan geacht worden afstand te hebben gedaan van een recht of aanspraak die zij heeft onder of ten gevolge van deze Verwerkersovereenkomst tenzij deze afstand schriftelijk is meegedeeld.
      </li>
      <li>
        Indien enige verbintenis of modaliteit in deze Verwerkersovereenkomst onafdwingbaar zou zijn of strijdig met een bepaling van dwingend recht, zal deze onafdwingbaarheid of ongeldigheid de geldigheid en afdwingbaarheid niet beïnvloeden van andere bepalingen in de Verwerkersovereenkomst, en evenmin van dat deel van de desbetreffende bepaling dat niet strijdig is met dwingend recht. De onwettige, ongeldige of onafdwingbare bepaling zal automatisch geacht worden vervangen te zijn door een bepaling die wettig, geldig en afdwingbaar is en zo nauw mogelijk aansluit bij de intentie die aan de onwettige, ongeldige of onuitvoerbare bepaling ten grondslag lag en zal deze in gewijzigde vorm worden toegepast.
      </li>
      <li>
        De Verwerkingsverantwoordelijke mag haar rechten of verplichtingen uit deze Verwerkersovereenkomst niet overdragen zonder de voorafgaande schriftelijke toestemming van de Verwerker.
      </li>
    </ol>

    <h5>Artikel 12: Toepasselijk recht en bevoegdheid</h5>

    <p>
      Deze Verwerkersovereenkomst wordt exclusief beheerst door en geïnterpreteerd overeenkomstig het Belgisch recht.
    </p>
    <p>
      De rechtbanken van Antwerpen zijn exclusief bevoegd voor elk geschil betreffende deze Verwerkersovereenkomst.
    </p>

    <p>
      ***<br>
      Opgesteld te Edegem, op 10/09/2021.<br>
      <em>(Door beide Partijen te ondertekenen met vermelding: &quot;gelezen en goedgekeurd&quot;)</em>
    </p>

  </div>
</template>
<script>

export default {
  name: 'InfoverwerkingsOvereenkomst',
};

</script>
<style lang='scss' scoped>
h1 {
  text-decoration: underline;
  padding-bottom: 1rem;
}
</style>
