import { api } from '@/helpers/api';

const state = {};

const mutations = {};

const actions = {
  async importMembers({ commit }, payload) {
    const response = await api()
      .post('Member/ImportMembers', {
        'id': payload.id,
        'data': payload.data,
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });

    // TODO: Fix api response in future update
    if (response.status === undefined) return true;

    switch (response.status) {
      case 400:
      case 404:
        throw response.data.error;
      default:
        return response.data;
    }
  },
  async getMembers({ commit }, id) {
    const response = await api()
      .get('Member/GetMember', {
        params: { associationId: id }
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });

    if (response.status === 500) {
      throw 'Er ging iets mis. Probeer het later nog eens.';
    }

    return response;
  },
  async updateMember({ commit }, payload) {
    return await api()
      .put('Member/Update', {
        'id': payload.id,
        'firstName': payload.firstName,
        'lastName': payload.lastName,
        'birthDate': payload.birthDate,
        'memberFeePaid': payload.memberFeePaid,
        'socialFee': payload.socialFee,
        'associationUnitId': payload.associationUnitId
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
  },
  async createMember({ commit }, payload) {
    return await api()
      .post('Member/Create', {
        'id': payload.id,
        'firstName': payload.firstName,
        'lastName': payload.lastName,
        'birthDate': payload.birthDate,
        'memberFeePaid': payload.memberFeePaid,
        'socialFee': payload.socialFee,
        'associationUnitId': payload.associationUnitId
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
  },
  async deleteMember({ commit }, payload) {
    const response = await api()
      .del('Member/Delete', {
        id: payload
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
  }
};

const getters = {};

const MemberModule = {
  state,
  mutations,
  actions,
  getters
};

export default MemberModule;
