import CreateOrganizationPage from '../../views/CreateOrganizationPage';
import DashboardPage from '../../views/DashboardPage';
import DashboardMemberImport from '../../views/DashboardMemberImport';
import DashboardMembersIndex from '../../views/DashboardMembersIndex';
import DashboardOrganizationSettings from '../../views/DashboardOrganizationSettings';
import DashboardCertificateSettings from '../../views/DashboardCertificateSettings';
import DashboardUnitsIndex from '@/views/DashboardAssociationUnitsSettings';
import DashboardAssociationUnitsSettings from '@/views/DashboardAssociationUnitsSettings';

export default [
  {
    path: '/account/organization',
    component: CreateOrganizationPage,
    name: 'DashboardOrganizationCreate',
    meta: {
      ROLE_USER: true,
    }
  },
  {
    path: '/manage/:slug',
    component: DashboardPage,
    name: 'Dashboard',
    meta: {
      ROLE_USER: true,
      ACTIVE_ORGANIZATION: true
    }
  },
  {
    path: '/manage/:slug/members/import',
    component: DashboardMemberImport,
    name: 'DashboardMemberImport',
    meta: {
      ROLE_USER: true,
      ACTIVE_ORGANIZATION: true
    }
  },
  {
    path: '/manage/:slug/members/overview',
    component: DashboardMembersIndex,
    name: 'DashboardMemberIndex',
    meta: {
      ROLE_USER: true,
      ACTIVE_ORGANIZATION: true
    }
  },
  // {
  //   path: '/manage/:slug/settings',
  //   component: DashboardOrganizationSettings,
  //   name: 'DashboardOrganizationSettings',
  //   meta: {
  //     ROLE_USER: true,
  //     ACTIVE_ORGANIZATION: true
  //   }
  // },
  {
    path: '/manage/:slug/settings/certificate',
    component: DashboardCertificateSettings,
    name: 'DashboardCertificateSettings',
    meta: {
      ROLE_USER: true,
      ACTIVE_ORGANIZATION: true
    }
  },
  {
    path: '/manage/:slug/settings/units',
    component: DashboardAssociationUnitsSettings,
    name: 'DashboardAssociationUnitsSettings',
    meta: {
      ROLE_USER: true,
      ACTIVE_ORGANIZATION: true
    }
  }
];
