<template>
  <section class="hero" :class="[ size, type ]">
    <div class="hero-body">
      <div class="container is-fluid">
        <slot name="content"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroWrapper',
  props: {
    size: {
      default: 'is-medium',
      type: String
    },
    type: {
      default: '',
      type: String
    }
  },
};
</script>

<style scoped lang="scss">
.hero {
  &.light {
    background: get-brand-color('primary', 'light');
  }
  &.primary {
    background: get-brand-color('primary', 'base');
  }
  &.warning {
    background: $warning;
  }
  &.warning-light {
    background: $warning-light;
  }
  &.danger {
    background: $danger;
  }
  &.danger-light {
    background: $danger-light;
  }

  &-body {
    padding: 3rem 0;

    @include lg {
      padding: 3rem 1.5rem;
    }
  }
}
</style>
