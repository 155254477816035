import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleDown, faCheckCircle, faVolleyballBall, faGuitar, faCampground, faUserFriends, faAngleRight, faAngleLeft, faUserNinja, faUserMd, faUserAstronaut, faUserTie, faHatCowboy, faVihara, faBuilding, faRocket, faHorse, faGlobeEurope, faFlag, faArrowLeft, faDownload, faTrash, faCookieBite, faPencilAlt, faEye, faEyeSlash, faQuoteRight, faQuoteLeft, faMapSigns, faHiking, faChevronRight, faMapPin, faTable, faArrowRight, faLock, faUserPlus, faUpload, faCogs, faStamp, faDoorOpen, faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import store from './store';
import router from './router';
import App from './App.vue';
import vueCountryRegionSelect from 'vue3-country-region-select'
import VueGtag from 'vue-gtag';
import { VueCookieNext } from 'vue-cookie-next';
import { createHead } from '@vueuse/head'
import { VueCsvImportPlugin } from 'vue-csv-import';

require('@/assets/main.scss');

library.add(
  faAngleDown, fab, faCheckCircle, faVolleyballBall, faCampground, faGuitar, faUserFriends, faAngleRight, faAngleLeft, faUserNinja, faUserMd, faUserAstronaut, faUserTie, faHatCowboy, faVihara, faBuilding, faRocket, faHorse, faGlobeEurope, faFlag, faArrowLeft, faDownload, faTrash, faCookieBite, faPencilAlt, faEye, faEyeSlash, faQuoteRight, faQuoteLeft, faMapSigns, faHiking, faChevronRight, faMapPin, faTable, faArrowRight, faLock, faUserPlus, faUpload, faCogs, faStamp, faDoorOpen, faPlus
);

const head = createHead()

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vueCountryRegionSelect)
  .use(VueCookieNext)
  .use(head)
  .use(VueGtag, {
    config: { id: 'G-6GGJ5WPFF5' },
    router,
    enabled: false,
  })
  .use(VueCsvImportPlugin)
  .component("font-awesome-icon", FontAwesomeIcon)
;

app.provide('gtag', app.config.globalProperties.$gtag)

app.mount('#app');
