<template>
  <BaseDashboardLayout :page-title="organization.name">
    <template v-slot:header>
      <h1 class="title is-2">
        Hi, {{ user.firstName }}
      </h1>
      <p class="subtitle">
        Welkom op het dashboard voor <i>{{ organization.name }}</i>.
      </p>
    </template>
    <template v-slot:content>
      <section class="feed columns">
        <div class="column is-two-thirds">
          <Notification :is-removable="true" type="is-success" tag="mk-welcome-message">
            <template v-slot:content>
              <h3 class="title is-4 mb-2">
                Merci!
              </h3>
              <p class="subtext">
                Als start up hebben we veel tijd gestoken in het ontwikkelen van MyKafka. Wij zijn dan ook zeer blij met nieuwe gebruikers zoals u.  Jullie feedback vinden we zeer waardevol om MyKafka beter te maken.  Verbeterpunten, opmerkingen en vragen zijn dan ook altijd welkom!
              </p>
            </template>
          </Notification>
          <TextBox v-if="!setupStatus.memberImport">
            <template v-slot:title>
              <h4 class="title is-4">
                1. Import je leden.
              </h4>
            </template>
            <template v-slot:content>
              <p class="subtext">
                MyKafka gebruikt deze data om te valideren of een lid is ingeschreven of mee op een activiteit was. Je kan je leden makkelijk importeren via een csv.
              </p>
              <ButtonLink text="Naar ledenimport" route="DashboardMemberImport" />
            </template>
          </TextBox>
          <TextBox v-if="!setupStatus.certificateSettings">
            <template v-slot:title>
              <h4 class="title is-4">
                2. Upload je handtekening en stempel.
              </h4>
            </template>
            <template v-slot:content>
              <p class="subtext">
                Om een geldig certificaat uit te kunnen schrijven hebben we een handtekening en stempel nodig op een blanco (wit) papier.  Deze kunt u hier opladen.
              </p>
              <ButtonLink text="Naar certificaten" route="DashboardCertificateSettings" />
            </template>
          </TextBox>
          <div class="feed__end">
            <font-awesome-icon icon="lock"/>
          </div>
        </div>
        <div class="column">
          <TextBox>
            <template v-slot:content>
              <p class="subtext">
                Je groep is aangemaakt maar je moet nog enkele stappen ondernemen vooraleer je leden deze kunnen gebruiken.
              </p>
            </template>
          </TextBox>
        </div>
      </section>
    </template>
  </BaseDashboardLayout>
</template>

<script>
import BaseDashboardLayout from '../layouts/BaseDashboardLayout';
import TextBox from '../components/ui/TextBox';
import ButtonLink from '../components/ui/ButtonLink';
import TextContainer from '../components/ui/TextContainer';
import RocketIcon from '../app/Shared/Icons/RocketIcon';
import Notification from '../components/ui/Notification';
import { useStore } from 'vuex';

export default {
  name: 'DashboardPage',
  components: {
    Notification,
    RocketIcon,
    TextContainer,
    ButtonLink,
    TextBox,
    BaseDashboardLayout
  },
  setup() {
    const store = useStore();
    // Get organization & user
    const organization = store.getters.GET_USER_ORGANIZATION;
    const user = store.getters.GET_USER;

    // Check steps in organization creation
    // 1. Member import
    const setupStatus = {
      'memberImport': false,
      'certificateSettings': false,
    };

    return {
      organization,
      user,
      setupStatus
    };
  }
};
</script>

<style scoped lang="scss">
.feed {
  &__end {
    position: relative;

    &:before {
      content: "";
      background: get-gray-color('light');
      width: 100%;
      height: 2px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    color: get-gray-color('light');
    padding-top: .75rem;
    text-align: center;
  }

  .rocket-icon {
    display: inline-block;
    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      background: get-gray-color('base');
      padding: .5rem;
      text-align: center;
      border-radius: 500px;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }
}
</style>
