/**
 * SESSION STORAGE KEYS
 * mk_user_input
 * mk_selected_organization
 * mk_user
 */

/**
 * Get item from session storage by key
 * @param key
 * @returns {string}
 */
export const getSessionVariable = (key) => {
  return JSON.parse(sessionStorage.getItem(key))
}

/**
 * Store item in session storage
 * @param key
 * @param value
 */
export const setSessionVariable = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
}

/**
 * Remove item from session storage
 * @param key
 */
export const clearSessionVariable = (key) => {
  sessionStorage.clear(key);
}
