const state = {
  BUSY: false,
  PREVIOUS_ROUTE: '',
};

const mutations = {
  SET_BUSY: (state, value) => {
    state.BUSY = value;
  },
  SET_PREVIOUS_ROUTE: (state, value) => {
    state.PREVIOUS_ROUTE = value;
  },
};

const actions = {};

const getters = {
  GET_BUSY: (state) => state.BUSY,
  GET_PREVIOUS_ROUTE: (state) => state.PREVIOUS_ROUTE,
};

const Globals = {
  state,
  mutations,
  actions,
  getters
};

export default Globals;
