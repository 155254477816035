<template>
  <div class="testimonial card">
    <div class="testimonial__content">
      <span class="testimonial__quote">
        <font-awesome-icon icon="quote-left"/>
      </span>
      <p class="subtext ml-3 mr-3">
        {{ text }}
      </p>
      <span class="testimonial__quote">
        <font-awesome-icon icon="quote-right"/>
      </span>
    </div>
    <div class="testimonial__user is-italic">
      {{ author }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestimonialCard',
  props: {
    text: {
      default: '',
      type: String
    },
    author: {
      default: '',
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.testimonial {
  &__content {
    padding: 3rem 3rem 1rem 3rem;
    display: flex;
  }

  &__quote {
    color: get-brand-color('primary', 'base');
    font-size: 1.5rem;

    &:last-child {
      display: flex;
      align-items: end;
    }
  }

  &__user {
    padding: 0 3rem 2rem 3rem;
    color: get-gray-color('base');
  }

}
</style>
