<template>
  <aside class="sidebar mb-5">
    <nav class="menu" v-if="showNavigation">
      <div class="menu__item">
        <router-link :to="{ name: 'Dashboard' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="campground" class="mr-1"/>
          </span>
          Dashboard
        </router-link>
      </div>
      <div class="menu__item menu__subtitle">
        Ledenbeheer
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'DashboardMemberImport' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="user-plus" class="mr-1"/>
          </span>
          Ledenimport
        </router-link>
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'DashboardMemberIndex' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="table" class="mr-1"/>
          </span>
          Overzicht
        </router-link>
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'DashboardAssociationUnitsSettings' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="user-friends" class="mr-1"/>
          </span>
          Functies / Takken
        </router-link>
      </div>
      <div class="menu__item menu__subtitle">
        Instellingen
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'DashboardCertificateSettings' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="stamp" class="mr-1"/>
          </span>
          Certificaten
        </router-link>
      </div>
<!--      <div class="menu__item">-->
<!--        <router-link :to="{ name: 'DashboardOrganizationSettings' }" class="menu__item router-link">-->
<!--          <span class="router-link__icon">-->
<!--            <font-awesome-icon icon="cogs" class="mr-1"/>-->
<!--          </span>-->
<!--          Groepsgegevens-->
<!--        </router-link>-->
<!--      </div>-->
      <div class="menu__item menu__subtitle menu__subtitle--account">
        Account
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'Logout' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="door-open" class="mr-1"/>
          </span>
          Logout
        </router-link>
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'OrganizationSelect' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="campground" class="mr-1"/>
          </span>
          Startpagina
        </router-link>
      </div>
    </nav>
  </aside>
</template>

<script>
export default {
  name: 'NavigationSide',
  props: {
    organization: {
      type: [Object, Boolean],
      default: {}
    },
    showNavigation: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped lang="scss">
.sidebar {
  margin-top: $header-height;
  padding: 2rem 1.25rem 0 1.25rem;
  position: absolute;
  display: none;

  @include lg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .menu {
    position: fixed;
    width: calc(#{$sidebar-width} - 2.5rem);
    max-width: inherit;

    &__item {
      a {
        padding: 1rem;
      }
    }

    &__subtitle {
      padding: 0 1rem;
      margin-top: 3rem;
      margin-bottom: 1rem;
      color: $black;
      font-weight: 500;
      font-size: 1.1rem;
    }
  }

  .router-link {
    color: get-gray-color('medium');
    font-weight: 500;
    font-size: 1.1rem;
    width: 100%;
    transition: all .3s ease-in-out;
    border-radius: 10px;
    display: flex;
    justify-content: start;

    &__icon {
      width: 2rem;
    }

    &-exact-active {
      position: relative;
      color: get-brand-color('primary', 'base');
    }

    &:hover {
      color: $black;
      background: get-gray-color('light');
    }
  }

  &:before {
    content: '';
    width: $sidebar-width;
    position: fixed;
    border-right: 1px solid darken(get-gray-color('light'), 10%);
  }
}

.sidebar,
.sidebar:before {
  top: 0;
  bottom: 0;
  left: 0;
}
</style>
