import axios from 'axios';
import store from '../store';

axios.defaults.crossDomain = true;

export const api = (url, config) => {
  // Init
  const baseURL = process.env.VUE_APP_BASE_URL;
  const user = store.getters.GET_USER;

  // Get request
  const get = (route, parameters, secure = false) => {
    let options = parameters;
    if (secure) {
      options = {
        params : parameters.params,
        headers: {
          Authorization: user ? `Bearer ${user.token}` : ''
        }
      }
    }

    return axios.get(baseURL + route, options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.value;
      });
  };

  // Post request
  const post = (route, payload) => {
    return axios.post(
      baseURL + route,
      payload,
      {
        headers: {
          Authorization: user ? `Bearer ${user.token}` : ''
        }
      }
      )
      .then(response => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.value) return error.response.value;

        return error.response;
      });
  }

  // Put request
  const put = (route, payload) => {
    return axios.put(
      baseURL + route,
      payload,
      {
        headers: {
          Authorization: user ? `Bearer ${user.token}` : ''
        }
      }
    )
      .then(response => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.value) return error.response.value;

        return error.response;
      });
  };

  // Delete request
  const del = (route, payload) => {
    return axios.delete(baseURL + route,
      {
        headers: {
          Authorization: user ? `Bearer ${user.token}` : ''
        },
        data: payload
      })
      .then(response => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.value) return error.response.value;

        return error.response;
      });

  };

  // Post request
  const download = (route, payload, fileName) => {
    return axios({
      url: baseURL + route,
      method: 'POST',
      responseType: 'blob',
      headers: {
        Authorization: user ? `Bearer ${user.token}` : '',
      },
      data: payload,
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
     }).catch((error) => {
       if (error.response.status === 404) return error.response;
       if (error.response.value) return error.response.value;

       return error.response;
      });
  }

  return {
    get,
    post,
    put,
    del,
    download
  }
}
