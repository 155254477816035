<template>
  <div class="content">
    <h1 id="privacy">Privacyverklaring</h1>
    <p>
      Het doel van deze Privacyverklaring is om U, in overeenstemming met de toepasselijke Algemene Verordening Gegevensbescherming (AVG of GDPR) te informeren over de persoonsgegevens die op de website [www.MyKafka.be](http://www.MyKafka.be/) (hierna: &quot;Website&quot;) worden verwerkt.
    </p>

    <h3>1. Hoedanigheid van MyKafka</h3>
    <p>
      Zoals uiteengezet in de Algemene Voorwaarden gebruik Website, is de Website bedoeld als interface tussen u en de vereniging waarvan u deel uitmaakt met als uitsluitende bedoeling administratieve documenten ten behoeve van u te verstrekken in verband met uw lidmaatschap van de vereniging. Dienovereenkomstig treedt MyKafka dan ook op als Verwerker voor de vereniging.
    </p>

    <h3>2. Over welke gegevens gaat het?</h3>
    <p>
      De persoonsgegevens die via de Website worden verwerkt, zijn enkel de gegevens die MyKafka als verwerker voor de vereniging verwerkt en de gegevens die u zelf verstrekt om de door u gevraagde administratieve documenten op te maken.
      MyKafka verzamelt geen andere gegevens.
    </p>

    <h3>3. Met wie worden de gegevens gedeeld ?</h3>
    <p>
      De persoonsgegevens worden met geen derden gedeeld of aan geen derden doorgegeven en worden a fortiori niet buiten de EU doorgeven.
    </p>

    <h3>4. Hoe lang worden uw persoonsgegevens bewaard?</h3>
    <p>
      De bewaartermijn van uw persoonsgegevens die via de Website worden opgevraagd, worden zolang als nodig voor het doel van de verwerking van persoonsgegevens bewaard, zoals gedefinieerd in bovengenoemde artikel 1, met name voor het verstrekken van de door u gevraagde administratieve documenten.
    </p>
    <p>
      De bewaartermijn van uw persoonsgegevens die via de Website worden opgevraagd, worden zolang als nodig voor het doel van de verwerking van persoonsgegevens bewaard, zoals gedefinieerd in bovengenoemde artikel 1, met name voor het verstrekken van de door u gevraagde administratieve documenten.
    </p>
    <p>
      Uw persoonsgegevens worden vervolgens gearchiveerd om gebruikt te worden in geval van een geding of een geschil gedurende de periode tot de verjaringstermijn van toepassing op het gerelateerde doel. Vervolgens worden de gegevens geanonimiseerd of verwijderd.
    </p>

    <h3>5. Wat zijn uw rechten en hoe kan U ze uitoefenen?</h3>
    <p>
      In overeenstemming met de toepasselijke Algemene Verordening Gegevensbescherming heeft U het recht op informatie en toegang, recht op rectificatie, recht op gegevenswissing, recht op beperking van de verwerking, recht op het verkrijgen van een kopie van uw persoonsgegevens voor uw eigen doeleinden of om deze aan een aanbieder naar keuze te verstrekken (&#39;recht op overdraagbaarheid van gegevens&#39;), het recht om bezwaar te maken tegen de verwerking van uw persoonsgegevens wanneer deze worden verwerkt voor direct marketingdoeleinden of wanneer de verwerking is gebaseerd op een legitiem belang.
    </p>
    <p>
      Al deze rechten zijn van toepassing voor zover toegestaan volgens de toepasselijke Algemene Verordening Gegevensbescherming.
    </p>
    <p>
      Aangezien MyKafka optreedt als verwerker voor de vereniging waarvan u lid bent, dient ieder verzoek in verband met de uitoefening van uw rechten gericht te worden aan de vereniging die uw gegevens verwerkt.
    </p>
    <p>
      Wilt u gebruik maken van uw recht om een klacht in te dienen (art. 77 van de AVG), dan verzoeken wij u contact op te nemen met de Gegevensbeschermingsautoriteit:
      <a href="https://www.gegevensbeschermingsautoriteit.be/contact">https://www.gegevensbeschermingsautoriteit.be/contact</a>.
    </p>

  </div>
</template>
<script>

export default {
  name: 'InfoPrivacy',
};

</script>
<style lang='scss' scoped>
h1 {
  text-decoration: underline;
  padding-bottom: 1rem;
}
</style>
