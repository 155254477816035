import { createStore } from "vuex";
import inputModule from './modules/input.js';
import certificateModule from './modules/certificate.js';
import authenticationModule from './modules/authentication';
import Globals from './modules/globals';
import User from './modules/user';
import AssociationModule from '@/store/modules/association';
import AdministratorModule from '@/store/modules/administrator';
import MemberModule from '@/store/modules/member';

export default createStore({
  modules: {
    AdministratorModule,
    MemberModule,
    inputModule,
    certificateModule,
    authenticationModule,
    Globals,
    User,
    AssociationModule
  },
});
