import { api } from '@/helpers/api';
import { getLocalStorageVariable, setLocalStorageVariable } from '@/helpers/localStorageHandler';

const state = {
  USER_ORGANIZATION: {}
};

const mutations = {
  SET_USER_ORGANIZATION: (state, value) => {
    state.USER_ORGANIZATION = value;
  }
};

const actions = {
  async getOrganizationByUserId({ commit }, id) {
    const response = await api()
      .get('Association/GetAssociationByAdminId', {
        params: { id: id }
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });

    switch (response.status) {
      case 400:
      case 404:
        console.debug(response);
        break;
        // User doesn't have an organization, redirect to creation/join page
      case 500:
        throw 'Er ging iets mis. Probeer het later nog eens.';
        break;
      default:
        // Successful response only returns the body containing organization data
        commit('SET_USER_ORGANIZATION', response);
        setLocalStorageVariable('organization', response);

        return response;
    }
  },
  async getUnits({ commit }, id) {
    return await api()
      .get('Association/GetUnits', {
        params: { associationId: id }
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
  },
  async getAssociationById({ commit }, id) {
    return await api()
      .get('Association/GetAssociationById', {
        params: { id: id }
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
  },
  async updateAssociationUnit({ commit }, payload) {
    return await api()
      .put('Association/UpdateAssociationUnit', {
        'id': payload.id,
        'name': payload.name,
        'groupIndex': payload.groupIndex,
        'memberFee': payload.memberFee,
        'socialMemberFee': payload.socialMemberFee,
        'campStart': payload.campStart,
        'campEnd': payload.campEnd,
        'description': payload.description,
        'overnight': payload.overnight,
        'street': payload.street ?? '',
        'number': payload.number,
        'city': payload.city ?? '',
        'country': payload.country ?? '',
        'zipCode': parseInt(payload.zipCode) ?? '',
        'annualMemberFee': parseInt(payload.annualMemberFee) ?? '',
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
  },
  async deleteAssociationUnit({ commit }, payload) {
    const response = await api()
      .del('Association/DeleteAssociationUnit', {
        id: payload
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });

    switch (response.status) {
      case 400:
        throw response.data.error;
      case 500:
        throw 'Er ging iets mis. Probeer het later nog eens.';
      default:
        return response;
    }
  },
  async createAssociationUnitForAssociation({ commit }, payload) {
    const response = await api()
      .post('Association/CreateAssociationUnitForAssociation', payload)
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });

    switch (response.status) {
      case 400:
        throw response.data.error;
      case 500:
        throw 'Er ging iets mis. Probeer het later nog eens.';
      default:
        return response;
    }
  },
  async updateActivityForAssociationUnit({ commit }, payload) {
    const response = await api()
      .post('Association/UpdateActivityForAssociationUnit', {
        'associationUnitId': payload.id,
        'campStart': payload.campStart,
        'campEnd': payload.campEnd,
        'description': payload.description,
        'overnight': payload.overnight
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });

    switch (response.status) {
      case 400:
        throw response.data.error;
      case 500:
        throw 'Er ging iets mis. Probeer het later nog eens.';
      default:
        return response;
    }
  },
  async createAssociation({ commit }, payload) {
    const response = await api()
      .post('Association/CreateAssociation', {
        'name': payload.name.input,
        'kafkaFirstName': payload.kafkaFirstName.input,
        'kafkaLastName': payload.kafkaLastName.input,
        'kafkaMail': payload.kafkaMail.input,
        'memberFee': payload.memberFee.input,
        'socialMemberFee': payload.socialMemberFee.input ?? 0,
        'memberFeeIsSameForEachAssociation': payload.memberFeeIsSameForEachAssociation.input,
        'associationFederationId': payload.typeId.input,
        'companyNumber': payload.companyNumber.input ?? '',
        'street': payload.street.input ?? '',
        'number': payload.number.input ?? '',
        'city': payload.city.input ?? '',
        'postalCode': payload.postalCode.input ?? '',
      })
      .catch((error) => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });

    switch (response.status) {
      case 400:
        throw response.data;
      case 500:
        throw 'Er ging iets mis. Probeer het later nog eens.';
      default:
        setLocalStorageVariable('organization', response);
        commit('SET_USER_ORGANIZATION', response);

        return response;
    }
  },
  async downloadAssociationStamp({ commit }, id) {
    return await api()
      .download('Association/DownloadAssociationStamp?id=' + id, {
        params: { id: id }
      }, 'stempel.png')
      .then((response) => {
        if (response !== undefined && response.status === 404) {
          throw 'Bestand niet gevonden.';
        }
      })
      .catch((error) => {
        if (error !== undefined) throw error;
        throw 'Er ging iets mis. Probeer het later nog eens.';
      })
  },
  async downloadAssociationSignature({ commit }, id) {
    return await api()
      .download('Association/DownloadAssociationSignature?id=' + id, {
        params: { id: id }
      }, 'handtekening.png')
      .then((response) => {
        if (response !== undefined && response.status === 404) {
          throw 'Bestand niet gevonden.';
        }
      })
      .catch((error) => {
        if (error !== undefined) throw error;
        throw 'Er ging iets mis. Probeer het later nog eens.';
      })
  },
  async updateAssociationSignature({ commit }, payload) {
    const response = await api()
      .post(`Association/UpdateAssociationSignature?id=${payload.id}`, payload.data)
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
  },
  async updateAssociationStamp({ commit }, payload) {
    const response = await api()
      .post(`Association/updateAssociationStamp?id=${payload.id}`, payload.data)
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
  },
  async getAllAssociationsSlugs({ commit }) {
    return await api()
      .get('Association/GetAllAssociationsSlugs')
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
  }
};

const getters = {
  GET_USER_ORGANIZATION: (state) => {
    // Get from store
    if (state.USER_ORGANIZATION.id) {
      return state.USER_ORGANIZATION;
    }

    // Get from sessionStorage
    const organization = getLocalStorageVariable('organization');
    if (organization) {
      state.USER_ORGANIZATION = organization;
      return organization;
    }

    return null;
  }
};

const AssociationModule = {
  state,
  mutations,
  actions,
  getters
};

export default AssociationModule;
