<template>
  <form class="form-body form-attest" novalidate @submit.prevent="onSubmit()">
    <div class="form-attest__general block">
      <div class="columns">
        <div class="form-attest__title column is-12">
          <p class="title is-5">
            <font-awesome-icon icon="flag" />
             {{isNewCertificate ? "Selecteer de mutualiteit en type attest voor deze aanvraag." : "Gegevens aanpassen voor dit certificaat"}}
          </p>
        </div>
      </div>
      <div class="columns" v-if="errors">
        <div class="column">
          <p class="help is-danger" v-for="error in errors">{{ error }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="field column">
          <label class="label is-required" for="healthService">Mutualiteit</label>
          <div class="select" :class="(errors.healthService)?'is-danger':''">
            <select id="healthService" v-model="model.healthService">
              <option value="" selected hidden>-- Mutualiteit --</option>
              <option v-for="(healthService) in healthServices" :value="healthService.id">
                {{ healthService['name'] }}
              </option>
            </select>
          </div>
        </div>
        <div class="field column">
          <label class="label is-required" for="healthService">Type attest</label>
          <div class="select" :class="(errors.certificateType)?'is-danger':''">
            <select id="certificate" v-model="model.certificateType" :disabled="!getSelectedHealthServiceOptions().length">
              <option value="" selected hidden>-- Attest --</option>
              <option v-for="option in getSelectedHealthServiceOptions()" :value="option.key">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="field column is-12">
        <button type="submit" class="button is-success" value="Submit" :disabled='isDisabled'>Volgende</button>
        <button class="button is-cancel is-danger is-inverted ml-2" v-on:click="$emit('closeModal')">Terug</button>
      </div>
    </div>
  </form>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { useStore } from 'vuex';

export default {
  name: 'SelectHealthService',
  emits: ['submitGeneralInfo'],
  computed: {
    isDisabled() {
      return !(this.model.healthService && this.model.certificateType);
    },
    healthServices() {
      return this.$store.getters.GET_CERTIFICATE_RECEIVERS;
    },
    isNewCertificate(){
      return this.model.healthService === ''
    },
  },
  methods: {
    getSelectedHealthServiceOptions() {
      let selectedId = this.model.healthService;
      let optionsArr = [];

      if (selectedId) {
        this.healthServices.forEach(function(element) {
          if (element.id === selectedId) {
            element.certificates.forEach((certificate) => {
              if (certificate.type === 'Member') {
                optionsArr.push({
                  'key': 'Member',
                  'text': 'Lidmaatschap'
                });
              } else {
                optionsArr.push({
                  'key': 'Camp',
                  'text': 'Kamp'
                });
              }
            })
          }
        })
      }
      return optionsArr;
    }
  },
  setup(props, context) {
    const store = useStore();

    // If user wants to edit a certificate, prefill data with selected certificate from store
    let editCertificate =  store.getters.getSelectedCertificate;

    const model = reactive({
      healthService: editCertificate['healthService'] ? editCertificate['healthService']  : '',
      certificateType: editCertificate['certificateType'] ? editCertificate['certificateType']  : '',
    });

    const errors = reactive({
      healthService: '',
      certificateType: '',
    });
    const healthServices = store.getters.GET_CERTIFICATE_RECEIVERS;

    function onSubmit() {
      // Validate form & emit data if correct
      if (model.healthService && model.certificateType.length > 0) {
        context.emit('submitGeneralInfo', model);
      }

      if (!model.healthService) {
        errors.healthService = 'Gelieve een mutualiteit te selecteren.';
      }
      if (!model.certificateType) {
        errors.certificateType = 'Gelieve een type attest te selecteren.';
      }
    }

    return {
      model,
      onSubmit,
      healthServices,
      errors
    };
  }
};
</script>

<style scoped lang="scss">
.form-attest {

  p {
    color: get-brand-color('primary', 'dark');
  }

  .block {
    margin-bottom: 1rem;

    .columns {
      margin-bottom: 0;

      .column {
        margin-bottom: 0;
      }
    }
  }

  #certificate {
    min-width: 100%;
  }

  &__title {
    svg {
      margin-right: .5rem;
    }
  }
}
</style>
