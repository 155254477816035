<template>
  <div class="footer">
    <div class="container">
      <ul class="footer__navigation">
        <li>
          <a href="mailto:info@mykafka.be" target="_blank">
            Contact
          </a>
        </li>
        <li>
          <router-link to='/info/data-processing-agreement'>
            Verwerkingsovereenkomst
          </router-link>
        </li>
        <li>
          <router-link to='/info/privacy'>
            Privacybeleid
          </router-link>
        </li>
        <li>
          <router-link to='/info/terms-of-use'>
            Algemene voorwaarden
          </router-link>
        </li>
        <li>
          <router-link to='/info/cookies'>
            Cookie-policy
          </router-link>
        </li>
        <li>
          <ul class="icons">
            <li>
              <a href="https://www.linkedin.com/company/mykafka" target="_blank">
                <font-awesome-icon :icon="['fab', 'linkedin']" class="icon--footer" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/MyKafka" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook']" class="icon--footer" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/MyKafkaBe" target="_blank">
                <font-awesome-icon :icon="['fab', 'twitter']" class="icon--footer" />
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
  };

</script>
<style lang='scss' scoped>
  // Footer
  // ------
  .footer {
    background: none;
    margin: 50px 0 0 0;
    padding: 3rem 1.5rem;

    &__navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include lg {
        flex-direction: row;
      }

      li {
        display: inline-block;
        margin: 0 1rem;
      }

      a, p {
        display: block;
        padding: .5rem;
        color: get-gray-color('base');
        @include lg {
          padding: 1rem;
        }

        &:hover {
          color: darken(get-gray-color('base'), 10%);
        }
      }
    }

    .icons {
      list-style: none;
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      li {
        margin-right: 10px;
        a {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .icon--footer {
            color: get-gray-color('base');
            font-size: 1.2rem;
            transition: color .2s linear;
          }
          &:hover {
            .icon--footer {
              color: darken(get-gray-color('base'), 10%);
            }
          }
        }
      }
    }
  }
</style>
