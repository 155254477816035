<template>
  <div class="body__main">
    <h1 class="title is-3">Aanvraag aanmaken <span
      v-if="organizationName">{{ organizationName }}</span></h1>
    <p class="subtext">Klik op 'attest aanvragen' onder "Uw aanvragen" en voeg de ledengegevens in.
      <br>Herhaal deze stap indien u attesten wil aanvragen voor meerdere leden.</p>
    <h3 class="title is-5">Uw aanvragen</h3>
    <div class="requests">
      <a class="requests__item requests__item--active"
         v-for='(certificate, key) in getCertificatesFromStore()' :key='certificate'
         v-on:click="editRequest(certificate, key)">
        <div class="request">
          <button class="delete" v-on:click.stop @click="removeCertificateRequest(key)"></button>
          <div class="request__body">
            <font-awesome-icon v-bind:icon="getIcon(key)" class="request__body__icon"/>
            <p class="request__body__name">
              {{ certificate.firstname }} {{ certificate.lastname }}
            </p>
            <p class="request__body__details">
              {{ getName(certificate.healthService) }} - {{ getType(certificate.certificateType) }}
            </p>
          </div>
        </div>
      </a>
      <a v-on:click="createRequest(getCertificatesFromStore().length)" class="requests__item">
        <div class="request">
          <div class="request__body">
            <RequestIcon/>
            <p>Attest aanvragen</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import RequestIcon from '@/app/Shared/Icons/RequestIcon.vue';
import {useStore} from 'vuex'

export default {
  name: "RequestOverview",
  emits: ['addCertificateRequest'],
  components: {
    RequestIcon,
  },
  data() {
    return {
      requests: [],
    }
  },
  methods: {
    getCertificatesFromStore() {
      return this.$store.getters.getAllCertificates;
    },
    /**
     * Open certificate request create modal
     */
    createRequest(certificatesCount) {
      this.$store.commit('CLEAR_SELECTED_CERTIFICATE');
      this.$emit('addCertificateRequest', certificatesCount);
    },
    /**
     * Open certificate request edit modal and pass selected certificate
     */
    editRequest(certificate, key) {
      certificate.key = key;
      this.$store.commit('SET_SELECTED_CERTIFICATE', certificate);
      this.$emit('addCertificateRequest', key);
    },
    getType(slug) {
      if (slug === 'Member') {
        return 'Lidmaatschap';
      } else {
        return 'Kamp';
      }
    },
    getName(id) {
      const healthServices = this.$store.getters.GET_CERTIFICATE_RECEIVERS;
      if (healthServices.length > 0) {
        let healthServiceName = healthServices.filter(healthService => {
          return healthService.id === id;
        })

        return healthServiceName[0].name;
      }
      return '';
    },
    getIcon(index) {
      switch (index) {
        case 0:
          return 'user-ninja';
        case 1:
          return 'user-astronaut';
        case 2:
          return 'hat-cowboy';
        default:
          return 'user-tie';
      }
    },
    removeCertificateRequest(key) {
      // Remove certificate
      let certificatesArr = this.$store.getters.getAllCertificates;
      certificatesArr.splice(key, 1)

      sessionStorage.removeItem('user_certificates');

      this.$store.commit('SET_CERTIFICATES', certificatesArr);
      sessionStorage.setItem("user_certificates", JSON.stringify(certificatesArr));
    }
  },
  /**
   * Get all certificates from store, if that's empty, check session storage
   */
  setup() {
    const store = useStore();
    let certificates = store.getters.getAllCertificates;

    if (certificates.length === 0 && sessionStorage.getItem("user_certificates")) {
      let sessionStoredCertificates = sessionStorage.getItem("user_certificates");
      sessionStoredCertificates = JSON.parse(sessionStoredCertificates);

      // Add (again) each certificate to the store
      if (sessionStoredCertificates.length > 0) {
        sessionStoredCertificates.forEach(function (certificateRequest) {
          store.dispatch('addCertificateRequestToStoreOnly', {
            certificateRequest: certificateRequest
          });
        })
      }
    }

    // Fetch organization name from store
    let organizationName = store.getters.getOrganizationName;

    if (!organizationName) {
      let organization = sessionStorage.getItem("selected_organization");

      if (organization !== null) {
        organization = JSON.parse(organization);
        organizationName = organization['name'];
      }
    }

    return {organizationName}
  }
};
</script>

<style scoped lang="scss">

.body__main {
  h1 {
    span {
      color: rgb(165, 170, 181);
      font-size: 1.5rem;
    }
  }

  .subtext {
    @include lg {
      margin-right: 25%;
    }

    margin-bottom: 50px;
  }

  .requests {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 1rem;

    @include lg {
      grid-template-columns: 30% 30% 30%;
    }

    &__item {
      background: #FFFFFF;
      box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.03);
      border-radius: 10px;
      transition: box-shadow 0.3s ease-in-out;

      &--active {
        background: get-brand-color('primary', 'base');

        .request {
          border: 1px solid $white;
          background: none;
          position: relative;

          &:hover {
            .delete {
              padding: .75rem;
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      &:hover {
        box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.08);
      }
    }

    .delete {
      z-index: 10;
      position: absolute;
      top: 5px;
      right: 5px;

      @include lg {
        transition: all .05s ease-in;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .request {
    margin: 8px;
    border-radius: 10px;
    height: 160px;
    display: flex;

    align-items: flex-end;
    justify-content: center;

    // verry cheeesy fix for dotted border
    background: linear-gradient(to right, #a5aab5 33%, #fff 0%) top/20px .5px repeat-x,
    linear-gradient(#a5aab5 33%, #fff 0%) right/1px 20px repeat-y,
    linear-gradient(to right, #a5aab5 33%, #fff 0%) bottom/20px 1px repeat-x,
    linear-gradient(#a5aab5 33%, #fff 0%) left/1px 20px repeat-y;

    &__body {
      text-align: center;
      color: get-gray-color('base');
      margin-bottom: 2rem;

      &__name {
        color: $white;
      }

      &__details {
        margin-top: 0 !important;
        font-size: .8rem;
      }

      &__icon {
        font-size: 2rem;
        color: $white;
      }

      p {
        margin-top: 10px;
      }
    }
  }
}
</style>
