<template>
  <div class="wrapper">
    <div class="main">
      <div class="container columns is-mobile">
        <div class="column is-8-tablet is-12-mobile">
          <div class="body">
            <FormSteps :active-step=1 />
            <h1 class="title is-3">Welkom,</h1>
            <p class="subtext about">
              MyKafka vergemakkelijkt het uitschrijven van attest voor organisaties en hun leden.
              <br>
              Na het doorlopen van de verschillende stappen kunt u uw attest downloaden.</p>
            <article class="message is-info">
              <div class="message-body">
                Beste bezoeker,
                <br/><br/>
                De naderende strengere GDPR-richtlijnen hebben een flinke impact op MyKafka. Voor ons kleine team vrijwilligers wordt het steeds uitdagender om aan de snel veranderende eisen te voldoen. Daarom hebben we besloten om na dit jaar het project on-hold te zetten.
                <br/><br/>
                MyKafka is ontstaan met als doel jeugdbewegingen te ondersteunen bij hun administratieve overhead, zodat ze zich meer kunnen concentreren op hun ledenwerking.
                We blijven actief zoeken naar andere manieren om jullie op dit gebied te blijven ondersteunen!<br/><br/>
                Bedankt voor het vertrouwen, <br/>
                <i>MyKafka</i>
              </div>
            </article>
            <hr class="divider">
            <div class="notification is-danger is-light" v-if="errorMessage">
              <p>{{ errorMessage }}</p>
            </div>
            <p class="subtext" v-else>Start uw aanvraag door te klikken op uw organisatie.</p>
            <div class="associations">
              <button class="button button__spinner is-outlined is-primary is-loading" v-if="isBusy">Loading</button>
              <AssociationSelectOption @click="handleAssociationSelected(association)" v-for="association in associations" :association="association" />
            </div>
            <p class="subtext">Niet de vereniging die u zocht? </p>
            <p class="subtext"> Controleer of u op de juiste url zit of neem contact op met uw vereniging.</p>
<!--            <p class="subtext mt-2 subtext&#45;&#45;promo">-->
<!--              Kan jouw organisatie ook wat digitalisering gebruiken? <router-link :to="{ name: 'Register' }">Maak vrijblijvend een account aan!</router-link>-->
<!--            </p>-->
          </div>
        </div>
        <div class="main__side column is-4 is-medium is-hidden-mobile"></div>
      </div>
    </div>
    <ConfirmDeleteData v-if="showConfirmationModal" @closeModal="toggleModal" @confirmOrganizationChange="handleAssociationSelected()" />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/app/Frontend/Components/Navigation/Footer.vue';
import FrontendForm from '@/app/Frontend/Views/RequestCreateForm';
import FormSteps from '@/app/Frontend/Components/Navigation/FormSteps';
import { useStore } from 'vuex';
import { ref } from 'vue';
import TextBox from '@/components/ui/TextBox';
import AssociationSelectOption from '@/components/flow/AssociationSelectOption';
import { getSessionVariable } from '@/helpers/sessionHandler';
import ConfirmDeleteData from '@/app/Frontend/Components/Modal/ConfirmDeleteData.';
import { useRouter } from 'vue-router';

/**
 * Organization select page. Shows a list of available organizations.
 */
export default {
  name: "OrganizationSelect",
  components: {
    ConfirmDeleteData,
    AssociationSelectOption,
    TextBox,
    FormSteps,
    FrontendForm,
    Footer,
  },
  setup() {
    document.title = 'MyKafka';
    const store = useStore();
    const router = useRouter();
    const errorMessage = ref('');
    const associations = ref({});
    const isBusy = ref(false);
    const showConfirmationModal = ref(false);
    const newAssociation = ref(false);

    // Fetch organizations
    isBusy.value = true;
    store.dispatch('getAllAssociationsSlugs')
      .then((response) => {
        associations.value = response['associations'];
      })
      .catch((error) => {
        errorMessage.value = error;
      })
      .finally(() => {
        isBusy.value = false;
      });

    function handleAssociationSelected(association) {
      // User selected a (new) association
      const previousData = getSessionVariable('user_certificates');

      if (previousData && previousData[0]['organization'] !== association.slug && !showConfirmationModal.value) {
        toggleModal();
        newAssociation.value = true;
      }

      store.dispatch('fetchOrganizationDetails', association.slug)
        .then(
          (result) => {
            // Remove all saved data if other association is selected
            if (newAssociation.value) {
              sessionStorage.removeItem('user_certificates');
              sessionStorage.removeItem('user_details');
              store.commit('clearEmail');
              store.commit('clearUsername');
              store.commit('clearOrganization');
              store.commit('CLEAR_CERTIFICATES');
            }

            store.commit('setOrganizationName', result.name)
            router.push({ name: 'OrganizationSelected', params: { slug: result.slug } })
          },
          () => {
            // Todo handle error case
          }
        );
    }

    function toggleModal() {
      showConfirmationModal.value = !showConfirmationModal.value;
      console.debug(showConfirmationModal.value);
    }

    return {
      errorMessage,
      associations,
      isBusy,
      showConfirmationModal,
      handleAssociationSelected,
      toggleModal
    };
  }
};
</script>

<style scoped lang="scss">

.wrapper {
  @include lg {
    overflow: hidden;
    padding-top: 3rem;
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .main {
    &__side {
      background: get-brand-color('primary', 'base');
    }
  }

  .container {
    @include lg {
      background: #fff;
      box-shadow: 0 0 25px 8px rgba(0,0,0,0.15);
      border-radius: 5px;
      min-height: 80vh;
    }
  }

  .footer {
    align-self: flex-end;
    justify-self: flex-end;
    width: 100%;
    margin-top: auto;
  }
}

.body {
  @include lg {
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .about {
    margin: 0 0 20px 0;
  }

  .subtext {
    @include lg {
      margin-right: 25%;

      &--promo {
        margin-right: 0;
      }
    }
  }

  .divider {
    width: 50px;
    border: none;
    margin: .5rem 0 2.5rem 0;
    border-top: 1px solid get-brand-color('primary', 'dark');
  }

  .associations {
    margin: 1rem 0;
    justify-content: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, 265px);
  }
}
</style>
