<template>
  <div class="body__main">
    <form class="form-body form-attest" novalidate @submit.prevent="onSubmit()">
      <div class="form-attest__general block">
        <div class="columns">
          <div class="form-attest__title column is-12">
            <p class="title is-5">
              <font-awesome-icon icon="rocket" />
              We zijn er bijna,
            </p>
            <p class="subtext">Voor je kan starten hebben we nog enkele gegevens nodig voor extra validatie. </p>
          </div>
        </div>
        <div class="columns" v-if="errors">
          <div class="column">
            <p class="help is-danger" v-for="error in errors">{{ error }}</p>
          </div>
        </div>
        <div class="columns">
          <div class="field column is-4">
            <label class="label is-required" for="fullName">Voor- en Achternaam</label>
            <div class="control">
              <input type="text" class="input" v-model="model.fullName" id="fullName" :class="(errors.fullName)?'is-danger':''"/>
            </div>
          </div>
          <div class="field column is-4">
            <label class="label is-required" for="email">Email</label>
            <div class="control">
              <input type="text" class="input" v-model="model.email" id="email" :class="(errors.email)?'is-danger':''"/>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="field column is-12">
          <button type="submit" class="button is-success" value="Submit" :disabled='isDisabled'>Volgende</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { useStore } from 'vuex';

export default {
  name: 'EmailForm',
  computed: {
    isDisabled() {
      return !(this.model.email && this.model.fullName);
    },
  },
  setup() {
    const store = useStore();
    const model = reactive({
      email: '',
      fullName: '',
    });
    const errors = reactive({
      email: '',
      fullName: '',
    });

    function onSubmit() {
      // Validate form & emit data if correct
      if (model.email.length > 0 && model.fullName.length > 0) {
        store.commit('setUsername', model.fullName);
        store.commit('setEmail', model.email);

        // Store in session
        sessionStorage.setItem("user_details", JSON.stringify({ 'username': model.fullName, 'email': model.email }));
      }

      if (!model.email) {
        errors.email = 'Gelieve een email-adres in te vullen.';
      }
      if (!model.fullName) {
        errors.fullName = 'Gelieve een naam in te vullen.';
      }
    }

    return {
      model,
      onSubmit,
      errors
    };
  }
}
</script>

<style scoped lang="scss">
.form-attest {

  p {
    color: get-brand-color('primary', 'dark');
  }

  .block {
    margin-bottom: 1rem;

    .columns {
      margin-bottom: 0;

      .column {
        margin-bottom: 0;
      }
    }
  }

  #certificate {
    min-width: 100%;
  }

  &__title {
    svg {
      margin-right: .5rem;
    }
  }
}
</style>
