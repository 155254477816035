const types = [
  {
    'id': 1,
    'name': 'Jeugdvereniging',
    'icon': 'campground'
  },
  {
    'id': 2,
    'name': 'Sportvereniging',
    'icon': 'volleyball-ball'
  },
  {
    'id': 3,
    'name': 'Muziekvereniging',
    'icon': 'guitar'
  },
  {
    'id': 4,
    'name': 'Andere',
    'icon': 'user-friends'
  }
];

export const getIconByTypeId = (id) => {
  if (id === null || id === undefined) id = 1;
  return types[id]['icon'];
};

export const getNameByTypeId = (id) => {
  if (id === null || id === undefined) id = 1;
  return types[id]['name'];
}

export const getOrganizationTypes = () => {
  return types;
}
