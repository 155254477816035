<template>
  <router-link :to="{ name: route }" class="button" :class="className">
    <span>{{ text }}</span>
    <span class="icon">
      <font-awesome-icon icon="arrow-right" />
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'ButtonLink',
  props: {
    route: {
      default: '',
      type: String
    },
    text: {
      default: '',
      type: String
    },
    className: {
      default: 'is-success',
      type: String
    }
  }
};
</script>

<style scoped lang="scss">
.button {
  margin-top: 1rem;
}
</style>
