<template>
<div class="notification" :class="type" v-if="isVisibile">
  <button class="delete" v-if="isRemovable" @click="hideNotification"></button>
  <div class="notification__content">
    <slot name="content"/>
  </div>
</div>
</template>

<script>
import { ref } from 'vue';
import { useCookie } from 'vue-cookie-next';

export default {
  name: 'Notification',
  props: {
    isRemovable: {
      default: false,
      type: Boolean
    },
    type: {
      default: '',
      type: String
    },
    tag: {
      default: '',
      type: String,
    }
  },
  setup(props) {
    const cookie = useCookie();
    let isVisibile = ref(true);

    // Hide notification and set cookie to prevent rendering notification again (if key is present)
    function hideNotification() {
      if (props.tag !== undefined && props.tag !== '') {
        const allowCookies = cookie.getCookie('mk-cookies-consent') === 'true';

        if (allowCookies) {
          cookie.setCookie(props.tag, 'true');
        }
      }
      isVisibile.value = false;
    }

    // Check if tag is saved in cookie
    isVisibile.value = !cookie.getCookie(props.tag)

    return {
      isVisibile,
      hideNotification
    }
  }
};
</script>

<style scoped lang="scss">
</style>
