<template>
  <div class="wrapper">
    <div class="main">
      <div class="container columns is-mobile">
        <RequestCreateForm @showDownloadPage="showDownloadPage()" v-if="showForm" />
        <DownloadOverviewPage @showFormPage="showFormPage()" v-else />
        <div class="main__side column is-4 is-medium is-hidden-mobile">
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/app/Frontend/Components/Navigation/Footer.vue';
import RequestCreateForm from './Views/RequestCreateForm';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';
import DownloadOverviewPage from '@/app/Frontend/Views/DownloadOverviewPage';

/**
 * Form overview page,
 * onMounted => Check if organization is present in store, if not, get slug from url and save correct organization in store
 */
export default {
  name: "LayoutForm",
  components: {
    DownloadOverviewPage,
    RequestCreateForm,
    Footer,
  },
  data() {
    return {
      showForm: true,
    }
  },
  methods: {
    showDownloadPage() {
      this.showForm = false;
    },
    showFormPage() {
      this.showForm = true;
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    document.title = 'MyKafka';

    onMounted(() => {
      /**
       * User refreshed or lost store => re-fetch organization details from slug
       */
      if (!store.getters.isOrganizationSelected) {
        const slug = route.params.slug;

        store.dispatch('fetchOrganizationDetails', slug);
      }
      /**
       * Get receiver data from db
       */
      if (!store.getters.GET_CERTIFICATE_RECEIVERS.length > 0) {
        store.dispatch('getReceivers');
      }
    })
  }
};
</script>

<style scoped lang="scss">

.wrapper {
  @include lg {
    padding-top: 3rem;
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
  }

  .main {
    &__side {
      background: get-brand-color('primary', 'base');
    }
  }

  .container {
    @include lg {
      background: #fff;
      box-shadow: 0 0 25px 8px rgba(0,0,0,0.15);
      border-radius: 5px;
      min-height: 80vh;
    }
  }

  .footer {
    align-self: flex-end;
    justify-self: flex-end;
    width: 100%;
    margin-top: auto;
  }
}
</style>
