import { useApi } from '../../utils/useApi';
import { getErrorFromAPI, handleApiError } from '../../utils/validationHelper';

const state = {
  IS_AUTHENTICATED: false,
};

const mutations = {
};

const actions = {
  /**
   * Resend password confirmation
   * @param commit
   * @param payload
   * @returns {Promise<*>}
   */
  async resendConfirmationMail({ commit }, payload) {
    return await useApi()
      .post(
        'Administrator/ResendConfirmEmail', { 'email': payload })
      .catch((err) => {
        throw getErrorFromAPI(err.response.status, 'resend-confirm-mail');
      });
  },

  /**
   * Register organization submit
   * - POST data to db via API
   * @param commit
   * @param payload
   * @returns {Promise<boolean>}
   */
  async createOrganizationApi({ commit }, payload) {
    const response = await useApi()
      .post(
        'Association/CreateAssociation', {
          'name': payload.name.input,
          'callSign': payload.callSign.input,
          'kafkaFirstName': payload.kafkaFirstName.input,
          'kafkaLastName': payload.kafkaLastName.input,
          'kafkaMail': payload.kafkaMail.input,
          'memberFee': payload.memberFee.input,
          'socialMemberFee': payload.socialMemberFee.input,
          'memberFeeIsSameForEachAssociation': payload.memberFeeIsSameForEachAssociation,
          'associationFederationId': payload.associationFederationId.input,
          'companyNumber': payload.companyNumber.input,
        },
        true
      )
      .catch(error => {
        throw handleApiError(error.response);
      });

    if (response && response.status === 200) {
      return true;
    }

    throw getErrorFromAPI(response.data.status);
  },
};

const getters = {};

const authenticationModule = {
  state,
  mutations,
  actions,
  getters
};

export default authenticationModule;
