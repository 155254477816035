<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column is-half footer__socials">
          <router-link to='/home' class="footer__logo">
            <img src="../../../../assets/logo-white.svg" alt="MyKafka: Documenten voor verenigingen" >
          </router-link>
          <h3 class="title">
            Papierwerk, <br>da's verleden tijd.
          </h3>
          <ul class="icons">
            <li>
              <a href="https://www.linkedin.com/company/mykafka" target="_blank">
                <font-awesome-icon :icon="['fab', 'linkedin']" class="icon--footer" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/MyKafka" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook']" class="icon--footer" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/MyKafkaBe" target="_blank">
                <font-awesome-icon :icon="['fab', 'twitter']" class="icon--footer" />
              </a>
            </li>
          </ul>
        </div>
        <div class="column">
          <ul class="footer__menu">
            <li class="footer__header">
              Algemeen
            </li>
<!--            <li>-->
<!--              <router-link to='/' class="footer__url">-->
<!--                Ons team-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link to='/' class="footer__url">-->
<!--                Voordelen-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link to='/' class="footer__url">-->
<!--                Prijzen & plannen-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link to='/' class="footer__url">-->
<!--                Maak een account-->
<!--              </router-link>-->
<!--            </li>-->
            <li>
              <router-link to='/home' class="footer__url">
                Startpagina
              </router-link>
            </li>
<!--            <li>-->
<!--              <router-link to='/home#about' class="footer__url">-->
<!--                Over ons-->
<!--              </router-link>-->
<!--            </li>-->
            <li>
              <router-link to='/' class="footer__url">
                Bestaande verenigingen
              </router-link>
            </li>
          </ul>
        </div>
        <div class="column">
          <ul class="footer__menu">
            <li class="footer__header">
              Ondersteuning
            </li>
<!--            <li>-->
<!--              <router-link to='/' class="footer__url">-->
<!--                Documentatie-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link to='/' class="footer__url">-->
<!--                Support-->
<!--              </router-link>-->
<!--            </li>-->
            <li>
              <a href="mailto:info@mykafka.be" target="_blank" class="footer__url">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div class="column">
          <ul class="footer__menu">
            <li class="footer__header">
              Legale informatie
            </li>
            <li>
              <router-link to='/info/privacy' class="footer__url">
                Privacybeleid
              </router-link>
            </li>
            <li>
              <router-link to='/info/terms-of-use' class="footer__url">
                Algemene voorwaarden
              </router-link>
            </li>
            <li>
              <router-link to='/info/data-processing-agreement' class="footer__url">
                Verwerkersovereenkomst
              </router-link>
            </li>
            <li>
              <router-link to='/info/cookies' class="footer__url">
                Cookie-policy
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <hr>
      <div class="footer__copy has-text-centered">
        ©{{ new Date().getFullYear() }} MyKafka.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};

</script>
<style lang='scss' scoped>
// Footer
// ------
.footer {
  background: get-brand-color('primary', 'darker');
  padding: 5rem 1.5rem 4rem;
  &__logo {
    img {
      width: 25px;
    }
  }
  &__socials {
    .title {
      margin: .5rem 0 3rem 0;
      color: rgba(255, 255, 255, 0.7);
    }
    .icons {
      list-style: none;
      padding: 0;
      margin: 0 0 50px 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      li {
        margin-right: 10px;
        a {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          text-align: center;
          background: rgba(255, 255, 255, .1);
          transition: background-color .2s linear;
          .icon--footer {
            color: rgba(255, 255, 255, 0.7);
            font-size: 1.2rem;
            transition: color .2s linear;
          }
          &:hover {
            .icon--footer {
              color: get-brand-color('primary', 'base');
            }
          }
        }
      }
    }
  }
  &__menu {
    li {
      margin: 0 0 1rem 0;
    }
    a {
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      transition: all .2s ease-in-out;
      &:hover {
        color: rgba(255, 255, 255, 0.7);
      }
      &.router-link-active {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  &__header {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1.25rem !important;
    color: rgba(255, 255, 255, 0.7);
  }
  &__copy {
    color: rgba(255, 255, 255, 0.7);
  }
  hr {
    border: none;
    border-top: .2px solid rgba(255, 255, 255, 0.3);
    height: 0.5px;
    background: none;
  }
}
</style>
