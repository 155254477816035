<template>
  <router-view/>
  <transition name="support" v-if="showFeedbackButton">
    <FeedbackButton />
  </transition>
  <transition name="slide">
    <CookieBanner @approveCookies="approvedClicked" v-if="showBanner" />
  </transition>
</template>
<script>

import CookieBanner from './app/Shared/Components/CookieBanner';
import { computed, inject } from 'vue';
import useCookies from './utils/useCookies';
import FeedbackButton from './app/Shared/Components/FeedbackButton';
import { useHead } from '@vueuse/head'
import { reactive } from '@vue/reactivity';

export default {
  name: 'App',
  components: { FeedbackButton, CookieBanner },
  computed: {
    showFeedbackButton() {
      return ![
        'Login',
        'Register',
        'Home'
      ].includes(this.$route.name);
    }
  },
  setup() {
    /**
     * Meta tags
     */
    const siteData = reactive({
      title: `MyKafka`,
      description: `Mykafka helpt verenigingen met het automatiseren van administratieve taken.`,
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
      ],
    })

    const gtag = inject('gtag');
    const { showBanner, cookiesApproved } = useCookies(gtag);
    const approvedClicked = () => cookiesApproved();

    return {
      showBanner,
      approvedClicked,
    }
  }
};
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

.slide-enter-active,
.slide-leave-active {
  transition: opacity .5s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
