<template>
  <div class="auth__header mb-5">
    <div class="logo is-size-mini is-centered">
      <router-link to="/">
        <img src="../../../assets/logo-full.svg" alt="MyKafka: Documenten voor verenigingen">
      </router-link>
    </div>
  </div>
  <div class="confirmation-box">
    <main id="content">
      <div class="confirmation-box__body is-centered is-size-mini mb-3">
        <div class="mb-5">
          <h1 class="title is-2" v-if="isConfirmed">Hoi <span>{{ user.firstName }}</span>,</h1>
          <h1 class="title is-2" v-else>Bijna daar,</h1>
          <div class="notification is-danger is-light" v-if="error && !isBusy">
            <p v-html="error"></p>
          </div>
          <div v-if="isConfirmed">
            <p class="subtext">Je account is succesvol aangemaakt! Hoog tijd dus om je vereniging op
              de kaart te zetten!</p>

            <div class="options">
              <router-link :to="{ name: 'DashboardOrganizationCreate' }" class="option">
                <span class="option__text">
                  <font-awesome-icon icon="hiking" class="option__text--icon"/>
                  Maak een nieuwe vereniging aan
                </span>
                <font-awesome-icon icon="chevron-right" class="option__chevron"/>
              </router-link>
              <router-link to="#" class="option is-disabled" data-tooltip="Tooltip Text">
                <span class="option__text">
                  <font-awesome-icon icon="map-pin" class="option__text--icon"/>
                  Heb je al een bestaande vereniging?
                </span>
                <font-awesome-icon icon="chevron-right" class="option__chevron"/>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { translate } from '../../../utils/translations';
import { reactive } from '@vue/reactivity';
import { setLocalStorageVariable } from '@/helpers/localStorageHandler';

export default {
  name: 'ConfirmationBox',
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    }
  },
  /**
   * Validate confirmation code
   */
  setup() {
    const store = useStore();
    const route = useRoute();
    let error = ref('Deze token is niet geldig.');
    const isConfirmed = ref(false);
    const user = reactive({});
    const userLoggedIn = store.getters.GET_USER;

    if (!userLoggedIn) {
      // Fetch query parameters
      const data = {
        'uid': route.query.uid ? route.query.uid : null,
        'code': route.query.code ? route.query.code : null,
      };

      if (data.uid == null || data.code == null) {
        error.value = 'Er liep iets fout tijdens het ophalen van je bevestigingscode. Kijk de url goed na of neem contact met ons op.';
      } else {
        store.commit('setBusy');
        store.dispatch('confirmEmail', data)
          .then((response) => {
            if (response['userInformation']) {
              error.value = '';
              user.value = response['userInformation'];
              console.debug(response);

              store.commit('SET_USER', response['userInformation']);
              setLocalStorageVariable('user', response['userInformation']);

              isConfirmed.value = true;
            }
          })
          .catch((error) => {
            error.value = 'error';
          })
          .finally(() => {
            store.commit('clearBusy');
          });
      }
    } else {
      isConfirmed.value = true;
      error.value = '';
      user.firstName = userLoggedIn.firstName;
    }

    return {
      error,
      isConfirmed,
      user
    };
  }
};
</script>

<style lang="scss" scoped>
.confirmation-box {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  flex-grow: 1;

  &__actions {
    margin-top: 25px;

    &--submit {
      margin-bottom: 2rem !important;
    }
  }

  a {
    text-decoration: underline;
  }

  .options {
    margin-top: 25px;

    .option {
      text-decoration: none;
      background: #FFFFFF;
      box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.03);
      border-radius: 10px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      margin: 1rem 0;
      padding: 1rem 2rem;
      flex: 1 1 0;
      min-width: 0;
      color: get-brand-color('primary', 'dark');
      font-family: $font-family-alt;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__text {
        &--icon {
          margin-right: 15px;
        }
      }

      &__chevron {
        transition: all .03s ease-in-out;
      }

      &:hover {
        box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.05);
        padding-right: 1.5rem;
      }

      &.is-disabled {
        opacity: .5;
        background: $grey-lightest;
        cursor: not-allowed;

        &:hover {
          padding-right: 2rem;
        }
      }
    }
  }
}

#content {
  width: 100%;
  align-self: center;
  margin-bottom: 50px;
  display: block;
}

.auth__header {
  img {
    width: 150px;
  }
}
</style>
