<template>
  <BaseDashboardLayout page-title="Leden overzicht">
    <template v-slot:hero>
      <HeroWrapper size="" type="warning" v-if="members.length === 0">
        <template v-slot:content>
          <h3 class="title is-3">
            Groei je ledenbestand
          </h3>
          <p class="subtitle">
            Tijdens het aanvragen van een attest maakt MyKafka gebruik van deze ledenlijst om na te gaan of een lid wel degelijk bestaat.
          </p>
          <ButtonLink text="Ga naar ledenimport" route="DashboardMemberImport" className="is-primary is-outlined" />
          </template>
      </HeroWrapper>
    </template>
    <template v-slot:content>
      <div class="mb-5" v-if="isBusy">
        <button v-if="isBusy" class="button button__spinner is-outlined is-primary is-loading">Loading</button>
      </div>
      <section class="member-index mb-5" v-if="!isBusy">
        <div class="columns">
          <div class="column is-8">
            <h3 class="title is-5">
              Je hebt <span class="is-primary">{{ members.length }}</span> leden in je organizatie.
            </h3>
          </div>
          <div class="column has-text-right">
            <button class="button is-success" @click="addMember">
              <span class="icon mr-1">
                <font-awesome-icon icon="plus"/>
              </span>
              Toevoegen
            </button>
          </div>
        </div>
        <TextBox v-if="selectedMember" class="members-edit notification is-light">
          <template v-slot:title>
            <h4 class="title is-4" v-if="selectedMember['id']">
              Gegevens van {{ selectedMember['firstName'] }} bewerken.
            </h4>
            <h4 class="title is-4" v-else>
              Nieuw lid aanmaken.
            </h4>
          </template>
          <template v-slot:content>
            <div class="notification is-danger is-light" v-if="submitErrorMessage">
              {{ submitErrorMessage }}
            </div>
            <form novalidate method="post" autocomplete="on" @submit.prevent="submit()">
              <div class="columns">
                <fieldset class="form-item column">
                  <TextType label="Voornaam" v-model="userInput['firstName']" :required="true"/>
                  <div class="field">
                    <label class="label is-required" for="date">Geboortedatum</label>
                    <div class="control">
                      <input type="date" v-model="userInput['birthDate']" class="input" id="date" required/>
                    </div>
                  </div>
                  <div class="field">
                    <label class="checkbox">
                      <div class="control">
                        <input
                          type="checkbox"
                          :value="userInput['memberFeePaid']"
                          :checked="userInput['memberFeePaid']"
                          v-model="userInput['memberFeePaid']"
                        />
                        Lidgeld betaald
                      </div>
                    </label>
                  </div>
                  <div class="field">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        :value="userInput['campAttendance']"
                        :checked="userInput['campAttendance']"
                        v-model="userInput['campAttendance']"
                      />
                      Kampgeld betaald
                    </label>
                  </div>
                </fieldset>
                <fieldset class="form-item column">
                  <TextType label="Achternaam" v-model="userInput['lastName']" :required="true"/>
                  <div class="field">
                    <label class="label is-required">Functie (Tak)</label>
                    <div class="select">
                      <select v-model="userInput['associationUnitId']">
                        <option value="" selected disabled hidden>-- Selecteren --</option>
                        <option v-for="(unit, key) in units" :value="key">
                          {{ unit }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="field">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        :value="userInput['socialFee']"
                        :checked="userInput['socialFee']"
                        v-model="userInput['socialFee']"
                      />
                      Sociaal tarief
                    </label>
                  </div>
                </fieldset>
              </div>
              <div class="field is-grouped">
                <button type="submit" class="button is-primary" :class="editIsBusy ? 'is-loading' : ''" value="Update" v-if="selectedMember['id']">Opslaan</button>
                <button type="submit" class="button is-primary" :class="editIsBusy ? 'is-loading' : ''" value="Create" v-else>Aanmaken</button>
                <button class="button is-cancel is-danger is-outlined ml-2" v-on:click="selectedMember = null">Sluiten</button>
              </div>
            </form>
          </template>
        </TextBox>
        <table class="table is-striped is-hoverable is-fullwidth members-table">
          <thead>
          <tr>
            <th>Id</th>
            <th>Voornaam</th>
            <th>Achternaam</th>
            <th>Geboortedatum</th>
            <th>Tak</th>
            <th>Lidgeld<br>betaald</th>
            <th>Kampgeld<br>betaald</th>
            <th>Sociaal<br>tarief</th>
            <th width="150px">Acties</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in members">
            <td>{{ row['id'] }}</td>
            <td>{{ row['firstName'] }}</td>
            <td>{{ row['lastName'] }}</td>
            <td>{{ readableDate(row['birthDate']) }}</td>
            <td>{{ units[row['associationUnitId']] }}</td>
            <td><span v-if="row['memberFeePaid']">&check;</span></td>
            <td><span v-if="row['campAttendance']">&check;</span></td>
            <td><span v-if="row['socialFee']">&check;</span></td>

            <td class="members-table__actions">
              <button class="button is-primary mr-3" @click="selectEditMember(row)">
                <font-awesome-icon icon="pencil-alt" class="edit-button__icon"/>
              </button>
              <button class="button is-danger" @click="deleteMember(row)">
                <font-awesome-icon icon="trash" class="delete-button__icon"/>
              </button>
            </td>
          </tr>
          <tr v-if="members.length === 0">
            <td colspan="9">Geen resultaten.</td>
          </tr>
          </tbody>
        </table>
      </section>
    </template>
  </BaseDashboardLayout>
</template>

<script>
import BaseDashboardLayout from '../layouts/BaseDashboardLayout';
import HeroWrapper from '../components/ui/HeroWrapper';
import ButtonLink from '../components/ui/ButtonLink';
import { useStore } from 'vuex';
import { ref } from 'vue';
import TextType from '@/components/form/TextType';
import TextBox from '@/components/ui/TextBox';
import CheckboxType from '@/components/form/CheckboxType';
import { reactive } from '@vue/reactivity';
import NumberType from '@/components/form/NumberType';
import { useRouter } from 'vue-router';
import SelectType from '@/components/form/SelectType';
import { readableDate } from '@/utils/utils';
export default {
  name: 'DashboardMembersIndex',
  components: {
    SelectType,
    NumberType,
    CheckboxType,
    TextBox,
    TextType,
    ButtonLink,
    HeroWrapper,
    BaseDashboardLayout
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isBusy = ref(true);
    const editIsBusy = ref(false);
    const errorMessage = ref('');
    const submitErrorMessage = ref('');
    const organization = store.getters.GET_USER_ORGANIZATION;
    const members = ref({});
    const units = ref({});
    const selectedMember = ref(null);
    const userInput = reactive({
      id: 0,
      firstName: '',
      lastName: '',
      birthDate: '',
      memberFeePaid: false,
      socialFee: false,
      campAttendance: false,
      associationUnitId: 0
    })

    // Fetch members from API
    store.dispatch('getMembers', organization.id)
      .then((response) => {
        if (response.members) {
          members.value = response.members;
        }
      })
      .catch((error) => {
        errorMessage.value = error;
      })

    // Fetch association units
    store.dispatch('getUnits', organization.id)
      .then((response) => {
        response.associationUnits.forEach((unit) => {
          units.value[unit.id] = unit.name;
        })
      })
      .catch((error) => {
        errorMessage.value = error;
      })
      .finally(() => {
        isBusy.value = false;
        selectedMember.value = null;
      });

    function selectEditMember(member) {
      userInput.id = member.id;
      userInput.firstName = member.firstName;
      userInput.lastName = member.lastName;
      userInput.birthDate = member.birthDate;
      userInput.memberFeePaid = member.memberFeePaid;
      userInput.socialFee = member.socialFee;
      userInput.campAttendance = member.campAttendance;
      userInput.associationUnitId = member.associationUnitId;
      selectedMember.value = member;
      submitErrorMessage.value = null;
      window.scrollTo(0, 0);
    }

    function submit() {
      editIsBusy.value = true;

      if (userInput.firstName && userInput.lastName && userInput.birthDate && userInput.associationUnitId) {
        if (userInput.id) {
          // Edit member
          store.dispatch('updateMember', userInput)
            .then(() => {
              router.go();
            })
            .catch(
              (errorString) => {
                submitErrorMessage.value = errorString;
                window.scrollTo(0, 0);
              }
            )
            .finally(() => {
              editIsBusy.value = false;
              submitErrorMessage.value = '';
            });
        } else {
          // Create member
          store.dispatch('createMember', userInput)
            .then(() => {
              router.go();
            })
            .catch(
              (errorString) => {
                submitErrorMessage.value = errorString;
                window.scrollTo(0, 0);
              }
            )
            .finally(() => {
              editIsBusy.value = false;
              submitErrorMessage.value = '';
            });
        }

      } else {
        submitErrorMessage.value = 'Gelieve de verplichte velden in te vullen.';
        editIsBusy.value = false;
      }
    }

    function deleteMember(member) {
      store.dispatch('deleteMember', member.id)
        .then(() => {
          router.go();
        })
        .catch((errorString) => {
          errorMessage.value = errorString;
        });
    }

    function addMember() {
      userInput.id = null;
      userInput.firstName = '';
      userInput.lastName = '';
      userInput.birthDate = '';
      userInput.memberFeePaid = false;
      userInput.socialFee = false;
      userInput.campAttendance = false;
      userInput.associationUnitId = 0;
      selectedMember.value = userInput;
    }

    return {
      members,
      isBusy,
      errorMessage,
      submitErrorMessage,
      selectedMember,
      units,
      userInput,
      editIsBusy,
      selectEditMember,
      submit,
      deleteMember,
      addMember,
      readableDate
    };
  }
};
</script>

<style scoped lang="scss">
.members-table {
  //display: block;
  //overflow-x: auto;
  //white-space: nowrap;

  &__actions {
    .button {
      svg {
        width: .75rem;
      }
    }
  }
}

.members-edit {
  .form-item {
    margin-bottom: .5rem;
  }
}
</style>
