<template>
  <div class="association__option">
    <font-awesome-icon :icon="icon"/>
    <p class="organization__name">
      {{ association.name }}
    </p>
    <p class="organization__location" v-if="association.city">
      {{ association.zipCode }}, {{ association.city }}
    </p>
  </div>
</template>

<script>
import { getIconByTypeId } from '@/helpers/associations';

export default {
  name: 'AssociationSelectOption',
  props: {
    association: Object,
  },
  setup(props) {
    // const icon = getIconByTypeId(props.association.type);
    const icon = getIconByTypeId(0);

    return { icon }
  }
};
</script>

<style scoped lang="scss">
.association__option {
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out;
  padding: 1rem;
  flex: 1 1 0;
  min-width: 0;
  color: get-brand-color('primary', 'dark');
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__name {
    margin: .5rem 1rem .5rem .5rem;
    font-weight: 600;
    line-height: 1.125;
    font-size: 1.25rem;
  }

  &__location {
    font-size: .9rem;
    color: get-gray-color('base');
  }

  &:hover {
    box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.08);
  }
}
</style>
