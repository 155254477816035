<template>
  <div class="modal is-active">
    <div class="modal-background" v-on:click="$emit('closeModal')"></div>
    <div class="modal-card modal__body">
      <section class="modal-card-body">
        <SelectHealthService v-if="!healthServiceSelected" @submitGeneralInfo="submitGeneralInfo" @closeModal="closeCertificateRequest" />
        <InputDataForm v-else @submitPersonalInfo="submitPersonalInfo" @closeModal="closeCertificateRequest" :certificateIndex="certificateIndex" />
      </section>
    </div>
  </div>
</template>
<script>
import SelectHealthService from '@/app/Frontend/Components/Form/SelectHealthService';
import InputDataForm from '../Form/InputDataForm';
import { reactive } from '@vue/reactivity';

export default {
  name: 'CreateCertificateForm',
  emits: ['closeModal'],
  props: {
    certificateIndex: {
      default: null,
    },
  },
  components: {
    SelectHealthService,
    InputDataForm
  },
  data() {
    return {
      userInput: {},
      healthServiceSelected: false,
    };
  },
  methods: {
    closeCertificateRequest() {
      this.$store.commit('CLEAR_SELECTED_CERTIFICATE');
      this.$emit('closeModal');
    },
    /**
     * Save general info (HealthService & TypeCertificate) in certificateRequest
     */
    submitGeneralInfo(input) {
      this.certificateRequest.healthService = input.healthService;
      this.certificateRequest.certificateType = input.certificateType;
      this.healthServiceSelected = true;
    },
    /**
     * Save personal info (Name, Address, SNN, ...) in certificateRequest
     * By the end of this, all info should be in the certificateRequest model
     * Save it in session and reset for next request
     */
    submitPersonalInfo(input) {
      this.certificateRequest.bus = input.bus;
      this.certificateRequest.date = input.date;
      this.certificateRequest.division = input.division;
      this.certificateRequest.firstname = input.firstname;
      this.certificateRequest.index = input.index;
      this.certificateRequest.lastname = input.lastname;
      this.certificateRequest.locality = input.locality;
      this.certificateRequest.number = input.number;
      this.certificateRequest.postal = input.postal;
      this.certificateRequest.socialSecurityNumber = input.socialSecurityNumber;
      this.certificateRequest.street = input.street;
      this.certificateRequest.country = input.country;
      this.certificateRequest.order = input.order;

      this.certificateRequest.organization = this.$route.params.slug;

      // Save data to store and session
      let selectedCertificate = this.$store.getters.getSelectedCertificate;
      // If a certificate is selected we are in 'edit mode'
      if (selectedCertificate.key !== undefined) {
        this.$store.dispatch('editCertificateRequest', {
          certificateRequest: this.certificateRequest,
          key: selectedCertificate.key,
        });
      } else {
        this.$store.dispatch('addCertificateRequest', {
          certificateRequest: this.certificateRequest,
        });
      }

      // Close modal
      this.$emit('closeModal');
    }
  },
  setup() {
    const certificateRequest = reactive({
      healthService: '',
      certificateType: '',
      bus: '',
      date: '',
      division: '',
      firstname: '',
      index: '',
      lastname: '',
      locality: '',
      number: '',
      postal: '',
      socialSecurityNumber: '',
      street: '',
      organization: '',
      order: 1,
    });

    return {
      certificateRequest,
    }
  }
};
</script>

<style scoped lang="scss">

.modal {
  &__body {
    border-radius: 10px;
  }

  .modal-card-body {
    padding: 2.5rem 2rem;

    .title {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
