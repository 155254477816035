import axios from 'axios';
import { ref } from 'vue';
import store from '../store';
import { getApiTokenHeaders } from './authentication';

axios.defaults.crossDomain = true;

export const useApi = () => {
  /**
   * BaseUrl: All other calls are appended to this
   */
  const baseURL = process.env.VUE_APP_BASE_URL;
  const data = ref();
  const error = ref();

  const get = (route, params) => {
    store.commit('setBusy');

    return axios.get(baseURL + route, params)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.value;
      });
  };

  const postAuthCheck = (route, data) => {
    store.commit('setBusy');

    return axios.post(baseURL + route, data)
      .then((response) => {
        store.commit('clearBusyValidation');
        return response;
      })
      .catch((error) => {
        store.commit('clearBusyValidation');
      });
  };

  const post = (route, data, authHeaders = false) => {
    store.commit('setBusy');
    const url = baseURL + route;
    const headers = (authHeaders) ? getApiTokenHeaders() : '';

    return axios.post(url, data, headers)
      .then((response) => {
        return response;
      });
  };

  const authPost = (route, data) => {
    store.commit('setBusy');

    return axios.post(baseURL + route,
      data,
      {
        withCredentials: true,
        credentials: 'include',
      })
      .then((response) => {
        return response;
      });
  };

  /**
   * Final call to generate pdf and start download
   * On init, save certificate index to store (isBusy)
   * @param data
   * @param index
   */
  const getCertificatePdf = (data, index) => {
    // Save isBusy
    let boolean = true;
    store.commit('setIsBusyByIndex', {
      index,
      boolean
    });

    // Prep filename
    let fileName = `${data['firstname']}_${data['lastname']}_${data['certificateType']}`;
    fileName = fileName.replace(/[^a-z0-9]/gi, '_')
      .toLowerCase() + '.pdf';

    // Pref model for post
    const bodyArr = {
      'associationId': store.getters.getOrganization['id'],
      'associationUnitId': data['division'],
      'certificateReceiverId': data['healthService'],
      'certificateType': data['certificateType'],
      'contactPersonName': store.getters.getUsername,
      'participantFirstName': data['firstname'],
      'participantLastName': data['lastname'],
      'birthDate': data['date'],
      'niss': data['socialSecurityNumber'],
      'street': data['street'],
      'number': parseInt(data['number']),
      'bus': (data['bus']) ? data['bus'] : 0,
      'index': (data['index']) ? data['index'] : 0,
      'zipcode': parseInt(data['postal']),
      'city': data['locality'],
      'country': data['country'],
      'email': store.getters.getEmail,
      'order': data['order'],
    };

    axios({
      url: baseURL + 'CertificateGeneration/GenerateCertificate',
      method: 'POST',
      responseType: 'blob',
      data: bodyArr,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        // Clear is busy
        let boolean = false;
        store.commit('setIsBusyByIndex', {
          index,
          boolean
        });

        return true;
      })
      .catch(error => {
        let errorString = 'Oeps, er ging iets fout. Probeer later opnieuw of neem contact op met uw vereniging.';

        store.commit('setErrorsByIndex', {
          index,
          errorString
        });

        // Clear is busy
        let boolean = false;
        store.commit('setIsBusyByIndex', {
          index,
          boolean
        });

        return false;
      });
  };

  return {
    data,
    error,
    getCertificatePdf,
    get,
    postAuthCheck,
    post,
    authPost
  };
};
