export const translate = (key) => {
  const translations = {
    'Email already exists.': 'Email bestaat al.',
    'Organization with name already exists': 'Er bestaat al een vereniging met deze naam.',
    '500': 'Oeps, er ging iets mis. Probeer het later nog eens.',
    'Admin already has an associated association': 'Oeps, je hebt al een organizatie aangemaakt.',

    // Unconfirmed
    'This field is required.': 'Dit is een verplicht veld.',
    'invalidEmail': 'Dit is geen geldig emailadres.',
    'invalidPhoneNumber': 'Dit is geen geldig gsm-nummer.',
    'maxCharLimit': 'Maximum aantal toegelaten karakters bereikt.',
    'minCharLimit': 'Dit veld moet minstens #VAR# karakters bevatten.',
    'noPasswordMatch': 'De ingevulde wachtwoorden komen niet overeen.',
    'mustContainNumber': 'Dit veld moet minstens 1 nummer bevatten.',
    'mustContainCapital': 'Dit veld moet minstens 1 hoofdletter bevatten.',
    'mustContainSpecialChar': 'Dit veld moet minstens 1 speciaal karakter bevatten.',
    'confirmationCodeNotFound': 'Er liep iets fout tijdens het ophalen van je bevestigingscode. Kijk de url goed na of neem contact met ons op.',
    'invalidConfirmationToken': 'Deze token is niet geldig.'
  };

  if (translations[key] === undefined) return translations[500];
  return translations[key];
}
