<template>
  <form class="form-body form-attest" novalidate @submit.prevent>
    <article class="message is-warning">
      <div class="message-body">
        Let op! Onze attesten zijn gebaseerd op data van het <b>voorbije jaar</b>. <br> Vul dus de
        tak/groep van het vorige jaar in.
      </div>
    </article>

    <div class="form-attest__personal block">
      <div class="columns">
        <div class="form-attest__title column is-12">
          <p class="title is-5">
            <font-awesome-icon v-bind:icon="getIcon('user')"/>
            <template v-if="fullName === ''">Voor wie is dit attest?</template>
            <template v-else>Data aanpassen voor {{ fullName }}</template>
          </p>
        </div>
      </div>
      <div class="columns" v-if="showApiValidationError.showError">
        <div class="column">
          <p class="help is-danger">
            Er is geen persoon gevonden met deze data. Controleer uw gegevens of neem contact op met
            uw vereniging.
          </p>
        </div>
      </div>
      <div class="columns" v-if="errorsPerson">
        <div class="column">
          <p class="help is-danger" v-for="error in errorsPerson">{{ error }}</p>
        </div>
      </div>

      <div class="columns">
        <div class="field column is-6">
          <label class="label is-required" for="firstname">Voornaam</label>
          <div class="control">
            <input type="text" v-model="model.firstname" class="input" id="firstname" required
                   :class="(errorsPerson.firstname)?'is-danger':''"/>
          </div>
        </div>
        <div class="field column is-6">
          <label class="label is-required" for="lastname">Achternaam</label>
          <div class="control">
            <input type="text" v-model="model.lastname" class="input" id="lastname" required
                   :class="(errorsPerson.lastname)?'is-danger':''"/>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="field column is-4">
          <label class="label is-required" for="date">Geboortedatum</label>
          <div class="control">
            <input type="date" v-model="model.date" class="input" id="date" required
                   :class="(errorsPerson.date)?'is-danger':''"/>
          </div>
        </div>
        <div class="field column is-8">
          <label class="label" for="ssn">Rijksregisternummer</label>
          <div class="control">
            <input type="text" id="ssn" class="input" v-model="model.socialSecurityNumber"
                   v-on:input="formatSSN" placeholder="xx.xx.xx-xxx.xx"
                   :class="(errorsPerson.socialSecurityNumber)?'is-danger':''" maxlength="15">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="field column is-12">
          <label class="label is-required" for="division">Tak / Groep vorig jaar:</label>
          <div class="select" :class="(errorsPerson.division)?'is-danger':''">
            <select id="division" v-model="model.division">
              <option value="" selected disabled hidden>-- Selecteren --</option>
              <option v-for="division in getOrganizationDivisions" :value="division.id">
                {{ division.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="columns mt-3">
        <div class="field column is-12">
          <label class="checkbox">
            <div class="control">
              <input
                type="checkbox"
                :value="multipleMembers"
                :checked="multipleMembers"
                v-model="multipleMembers"
              />
              Broers / zussen in deze vereniging?
            </div>
          </label>
        </div>
      </div>
      <div class="columns" v-if="multipleMembers">
        <div class="field column is-12">
          <label class="label is-required" for="division">Sorteer op volgorde van leeftijd:</label>
          <div class="select" :class="(errorsPerson.order)?'is-danger':''">
            <select id="order" v-model="model.order">
              <option value="" selected disabled hidden>-- Selecteren --</option>
              <option v-for="order in amountOfRequests" :value="order">
                <template v-if="order === 1">Oudste</template>
                <template v-else-if="order === amountOfRequests">Jongste</template>
                <template v-else>{{order}}de</template>
              </option>
            </select>
          </div>
          <p class="help mt-3">Sommige verenigingen werken met kortingregels voor broers/zussen die lid zijn van dezelfde vereniging. Hiervoor vragen wij u om de broers/zussen te rangschikken op leeftijd (oud naar jong).</p>
        </div>
      </div>
    </div>

    <div class="form-attest-address block">
      <div class="columns">
        <div class="form-attest__title column is-12">
          <p class="title is-5">
            <font-awesome-icon v-bind:icon="getIcon('house')"/>
            Op welk adres is deze persoon ingeschreven?
          </p>
        </div>
      </div>

      <div class="columns" v-if="errorsAddress">
        <div class="column">
          <p class="help is-danger" v-for="error in errorsAddress">{{ error }}</p>
        </div>
      </div>

      <div class="field" v-if="getAllCertificates.length !== 0">
        <label class="label">Adres kopiëren:</label>
        <div class="select">
          <select class="select" v-model="certificateAddress" @change="setAddress">
            <option value="" selected>Nieuw adres</option>
            <option v-for="certificate in getAllCertificates" :value="certificate">
              {{ certificate.firstname + ' ' + certificate.lastname }}
            </option>
          </select>
        </div>
      </div>

      <div class="columns">
        <div class="field column is-12">
          <label class="label is-required" for="street">Straat</label>
          <div class="control">
            <input type="text" class="input" v-model="model.street" id="street"
                   :class="(errorsAddress.street)?'is-danger':''"/>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="field column is-4">
          <label class="label is-required" for="number">Nr.</label>
          <div class="control">
            <input type="text" class="input" v-model="model.number" id="number"
                   :class="(errorsAddress.number)?'is-danger':''"/>
          </div>
        </div>
        <div class="field column is-4">
          <label class="label" for="bus">Bus</label>
          <div class="control">
            <input type="text" class="input" v-model="model.bus" id="bus"/>
          </div>
        </div>
        <div class="field column is-4">
          <label class="label" for="index">Index</label>
          <div class="control">
            <input type="text" class="input" v-model="model.index" id="index"/>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="field column is-9">
          <label class="label is-required" for="locality">Gemeente</label>
          <div class="control">
            <input type="text" class="input" v-model="model.locality" id="locality" :class="(errorsAddress.locality)?'is-danger':''"/>
          </div>
        </div>
        <div class="field column is-3">
          <label class="label is-required" for="postal">Postcode</label>
          <div class="control">
            <input type="text" class="input" v-model="model.postal" id="postal" :class="(errorsAddress.postal)?'is-danger':''"/>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="field column is-4">
          <label class="label is-required" for="postal">Land</label>
          <div class="select">
            <country-select v-model="model.country" country="BE" disablePlaceholder=false id="country" :class="(errorsAddress.country)?'is-danger':''"/>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" v-if="showApiValidationError.showError">
      <div class="column">
        <p class="help is-danger">
          Er ging iets mis. Gelieve uw gegevens te controleren.
        </p>
        <p class="help is-danger" v-for="error in errorsPerson">{{ error }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="field is-grouped column is-12">
        <button type="submit" class="button is-success" value="Submit" :disabled="!getIsValid" @click="onSubmit" v-bind:class="{'is-loading': isBusy}">Volgende
        </button>
        <button class="button is-cancel is-danger is-inverted ml-2" v-on:click="$emit('closeModal')">Terug
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import RequestIcon from '@/app/Shared/Icons/RequestIcon.vue';
import {reactive} from '@vue/reactivity';
import {useStore} from 'vuex'
import { ref } from 'vue';

export default {
  name: 'CreateCertificateForm',
  emits: ['submitPersonalInfo'],
  props: {
    certificateIndex: {
      default: null,
    },
  },
  data() {
    return {
      errors: [],
      lastChar: '',
    }
  },
  components: {
    RequestIcon,
  },
  computed: {
    getOrganizationDivisions() {
      let organization = this.$store.getters.getOrganization;
      return organization['associationUnits'];
    },
    getIsValid() {
      return this.$store.getters.getFormValidationStatus;
    },
    isBusy() {
      return this.$store.getters.getIsBusyValidation;
    },
    formatSSN() {
      let ssn = this.model.socialSecurityNumber;

      if (this.lastChar !== '.' && this.lastChar !== '-') {
        switch (ssn.length) {
          case 2:
          case 5:
          case 12:
            ssn = ssn + '.';
            break;
          case 8:
            ssn = ssn + '-';
            break;
        }
      }

      this.lastChar = ssn.slice(-1);
      this.model.socialSecurityNumber = ssn;
    },
    getAllCertificates() {
      return this.$store.getters.getAllCertificates;
    }
  },
  methods: {
    getIcon(type) {
      let iconUser = '';
      let iconHouse = '';

      switch (this.$props.certificateIndex) {
        case 0:
          iconUser = 'user-ninja';
          iconHouse = 'vihara'
          break;
        case 1:
          iconUser = 'user-astronaut';
          iconHouse = 'rocket';
          break;
        case 2:
          iconUser = 'hat-cowboy';
          iconHouse = 'faHorse';
          break;
        default:
          iconUser = 'user-tie';
          iconHouse = 'building';
      }

      if (type === 'user') {
        return iconUser;
      }
      return iconHouse;
    },
    setAddress() {
      this.model.street = this.certificateAddress.street;
      this.model.number = this.certificateAddress.number;
      this.model.bus = this.certificateAddress.bus;
      this.model.index = this.certificateAddress.index;
      this.model.locality = this.certificateAddress.locality;
      this.model.postal = this.certificateAddress.postal;
      this.model.country = this.certificateAddress.country;
    },
  },
  setup(props, context) {
    const store = useStore();
    let hasApiValidationError = false;
    let showApiValidationError = reactive({
      showError: false,
    });
    const multipleMembers = ref(false);

    // If user wants to edit a certificate, prefill data with selected certificate from store
    let editCertificate = store.getters.getSelectedCertificate;

    const model = reactive({
      firstname: editCertificate['firstname'] ? editCertificate['firstname'] : '',
      lastname: editCertificate['lastname'] ? editCertificate['lastname'] : '',
      socialSecurityNumber: editCertificate['socialSecurityNumber'] ? editCertificate['socialSecurityNumber'] : '',
      division: editCertificate['division'] ? editCertificate['division'] : '',
      date: editCertificate['date'] ? editCertificate['date'] : '',
      order: editCertificate['order'] ? editCertificate['order'] : 1,

      street: editCertificate['street'] ? editCertificate['street'] : '',
      number: editCertificate['number'] ? editCertificate['number'] : '',
      bus: editCertificate['bus'] ? editCertificate['bus'] : '',
      index: editCertificate['index'] ? editCertificate['index'] : '',
      locality: editCertificate['locality'] ? editCertificate['locality'] : '',
      postal: editCertificate['postal'] ? editCertificate['postal'] : '',
      country: editCertificate['country'] ? editCertificate['country'] : 'BE',
    });

    if (editCertificate['order']) {
      multipleMembers.value = true;
    }

    const errorsPerson = reactive({
      firstname: '',
      lastname: '',
      date: '',
      socialSecurityNumber: '',
      division: '',
      order: '',
    });
    const errorsAddress = reactive({
      street: '',
      number: '',
      bus: '',
      index: '',
      locality: '',
      postal: '',
      country: '',
    });

    const hasLetters = /[a-zA-Z]/g;
    const fullName = (model.firstname !== '') ? model.firstname + ' ' + model.lastname : '';

    // Fetch amount of current certificates to get order options
    const amountOfRequests = store.getters.getAllCertificates.length + 1;

    /**
     * Validate form and show errors if present
     */
    function onSubmit() {
      /**
       * Yikes, needs refactoring asap
       */
      let hasErrors = false;
      if (!model.firstname) {
        errorsPerson.firstname = 'Voornaam is verplicht.';
        hasErrors = true;
      } else {
        errorsPerson.firstname = '';
      }
      if (!model.lastname) {
        errorsPerson.lastname = 'Achternaam is verplicht';
        hasErrors = true;
      } else {
        errorsPerson.lastname = '';
      }
      if (!model.date) {
        errorsPerson.date = 'Geboortedatum is verplicht.';
        hasErrors = true;
      } else {
        errorsPerson.date = '';
      }
      if (!model.division) {
        errorsPerson.division = 'Groep is verplicht.';
        hasErrors = true;
      } else {
        errorsPerson.division = '';
      }
      if (!model.street) {
        errorsAddress.street = 'Straat is verplicht.';
        hasErrors = true;
      } else {
        errorsAddress.street = '';
      }
      if (!model.number) {
        errorsAddress.number = 'Nummer is verplicht.';
        hasErrors = true;
      } else {
        errorsAddress.number = '';
      }
      if (isNaN(model.number)) {
        errorsAddress.number = 'Nummer moet numeriek zijn.';
        hasErrors = true;
      } else {
        errorsAddress.number = '';
      }
      if (!model.locality) {
        errorsAddress.locality = 'Gemeente is verplicht.';
        hasErrors = true;
      } else {
        errorsAddress.locality = '';
      }
      if (!model.postal) {
        errorsAddress.postal = 'Postcode is verplicht.';
        hasErrors = true;
      } else {
        errorsAddress.postal = '';
      }
      if (isNaN(model.postal)) {
        errorsAddress.postal = 'Postcode moet numeriek zijn.';
        hasErrors = true;
      } else {
        errorsAddress.postal = '';
      }
      if (hasLetters.test(model.socialSecurityNumber)) {
        errorsPerson.socialSecurityNumber = 'Foutief rijksregisternummer.';
        hasErrors = true;
      } else {
        errorsPerson.socialSecurityNumber = '';
      }

      /**
       * Check extra validation via API call
       */
      let dataToCheck = {
        "associationId": store.getters.getOrganization['id'],
        "associationUnitId": model.division,
        "participantFirstName": model.firstname,
        "participantLastName": model.lastname,
        "birthDate": model.date
      };

      // Set validation to false to prevent submit during api call
      const apiValidationCall = store.dispatch('checkFormValidation', dataToCheck);

      apiValidationCall.then((response) => {
        if (!response.data) {
          hasApiValidationError = true;
          showApiValidationError.showError = true;
        } else {
          showApiValidationError.showError = false;
        }

        // If form is valid
        if (!hasErrors && !showApiValidationError.showError) {
          context.emit('submitPersonalInfo', model)
        }
      })
    }

    return {
      model,
      onSubmit,
      errorsPerson,
      showApiValidationError,
      fullName,
      multipleMembers,
      amountOfRequests,
      errorsAddress,
    };
  },
}
</script>

<style scoped lang="scss">
.form-attest {

  p {
    color: get-brand-color('primary', 'dark');
  }

  .block {
    margin-bottom: 3rem;

    .columns {
      margin-bottom: 0;

      .column {
        margin-bottom: 0;
      }
    }
  }

  &__personal {
    //margin-top: 3rem;
  }

  &__title {
    svg {
      margin-right: .5rem;
    }
  }
}
</style>
