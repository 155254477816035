import { api } from '@/helpers/api';
import { getLocalStorageVariable, setLocalStorageVariable } from '@/helpers/localStorageHandler';

const state = {
  USER: {}
};

const mutations = {
  SET_USER: (state, value) => {
    state.USER = value;
  }
};

const actions = {
  async authenticate({ commit }, payload) {
    const response = await api()
      .post('Administrator/authenticate', {
        'email': payload.email.input,
        'password': payload.password.input
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });

    // Check response
    switch (response.status) {
      case 400:
        throw 'Foutieve inloggegevens. Probeer het opnieuw of <a href="mailto:info@mykafka.be">neem contact op</a>.';
      case 500:
        throw 'Er ging iets mis. Probeer het later nog eens.';
      default:
        // Successful response only returns the body containing user data
        commit('SET_USER', response);
        setLocalStorageVariable('user', response);

        return response;
    }
  },
  async confirmEmail({ commit }, payload) {
    const response = await api()
      .post('Administrator/ConfirmEmail', {
        'adminId': payload.uid,
        'code': payload.code
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });
    // Check response
    if (!response['succeeded']) {
      throw 'Deze token is niet geldig.';
    } else {
      return response;
    }
  },
  async register({ commit }, payload) {
    const response = await api()
      .post('Administrator/register', {
        'email': payload.email.input,
        'password': payload.password.input,
        'firstName': payload.firstName.input,
        'lastName': payload.lastName.input,
        'userName': payload.firstName.input.charAt(0).toLowerCase() + payload.lastName.input.toLowerCase()
      })
      .catch(() => {
        throw 'Er ging iets mis. Probeer het later nog eens.';
      });

    // Check response
    switch (response.status) {
      case 400:
        throw response.data;
      case 500:
        throw 'Er ging iets mis. Probeer het later nog eens.';
      default:
        // Store user email in localStorage
        setLocalStorageVariable('register-email', payload.email.input);

        // Post new user to hubspot
        // await api()
        //   .post('https://api.hsforms.com/submissions/v3/integration/submit/25126023/b2e6dc9b-e4a1-481d-b81f-038c82b70a11', {
        //     'submittedAt': Date.now(),
        //     'fields': [
        //       {
        //         'objectTypeId': '0-1',
        //         'name': 'email',
        //         'value': payload.email.input,
        //       },
        //       {
        //         'objectTypeId': '0-1',
        //         'name': 'firstname',
        //         'value': payload.firstName.input,
        //       },
        //       {
        //         'objectTypeId': '0-1',
        //         'name': 'lastname',
        //         'value': payload.lastName.input,
        //       },
        //     ],
        //     'context': {
        //       'pageUri': window.location.href,
        //       'pageName': 'Register page'
        //     },
        //     'legalConsentOptions': {
        //       'consent': {
        //         'consentToProcess': true,
        //         'text': 'Door op "Verzenden" te klikken, maakt u een MyKafka-account aan en gaat u akkoord met de gebruiksvoorwaarden en het privacybeleid van MyKafka.',
        //       }
        //     }
        //   });

        return response;
    }
  }
};

const getters = {
  GET_USER: (state) => {
    // Get from store
    if (state.USER.id) {
      return state.USER;
    }

    // Get from sessionStorage
    const user = getLocalStorageVariable('user');
    if (user) {
      return user;
    }

    // User not found
    return null;
  }
};

const AdministratorModule = {
  state,
  mutations,
  actions,
  getters

};

export default AdministratorModule;
