<template>
  <div class="wrapper">
    <Navigation backgroundColor="transparent" />
    <div class="not-found">
      <section class="hero section">
        <div class="container is-full-desktop">
          <div class="columns">
            <div class="column">
              <p class="has-text-centered not-found__icon">
                <font-awesome-icon icon="map-signs" class="title is-1"/>
              </p>
              <h1 class="title is-3">(404) Was het nu naar links of naar rechts, ...? </h1>
              <p class="subtext">
                Deze route bestaat niet meer. Klik <router-link to="/">hier</router-link> om terug naar de startpagina te gaan.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer/>
  </div>
</template>
<script>
import Footer from './Components/Navigation/Footer';
import Navigation from './Components/Navigation/Navigation';

export default {
  name: 'NotFound',
  components: {
    Footer,
    Navigation
  }
};
</script>
<style lang='scss' scoped>
.wrapper {
  //height: 100vh;
  //background: get-brand-color('primary', 'base');
}

.not-found {
  padding: 5% 0 10% 0;
  background: get-brand-color('primary', 'base');

  &__icon {
    margin-bottom: 2rem;

    @include lg {
      margin-bottom: 5rem;
    }
  }
}

.title,
.subtext {
  color: $white;
  text-align: center;
}
</style>
