import { ref, watch, computed } from 'vue';
import { useCookie } from 'vue-cookie-next';

const allowCookies = ref();

export default function useCookies(gtag) {
  const cookie = useCookie();

  /**
   * If cookie is set, enable google analytics
   */
  if (cookie.isCookieAvailable('mk-cookies-consent')) {
    allowCookies.value = cookie.getCookie('mk-cookies-consent') === 'true';
    gtag.optIn();
  } else {
    allowCookies.value = undefined;
  }

  /**
   * If allow cookies is approved, set cookie and enable google analytics
   */
  watch(allowCookies, () => {
    if (allowCookies.value !== undefined) {
      cookie.setCookie('mk-cookies-consent', allowCookies.value.toString());
      if (allowCookies.value) {
        gtag.optIn();
      } else {
        gtag.optOut();
      }
    }
  });

  /**
   * Check if we need to show the cookie banner
   */
  const showBanner = computed(() => {
    return allowCookies.value === undefined;
  });

  /**
   * On approve, set allowCookies to true and execute watch function
   * @returns {boolean}
   */
  const cookiesApproved = () => (allowCookies.value = true);

  return {
    allowCookies,
    showBanner,
    cookiesApproved,
  };
}
