<template>
  <router-link :to="{ name: route }" class="button is-white">
    <span class="icon">
      <font-awesome-icon icon="arrow-left" />
    </span>
    <span>Terug</span>
  </router-link>
</template>

<script>
export default {
  name: 'ButtonPrevious',
  props: {
    route: {
      default: '',
      type: String
    }
  }
};
</script>

<style scoped lang="scss">
.button {
  margin-top: 1rem;
}
</style>
