<template>
  <div class="field">
    <label class="label" :class="required ? 'is-required' : ''">{{ label }}</label>
    <div class="select" :class="(error) ? 'is-danger' : ''">
      <select v-bind="$attrs" @input="$emit('update:modelValue', $event.target.value)">
        <option value="" selected disabled hidden>-- Selecteren --</option>
        <option v-for="option in options" :value="option.id">
          {{ option.name }}
        </option>
      </select>
      <p class="help is-danger" v-if="error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectType',
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: []
    }
  }
};
</script>

<style scoped>

</style>
