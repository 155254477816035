<template>
  <div class="navbar-mobile">
    <nav class="menu">
      <div class="menu__item">
        <router-link :to="{ name: 'Dashboard', params: { slug: organization.slug } }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="campground" class="mr-1"/>
          </span>
          Dashboard
        </router-link>
      </div>
      <div class="menu__item menu__subtitle">
        Ledenbeheer
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'DashboardMemberIndex' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="table" class="mr-1"/>
          </span>
          Overzicht
        </router-link>
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'DashboardMemberImport' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="user-plus" class="mr-1"/>
          </span>
          Import
        </router-link>
      </div>
      <div class="menu__item menu__subtitle">
        Instellingen
      </div>
<!--      <div class="menu__item">-->
<!--        <router-link :to="{ name: 'DashboardOrganizationSettings' }" class="menu__item router-link">-->
<!--          <span class="router-link__icon">-->
<!--            <font-awesome-icon icon="cogs" class="mr-1"/>-->
<!--          </span>-->
<!--          Groepsgegevens-->
<!--        </router-link>-->
<!--      </div>-->
      <div class="menu__item">
        <router-link :to="{ name: 'DashboardCertificateSettings' }" class="menu__item router-link">
          <span class="router-link__icon">
            <font-awesome-icon icon="stamp" class="mr-1"/>
          </span>
          Certificaten
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
  name: 'NavbarMobile',
  props: {
    organizationName: {
      type: String,
      default: ''
    }
  },
  setup() {
    const store = useStore();
    const user = store.getters.GET_USER;
    const organization = store.getters.GET_USER_ORGANIZATION;

    return {
      user,
      organization
    }
  }
};
</script>

<style scoped lang="scss">
.navbar-mobile {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $white;
  width: 100%;
  margin-top: $header-height;
  padding: 2rem 1.25rem 2rem 2rem;
  z-index: 999;

  .menu {
    position: fixed;

    &__item {
      margin-bottom: 1rem;
    }

    &__subtitle {
      margin: 2rem 0 1rem 0;
      color: $black;
      font-weight: 500;
      font-size: 1.1rem;
    }
  }

  .router-link {
    color: get-gray-color('medium');
    font-weight: 500;
    font-size: 1.1rem;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: start;
    transition: all .3s ease-in-out;

    &__icon {
      width: 2rem;
    }

    &-exact-active {
      position: relative;
      color: get-brand-color('primary', 'base');
    }

    &:hover {
      color: $black;
      background: get-gray-color('light');
    }
  }
}
</style>
