import { useApi } from '../../utils/useApi';

const state = {
  certificateRequests: [],
  selectedCertificate: {},
  CERTIFICATE_RECEIVERS: '',
}

const mutations = {
  SET_CERTIFICATES: (state, value) => {
    state.certificateRequests = value;
  },
  CLEAR_CERTIFICATES: (state) => {
    state.certificateRequests = [];
  },
  SET_SELECTED_CERTIFICATE: (state, value) => {
    state.selectedCertificate = value;
  },
  CLEAR_SELECTED_CERTIFICATE: (state) => {
    state.selectedCertificate = {};
  },
  SET_CERTIFICATE_RECEIVERS: (state, value) => {
    state.CERTIFICATE_RECEIVERS = value;
  }
}

const actions = {
  addCertificateRequest: ({ commit, state }, certificateRequest) => {
    // Add to certificates
    let certificateRequests = state.certificateRequests;

    certificateRequests.push(certificateRequest.certificateRequest);

    // Save to store and session
    commit('SET_CERTIFICATES', certificateRequests);
    sessionStorage.setItem("user_certificates", JSON.stringify(certificateRequests));
  },
  editCertificateRequest: ({ commit, state }, data) => {
    let certificate = data.certificateRequest;
    let key = data.key;

    // Fetch current certificates
    let certificateRequests = state.certificateRequests;

    // Replace old certificate with edited one
    certificateRequests[key] = certificate;

    // Save to store and session
    commit('SET_CERTIFICATES', certificateRequests);
    sessionStorage.setItem("user_certificates", JSON.stringify(certificateRequests));

    // Clear selectedCertificate
    commit('CLEAR_SELECTED_CERTIFICATE');

  },
  addCertificateRequestToStoreOnly: ({ commit, state }, certificateRequest) => {
    // Add to certificates
    let certificateRequests = state.certificateRequests;
    certificateRequests.push(certificateRequest.certificateRequest);

    // Save to store and session
    commit('SET_CERTIFICATES', certificateRequests);
  },
  getReceivers: ({ commit }) => {
    return useApi().get('CertificateReceiver/GetAllCertificateReceivers').then(
      result => {
        if (!result) {
          throw null;
        }

        commit('SET_CERTIFICATE_RECEIVERS', result['certificateReceivers']);
      },
    );
  },
}

const getters = {
  getCertificateById: (state) => {
    return state.certificateRequests.find(certificateRequest => certificateRequest.id === id);
  },
  getAllCertificates: (state) => {
    if (state.certificateRequests.length > 0) {
      return state.certificateRequests;
    }
    return [];
  },
  getSelectedCertificate: (state) => {
    return state.selectedCertificate;
  },
  GET_CERTIFICATE_RECEIVERS: (state) => {
    return state.CERTIFICATE_RECEIVERS;
  },
}

const certificateModule = {
  state,
  mutations,
  actions,
  getters
}

export default certificateModule;
