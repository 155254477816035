import OrganizationSelect from '@/app/Frontend/OrganizationSelect.vue';
import LayoutForm from '@/app/Frontend/LayoutForm';

export default [
  {
    path: '/',
    name: 'OrganizationSelect',
    component: OrganizationSelect,
  },
  {
    path: '/group/:slug',
    name: 'OrganizationSelected',
    component: LayoutForm,
  },
];
