<template>
  <BaseDashboardLayout page-title="Functies / Takken overzicht">
    <template v-slot:content>
      <div class="mb-5" v-if="isBusy.query">
        <button class="button button__spinner is-outlined is-primary is-loading">Loading</button>
      </div>
      <section class="unit-index mb-5" v-if="!isBusy.query">
        <div class="columns">
          <div class="column is-8">
            <h3 class="title is-5">
            </h3>
          </div>
          <div class="column has-text-right">
            <button class="button is-success" @click="addUnit">
              <span class="icon mr-1">
                <font-awesome-icon icon="plus"/>
              </span>
              Toevoegen
            </button>
          </div>
        </div>
        <div class="notification is-danger is-light" v-if="errorMessage && !selectedUnit">
          {{ errorMessage }}
        </div>
        <TextBox v-if="selectedUnit" class="units-edit notification is-light">
          <template v-slot:title>
            <h4 class="title is-4" v-if="selectedUnit['id']">
              Gegevens van {{ selectedUnit['name'] }} bewerken.
            </h4>
            <h4 class="title is-4" v-else>
              Nieuwe tak aanmaken.
            </h4>
          </template>
          <template v-slot:content>
            <div class="notification is-danger is-light" v-if="errorMessage">
              {{ errorMessage }}
            </div>
            <form novalidate method="post" autocomplete="on" @submit.prevent="submit()">
              <div class="columns">
                <fieldset class="form-item column">
                  <TextType label="Naam" v-model="userInput['name']" :required="true"/>
                  <NumberType label="Bedrag verminderd lidgeld" v-model="userInput['socialMemberFee']" />
                </fieldset>
                <fieldset class="form-item column">
                  <NumberType label="Bedrag lidgeld" v-model="userInput['annualMemberFee']" :required="true" />
                </fieldset>
              </div>
              <div class="columns mt-2">
                <fieldset class="form-item column">
                  <NumberType label="Bedrag kamp" v-model="userInput['memberFee']" :required="true" />
                  <div class="field">
                    <label class="label is-required" for="date">Datum kamp (start)</label>
                    <div class="control">
                      <input type="date" v-model="userInput['campStart']" class="input" required/>
                    </div>
                  </div>
                  <TextType label="Straat (kamplocatie)" v-model="userInput['street']"/>
                  <TextType label="Postcode (kamplocatie)" v-model="userInput['zipCode']"/>
                  <div class="field">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        :value="userInput['overnight']"
                        :checked="userInput['overnight']"
                        v-model="userInput['overnight']"
                      />
                      Kamp met overnachting
                    </label>
                  </div>
                </fieldset>
                <fieldset class="form-item column">
                  <TextType label="Beschrijving" v-model="userInput['description']"/>
                  <div class="field">
                    <label class="label is-required" for="date">Datum kamp (einde)</label>
                    <div class="control">
                      <input type="date" v-model="userInput['campEnd']" class="input" id="date" required/>
                    </div>
                  </div>
                  <TextType label="Huisnummer (kamplocatie)" v-model="userInput['number']"/>
                  <!--                  <TextType label="Bus (kamplocatie)" v-model="userInput['bus']" :required="true"/>-->
                  <TextType label="Stad/dorp (kamplocatie)" v-model="userInput['city']"/>
                  <TextType label="Land (kamplocatie)" v-model="userInput['country']"/>
                </fieldset>
              </div>
              <div class="field is-grouped">
                <button type="submit" class="button is-primary" :class="isBusy.edit ? 'is-loading' : ''" value="Update" v-if="selectedUnit['id']">Opslaan</button>
                <button type="submit" class="button is-primary" :class="isBusy.edit ? 'is-loading' : ''" value="Create" v-else>Aanmaken</button>
                <button type="button" class="button is-cancel is-danger is-outlined ml-2" v-on:click="selectedUnit = null;errorMessage = null">Sluiten</button>
              </div>
            </form>
          </template>
        </TextBox>

        <table class="table is-striped is-hoverable is-fullwidth units-table">
          <thead>
          <tr>
            <th>Naam</th>
            <th>Lidgeld</th>
            <th>Bedrag kamp (verminderd)<sup><a href="#info-a">a</a></sup></th>
            <th>Kamp beschrijving</th>
            <th>Kamp periode</th>
            <th>Kamp locatie</th>
            <th>Overnachting<sup><a href="#info-b">b</a></sup></th>
            <th width="150px">Acties</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="unit in associationUnits">
            <td>{{ unit['name'] }}</td>
            <td>&euro;{{ unit['annualMemberFee'] }}</td>
            <td>
              &euro;{{ unit['memberFee'] }}
              <span v-if="unit['socialMemberFee']">
                (&euro;{{ unit['socialMemberFee'] }})
              </span>
            </td>
            <td>{{ unit['activity']['description'] }}</td>
            <td>
              <span v-if="unit['activity']['startDate'] !== '0001-01-01T00:00:00'">
                {{
                  readableDate(unit['activity']['startDate'])
                }}
              </span>
              -
              <span v-if="unit['activity']['endDate'] !== '0001-01-01T00:00:00'">
                 {{
                  readableDate(unit['activity']['endDate'])
                }}
              </span>
            </td>
            <td>
              {{ unit['activity']['street'] }}&nbsp;{{ unit['activity']['number'] }} <span v-if="unit['activity']['bus']">{{ unit['activity']['bus'] }}</span>
              <br>
              {{ unit['activity']['zipCode'] }}&nbsp;{{ unit['activity']['city'] }}<span v-if="unit['activity']['country']">,&nbsp;{{ unit['activity']['country'] }}</span>
            </td>
            <td><span v-if="unit['activity']['overnight']">&check;</span></td>
            <td class="units-table__actions">
              <button class="button is-primary mr-3" @click="selectUnit(unit)">
                <font-awesome-icon icon="pencil-alt" class="edit-button__icon"/>
              </button>
              <button class="button is-danger" @click="deleteUnit(unit)">
                <font-awesome-icon icon="trash" class="delete-button__icon"/>
              </button>
            </td>
          </tr>
          <tr v-if="associationUnits.length === 0">
            <td colspan="5">Geen resultaten.</td>
          </tr>
          </tbody>
        </table>
        <p class="is-info is-italic has-text-grey">
          <span id="info-a">a. Bedrag kamp verminderd: Prijs voor leden die recht hebben op een verminderd tarief.</span>
          <br>
          <span id="info-b">b. Overnachting: Meerdaagse activiteit met overnachting.</span>
        </p>
      </section>
    </template>
  </BaseDashboardLayout>
</template>

<script>
import BaseDashboardLayout from '../layouts/BaseDashboardLayout';
import HeroWrapper from '../components/ui/HeroWrapper';
import ButtonLink from '../components/ui/ButtonLink';
import { useStore } from 'vuex';
import { ref } from 'vue';
import TextType from '@/components/form/TextType';
import TextBox from '@/components/ui/TextBox';
import CheckboxType from '@/components/form/CheckboxType';
import { reactive } from '@vue/reactivity';
import NumberType from '@/components/form/NumberType';
import { useRouter } from 'vue-router';
import SelectType from '@/components/form/SelectType';
import { dateToFormat, readableDate } from '@/utils/utils';

export default {
  name: 'DashboardAssociationUnitsSettings',
  components: {
    SelectType,
    NumberType,
    CheckboxType,
    TextBox,
    TextType,
    ButtonLink,
    HeroWrapper,
    BaseDashboardLayout
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isBusy = reactive({
      query: false,
      edit: false
    });
    const errorMessage = ref(null);
    const associationUnits = ref({});
    const selectedUnit = ref(null)
    const userInput = reactive({
      id: 0,
      groupIndex: '',
      memberFee: null,
      name: '',
      socialMemberFee: null,
      campStart: null,
      campEnd: null,
      description: '',
      overnight: false,
      street: '',
      number: '',
      bus: '',
      city: '',
      country: '',
      zipCode: null,
      annualMemberFee: null,
    })
    const organization = store.getters.GET_USER_ORGANIZATION;

    // Fetch association units
    isBusy.query = true;
    store.dispatch('getUnits', organization.id)
      .then((response) => {
        if (response.associationUnits) {
          associationUnits.value = response.associationUnits;
        }
      })
      .catch((error) => {
        errorMessage.value = error;
      })
      .finally(() => {
        isBusy.query = false;
      });

    function submit() {
      isBusy.edit = true;
      errorMessage.value = null;
      if (userInput.name) {
        if (userInput.id) {
          // Edit Unit
          store.dispatch('updateAssociationUnit', userInput)
            .then(() => {
              router.go();
            })
            .catch(
              (errorString) => {
                errorMessage.value = errorString;
                window.scrollTo(0, 0);
              }
            )
            .finally(() => {
              isBusy.edit = false;
              errorMessage.value = null;
            });
        } else {
          // Create unit
          store.dispatch('createAssociationUnitForAssociation', {
            'federationUnitName': userInput.name,
            'name': userInput.name,
            'groupIndex': 0,
            'memberFee': parseInt(userInput.memberFee),
            'socialMemberFee': userInput.socialMemberFee ? parseInt(userInput.socialMemberFee) : 0,
            'associationId': organization.id,
            'campStart': userInput.campStart,
            'campEnd': userInput.campEnd,
            'description': userInput.description,
            'overnight': userInput.overnight,
            'street': userInput.street ?? '',
            'number':  userInput.number ?? '',
            'city': userInput.city ?? '',
            'zipCode': parseInt(userInput.zipCode) ?? '',
            'country': userInput.country ?? '',
            'annualMemberFee': parseInt(userInput.annualMemberFee) ?? '',
          })
            .then(() => {
              router.go();
            })
            .catch(
              (errorString) => {
                errorMessage.value = errorString;
                window.scrollTo(0, 0);
              }
            )
            .finally(() => {
              isBusy.edit = false;
              errorMessage.value = null;
            });
        }
      } else {
        isBusy.edit = false;
        errorMessage.value = 'Gelieve de verplichte velden in te vullen.';
      }
    }

    function selectUnit(unit) {
      userInput.id = unit.id;
      userInput.groupIndex = unit.groupIndex;
      userInput.name = unit.name;
      userInput.memberFee = parseInt(unit.memberFee);
      userInput.socialMemberFee = parseInt(unit.socialMemberFee);
      userInput.campStart = dateToFormat(unit.activity.startDate, 'YYYY-MM-DD');
      userInput.campEnd = dateToFormat(unit.activity.endDate, 'YYYY-MM-DD');
      userInput.description = unit.activity.description;
      userInput.overnight = unit.activity.overnight;
      userInput.street = unit.activity.street;
      userInput.number = unit.activity.number;
      userInput.bus = unit.activity.bus;
      userInput.city = unit.activity.city;
      userInput.zipCode = unit.activity.zipCode;
      userInput.country = unit.activity.country;
      userInput.annualMemberFee = parseInt(unit.annualMemberFee);

      selectedUnit.value = unit;
      window.scrollTo(0, 0);
    }

    function deleteUnit(unit) {
      store.dispatch('deleteAssociationUnit', unit.id)
        .then(() => {
          router.go();
        })
        .catch((errorString) => {
          errorMessage.value = errorString;
          console.debug(errorString)
        });
    }

    function addUnit() {
      userInput.id = null;
      userInput.groupIndex = null;
      userInput.name = '';
      userInput.memberFee = null;
      userInput.socialMemberFee = null;
      userInput.campStart = null;
      userInput.campEnd = null;
      userInput.description = '';
      userInput.overnight = false;
      userInput.street = '';
      userInput.number = '';
      userInput.bus = '';
      userInput.city = '';
      userInput.zipCode = null;
      userInput.country = '';
      userInput.annualMemberFee = null;

      selectedUnit.value = userInput;
      errorMessage.value = null;
    }

    return {
      isBusy,
      errorMessage,
      associationUnits,
      selectedUnit,
      userInput,
      submit,
      selectUnit,
      deleteUnit,
      addUnit,
      readableDate
    };
  }
};
</script>

<style scoped lang="scss">
.units-table {
  &__actions {
    .button {
      svg {
        width: .75rem;
      }
    }
  }
}

.units-edit {
  .form-item {
    margin-bottom: .5rem;
  }
}
</style>
