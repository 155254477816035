export const getOGMetaTags = (name, description, image) => {
  return {
    title: (name !== '') ? name : 'Mykafka',
    metaTags: [
      {
        name: 'description',
        content: description ? description : ''
      },
      {
        property: 'og:description',
        content: description ? description : ''
      },
      {
        property: 'og:image',
        content: image ? image : '/img/logo-full.76294541.svg'
      },
      {
        property: 'twitter:image:src',
        content: '/img/logo-full.76294541.svg'
      },
      {
        property: 'og:image:width',
        content: '1000'
      },
      {
        property: 'og:site_name',
        content: (name !== '') ? name : 'Mykafka'
      }
    ]
  }
}
