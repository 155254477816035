<template>
  <div class="feedback" @mouseover="isHover = true" @mouseleave="isHover = false">
    <transition name="slide">
      <div class="message is-success feedback__submitted" v-if="isSubmitted">
        <p class="message-body">
          👍 Bedankt voor uw feedback!
        </p>
      </div>
    </transition>
    <div class="feedback__wrapper" :class="(isClicked) ? ' form-active' : ''">
      <div class="feedback__content notification" v-if="isClicked">
        <button class="delete" @click="isClicked = false; isHover = false"></button>
        <div class="feedback__content__text">
          <p class="title is-5">Feedback of suggesties?</p>
          <p class="subtext">Heeft u een fout gevonden of tips voor het verbeteren van onze applicatie? Laat het hier ons weten.</p>
        </div>
        <div class="feedback__content__form">
          <div class="field">
            <label class="label is-required" for="type">Type</label>
            <div class="select">
              <select id="type" v-model="supportInput.type">
                <option value="suggestion">Suggestie</option>
                <option value="problem">Probleem</option>
                <option value="other">Andere</option>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="label" for="email">Email</label>
            <div class="control">
              <input type="email" class="input" v-model="supportInput.email" id="email"/>
            </div>
          </div>
          <div class="field">
            <label for="text" class="label is-required">Bericht</label>
            <textarea id="text" class="textarea has-fixed-size" v-model="supportInput.message" placeholder="..." :class="(supportErrors.message)?'is-danger':''"></textarea>
            <p class="help is-danger" v-if="supportErrors.message">{{ supportErrors.message }}</p>
          </div>
          <div class="field">
            <button type="submit" class="button is-danger mr-2 is-outlined" @click="isClicked = false; isHover = false">Sluiten</button>
            <button type="submit" class="button is-info" value="Submit" @click="onSubmit()">Verzenden</button>
          </div>
        </div>
      </div>
      <div class="feedback__trigger" @click="isClicked = true" v-else>
        <p v-if="isHover">Feedback/Suggesties</p>
        <WavingHandsIcon class="feedback__icon"/>
      </div>
    </div>
  </div>
</template>
<script>
import WavingHandsIcon from '@/app/Shared/Icons/WavingHandsIcon';
import { reactive } from '@vue/reactivity';
import { useApi } from '@/utils/useApi';
export default {
  name: 'FeedbackButton',
  components: { WavingHandsIcon },
  data() {
    return {
      isHover: false,
      isClicked: false,
      isSubmitted: false,
    }
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    }
  },
  methods: {
    onSubmit() {
      if (this.supportInput.message.length === 0) {
        this.supportErrors.message = 'Gelieve een bericht in te geven.';
      } else if (this.supportInput.message.length > 200) {
        this.supportErrors.message = 'Bericht kan niet langer dan 200 karakters zijn. Momenteel heeft u: ' + this.supportInput.message.length + '/200 karakters.';
      } else {
        useApi().post('/Feedback/FeedbackController', this.supportInput).then((response) => {
          this.supportInput.message = '';
          this.supportInput.email = '';
          this.supportErrors.message = '';
          this.isHover = false;
          this.isClicked = false;
          this.isSubmitted = true;

          setTimeout(() => this.isSubmitted = false, 3000);
        });
      }
    }
  },
  setup() {
    const supportInput = reactive({
      email: '',
      type: 'suggestion',
      message: '',
    })
    const supportErrors = reactive({
      message: '',
    })

    return {
      supportInput,
      supportErrors
    }
  }
};
</script>
<style lang="scss" scoped>
  .feedback {
    &__submitted {
      position: fixed;
      left: 50%;
      bottom: 10%;
      transform: translate(-50%, 10%);

      p {
        padding: 1rem;
      }
    }

    &__wrapper {
      box-shadow: 0 0 25px 8px rgba(0,0,0,0.15);
      overflow: hidden;
      background: get-brand-color('primary', 'base');
      border-radius: 50%;
      transition: background .2s ease-in;

      position: fixed;
      right: 5%;
      bottom: 20%;
      cursor: pointer;

      @include lg {
        width: auto;
        transform: none;
      }
    }

    &:hover {
      .feedback__wrapper {
        box-shadow: 0 0 25px 8px rgba(0,0,0,0.15);
        background: $white;
        border-radius: 3%;
      }

      .feedback__icon {
        -webkit-animation: none;
        fill: get-brand-color('primary', 'base') !important;
      }

      p {
        visibility: visible;
        opacity: 1;
      }
    }

    &__trigger {
      padding: 1rem;
      display: flex;
      align-items: center;

      p {
        transition: visibility 0s, opacity 0.5s linear;
        opacity: 0;
        visibility: hidden;
        color: get-brand-color('primary', 'base');
        font-size: 1rem;
        font-weight: 500;
        margin-right: 1rem;
      }
    }

    &__icon {
      fill: $white;
      transition: all .1s ease-in;
      -webkit-animation-name: shake;
      -webkit-animation-duration: 5s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      -webkit-animation-delay: 0s;
    }

    &__content {
      cursor: default;
      padding: 1rem 1rem;
      background: $white;

      &__text {
        margin-bottom: 1rem;

        @include lg{
          width: 450px;
        }
      }

      &__form {
        input[type=email] {
          border-radius: 4px !important;
        }

        .button {
          margin-top: 1rem;
        }
      }
    }

    .form-active {
      background: $white;
      border-radius: 1%;

      @include lg{
        right: 5%;
        width: auto;
        transform: none;
      }

      right: 50%;
      width: 90%;
      transform: translateX(50%);

      &:hover {
        border-radius: 1%;
      }
    }
  }

  @-webkit-keyframes shake {
    0% {
    }
    50% {
      transform: rotate(0deg);
    }
    53%, 59% {
      transform: rotate(15deg);
    }
    56%, 62% {
      transform: rotate(-15deg);
    }
    65%{
      transform: rotate(0deg);
    }
  }
</style>
