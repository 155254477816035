<template>
  <div class="auth__header mb-5">
    <div class="logo is-size-mini is-centered">
      <router-link to="/">
        <img src="../../../assets/logo-full.svg" alt="MyKafka: Documenten voor verenigingen">
      </router-link>
    </div>
  </div>
  <div class="register-box">
    <main id="content">
      <div class="register-box__form is-centered is-size-mini mb-3">
        <template v-if="!registerCompleted">
          <div class="mb-5">
            <h1 class="title is-2">Welkom bij MyKafka!</h1>
            <p class="subtext">Alle avonturen starten met een registratieformulier!</p>
            <p class="subtext">
              Na het registeren van je account kan je beginnen met het digitaliseren van jouw vereniging.
            </p>
          </div>
          <form novalidate @submit.prevent="submit()" id="register-form" method="post" autocomplete="on">
            <div class="notification is-danger is-light" v-if="errorMessage">
              <p v-html="errorMessage"></p>
            </div>
            <fieldset>
              <div class="field">
                <label class="label is-required" for="firstName">Voornaam</label>
                <div class="control">
                  <input type="email" class="input" id="firstName"
                         v-model="userInput.firstName.input"
                         :class="(userInput.firstName.error)?'is-danger':''"/>
                  <p class="help is-danger" v-if="userInput.firstName.error">
                    {{ userInput.firstName.error }}
                  </p>
                </div>
              </div>
              <div class="field">
                <label class="label is-required" for="lastName">Achternaam</label>
                <div class="control">
                  <input type="email" class="input" id="lastName" v-model="userInput.lastName.input"
                         :class="(userInput.lastName.error)?'is-danger':''"/>
                  <p class="help is-danger" v-if="userInput.lastName.error">
                    {{ userInput.lastName.error }}
                  </p>
                </div>
              </div>
              <div class="field">
                <label class="label is-required" for="email">Email</label>
                <div class="control">
                  <input type="email" class="input" id="email" v-model="userInput.email.input"
                         :class="(userInput.email.error)?'is-danger':''"/>
                  <p class="help is-danger" v-if="userInput.email.error">
                    {{ userInput.email.error }}
                  </p>
                </div>
              </div>
              <div class="field">
                <label class="label is-required is-pulled-left" for="password">Wachtwoord</label>
                <div class="is-pulled-right">
                  <input type="checkbox" name="show-password" id="show-password" class="is-hidden"
                         v-model="showPassword">
                  <label for="show-password" title="Verberg wachtwoord" class="show-password"
                         v-if="showPassword">
                    <font-awesome-icon icon="eye-slash" class="mr-1"/>
                    Verberg
                  </label>
                  <label for="show-password" title="Toon wachtwoord" class="show-password" v-else>
                    <font-awesome-icon icon="eye" class="mr-1"/>
                    Toon
                  </label>
                </div>
                <div class="control">
                  <input :type="showPassword ? 'text' : 'password'" class="input" id="password" maxlength="51" minlength="6" v-model="userInput.password.input" :class="(userInput.password.error)?'is-danger':''"/>
                  <p class="help is-danger" v-if="userInput.password.error">
                    {{ userInput.password.error }}</p>
                </div>
              </div>
              <div class="field password-checks" v-if="passwordSafety.value !== 100">
                <progress class="progress is-small is-success" max="100" :value="passwordSafety.value" />
                <ul class="subtext">
                  <li :class="(passwordSafety.minChars ? 'checked' : '')">Min. 8 karakters</li>
                  <li :class="(passwordSafety.upperCase ? 'checked' : '')">Min. 1 hoofdletter</li>
                  <li :class="(passwordSafety.number ? 'checked' : '')">Min. 1 nummer</li>
                  <li :class="(passwordSafety.specialChar ? 'checked' : '')">Min. 1 speciaal karakter</li>
                </ul>
              </div>
              <div class="field">
                <p class="text subtext">Door op "Verzenden" te klikken, gaat u akkoord met de <a
                  href="https://mykafka.be/info/terms-of-use" rel="noopener" target="_blank">gebruiksvoorwaarden</a>
                  en het <a href="https://mykafka.be/info/privacy" rel="noopener" target="_blank">privacybeleid</a>
                  van MyKafka.</p>
              </div>
              <div class="register-box__actions">
                <div class="field register-box__actions--submit">
                  <button type="submit" class="button is-primary is-fullwidth"
                          :class="(isBusy)?'is-loading':''" value="log in">Verzenden
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
          <div class="field is-centered has-text-centered">
            Al een account?
            <router-link to="login"> Meld je hier aan</router-link>
          </div>
        </template>
        <template v-else>
          <div class="mb-5">
            <h1 class="title is-2">Check, check, ...</h1>
          </div>
          <div class="notification is-danger is-light" v-if="errorMessage">
            <p v-html="errorMessage"></p>
          </div>
          <div class="notification is-success is-light" v-if="resendConfirmationCompleted">
            <p>De bevestigingsmail is opnieuw verzonden. </p>
          </div>
          <p class="subtext">Je registratie is goed aangekomen!</p>
          <br>
          <p class="subtext">
            Er is een bevestigingsmail verzonden naar <b>{{ userInput.email.input }}</b>.
            <br>
            Nog even bevestigen dat jij het bent en je kan aan de slag!
          </p>
          <br>
          <p class="subtext">
            Nog geen mail ontvangen? Kijk eens in je spamfolder of <a
            v-on:click="resendConfirmationMail()">probeer opnieuw</a>.
          </p>
          <p class="subtext mt-5">Voor verdere vragen kan je ons altijd <a
            href="mailto:info@mykafka.be" target="_blank">hier</a> bereiken.</p>
        </template>
      </div>
    </main>
  </div>
</template>

<script>

import { ref, watch } from 'vue';
import { reactive } from '@vue/reactivity';
import { useStore } from 'vuex';
import { registerValidation } from '@/utils/validationHelper';
import { getLocalStorageVariable } from '@/helpers/localStorageHandler';

export default {
  name: 'RegisterBox',
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    }
  },
  setup() {
    const store = useStore();
    const errorMessage = ref('');

    let userInput = reactive({
      email: {
        input: '',
        error: false,
      },
      firstName: {
        input: '',
        error: false,
      },
      lastName: {
        input: '',
        error: false,
      },
      password: {
        input: '',
        error: false,
      },
    });
    let registerCompleted = ref(false);
    let resendConfirmationCompleted = ref(false);
    let passwordSafety = reactive({
      minChars: false,
      upperCase: false,
      number: false,
      specialChar: false,
      value: 0,
    });

    store.commit('clearBusy');

    /**
     * Check if user email is stored in localStorage, ifso, user already registered but reloaded page
     */
    if (getLocalStorageVariable('register-email')) {
      userInput.email.input = getLocalStorageVariable('register-email');
      registerCompleted.value = true;
    }

    /**
     * Validate user input
     * If valid, send data to hubspot and create new user in the backend db
     */
    function submit() {
      // Check validation
      userInput = registerValidation(userInput);

      if (userInput.firstName.error === '' && userInput.lastName.error === '' && userInput.email.error === '' && userInput.password.error === '') {
        store.commit('setBusy');

        store.dispatch('register', userInput)
          .then(() => {
            errorMessage.value = '';
            registerCompleted.value = true;
          })
          .catch((errors) => {
            if (typeof errors === 'string') {
              errorMessage.value = errors;
            } else {
              errors.forEach((error) => {
                // Set error message for field
                const field = error['PropertyName'].toLowerCase();
                userInput[field].error = error['ErrorMessages'].join(', ');
              })
            }
            window.scrollTo(0, 0)
          })
          .finally(function () {
              store.commit('clearBusy');
          });
      }
    }

    /**
     * Resend confirmation mail
     */
    function resendConfirmationMail() {
      store.commit('setBusy');

      if (registerCompleted.value) {
        store.dispatch('resendConfirmationMail', userInput.email.input)
          .then((response) => {
            errorMessage.value = '';
            resendConfirmationCompleted.value = true;
          })
          .catch((errorMessage) => {
            errorMessage.value = errorMessage;
            resendConfirmationCompleted.value = false;
          })
          .finally(() => {
              store.commit('clearBusy');
            }
          )
        ;
      }
    }

    /**
     * Check password safety
     */
    watch(userInput.password, (pw) => {
      passwordSafety.value = 0;

      passwordSafety.minChars = pw.input.length >= 8;
      passwordSafety.upperCase = (pw.input.toLowerCase() !== pw.input);
      passwordSafety.number = /[0-9]/.test(pw.input);
      passwordSafety.specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pw.input);

      if (passwordSafety.minChars) passwordSafety.value += 25;
      if (passwordSafety.upperCase) passwordSafety.value += 25;
      if (passwordSafety.number) passwordSafety.value += 25;
      if (passwordSafety.specialChar) passwordSafety.value += 25;
    })

    return {
      userInput,
      submit,
      errorMessage,
      registerCompleted,
      resendConfirmationMail,
      resendConfirmationCompleted,
      passwordSafety
    };
  }
}
;
</script>

<style lang="scss" scoped>
.register-box {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  flex-grow: 1;

  &__actions {
    margin-top: 25px;

    &--submit {
      margin-bottom: 2rem !important;
    }
  }

  a {
    text-decoration: underline;
  }
}

.show-password {
  cursor: pointer;
  color: get-gray-color('base');

  &:hover {
    color: get-brand-color('primary', 'base');
  }
}

.password-checks {

  ul {
    color: get-gray-color('base');
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    .checked {
      color: $success;
    }
  }

  .progress {
    margin-bottom: 15px;
  }
}

#content {
  width: 100%;
  align-self: center;
  margin-bottom: 50px;
  display: block;
}

.auth__header {
  img {
    width: 150px;
  }
}
</style>
