<template>
  <div class="column is-8-tablet is-12-mobile">
    <div class="body">
      <CreateCertificateForm v-if="showFormModal" @closeModal="closeCertificateRequest" :certificateIndex="certificateIndex" />
      <FormSteps :activeStep="2" />
      <RequestOverview @addCertificateRequest="addCertificateRequest" />
      <div class="body__controls mt-5">
        <div class="field is-grouped">
          <button v-on:click="gotToHomepage()" class="button is-white"><font-awesome-icon icon="arrow-left" class="mr-2" />Terug</button>
          <p>Stap 2/3</p>
          <button v-on:click="goToDownloadPage()" class="ml-5 button is-success is-disabled" :disabled="getCertificates.length < 1">Volgende stap</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/app/Frontend/Components/Navigation/Footer.vue';
import FormSteps from '@/app/Frontend/Components/Navigation/FormSteps.vue';
import RequestOverview from '@/app/Frontend/Components/RequestOverview.vue';
import CreateCertificateForm from '../Components/Modal/CreateCertificateForm';

/**
 * Start form to create certificates,
 * This page serves as the main overview of all requested certificates
 */
export default {
  name: "RequestCreateForm",
  components: {
    CreateCertificateForm,
    Footer,
    FormSteps,
    RequestOverview,
  },
  data() {
    return {
      showFormModal: false,
      certificateIndex: 0,
    }
  },
  computed: {
    getCertificates() {
      return this.$store.getters.getAllCertificates;
    }
  },
  methods: {
    /**
     * New certificate index id
     * @param newCertificateIndex
     */
    addCertificateRequest(newCertificateIndex) {
      this.certificateIndex = newCertificateIndex;
      this.showFormModal = true;
    },
    closeCertificateRequest() {
      this.showFormModal = false;
    },
    goToDownloadPage() {
      this.$emit('showDownloadPage');
    },
    gotToHomepage() {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped lang="scss">

.body {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__controls {
    @include lg {
      margin-top: auto;
    }
    margin-top: 2rem;

    .field {
      display: flex;
      align-items: center;

      p {
        margin-left: 1rem;
      }
    }
  }
}
</style>
