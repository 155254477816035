<template>
  <div class="column is-8-tablet is-12-mobile">
    <div class="body">
      <FormSteps :activeStep="3" @goBack="goToFormPage()" />
      <EmailForm v-if="!hasUsernameAndEmail()" />
      <div class="body__main" v-else>
        <h1 class="title is-3">That's it!</h1>
        <p class="subtext">Volgende gegevens worden gebruikt als validatie</p>
        <div class="notification mt-4">
          {{ getUserDetails()['username'] }} — {{ getUserDetails()['email'] }}
          <button class="delete" @click="removeUserDetails()"></button>
        </div>
        <p class="subtext">Uw kan hieronder de attesten downloaden.</p>
        <div class="requests mt-4">
          <template v-for='(certificate, index) in getCertificates()' :key='certificate'>
            <p class="help is-danger">{{ getErrorsByIndex(index) }}</p>
            <a class="requests__item requests__item--active" @click="getCertificateDownload(certificate, index)">
              <div class="request__icon">
                <font-awesome-icon v-bind:icon="getIcon(index)" />
              </div>
              <div class="request__body">
                <p>
                  {{ certificate.firstname }} {{ certificate.lastname }}, <br>
                  <span class="request__body--details is-hidden-desktop">{{ getName(certificate.healthService) }} - {{ getType(certificate.certificateType) }}</span>
                </p>
              </div>
              <div class="request__details is-hidden-mobile">
                <p>
                  {{ getName(certificate.healthService) }} - {{ getType(certificate.certificateType) }}
                </p>
              </div>
              <div class="request__footer">
                <button v-if="getIsBusy(index)" class="request__loading button is-loading is-primary is-inverted">&nbsp;</button>
                <font-awesome-icon icon="download" v-else />
              </div>
              <div>
              </div>
            </a>
          </template>
        </div>
      </div>
      <div class="body__controls">
        <div class="field is-grouped">
          <button v-on:click="goToFormPage()" class="button is-white">
            <font-awesome-icon icon="arrow-left" class="mr-2" />
            Terug</button>
          <p>Stap 3/3</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/app/Frontend/Components/Navigation/Footer.vue';
import FormSteps from '@/app/Frontend/Components/Navigation/FormSteps.vue';
import { useApi } from '@/utils/useApi';
import EmailForm from '@/app/Frontend/Components/Form/EmailForm';

/**
 * Overview page for downloads
 */
export default {
  name: "DownloadOverviewPage",
  components: {
    EmailForm,
    Footer,
    FormSteps,
  },
  methods: {
    goToFormPage() {
      this.$emit('showFormPage');
    },
    getCertificates() {
      return this.$store.getters.getAllCertificates;
    },
    getUserDetails() {
      return {'username': this.$store.getters.getUsername, 'email': this.$store.getters.getEmail}
    },
    getIsBusy(index) {
      let isBusy = this.$store.getters.getIsBusyByIndex;
      return isBusy[index];
    },
    getType(slug) {
      if (slug === 'Member') {
        return 'Lidmaatschap';
      } else {
        return 'Kamp';
      }
    },
    getName(id) {
      const healthServices = this.$store.getters.GET_CERTIFICATE_RECEIVERS;
      if (healthServices.length > 0) {
        let healthServiceName = healthServices.filter(healthService => {
          return healthService.id === id;
        })

        return healthServiceName[0].name;
      }
      return '';
    },
    getErrorsByIndex(index) {
      let errors = this.$store.getters.getErrorsByIndex;
      return errors[index];
    },
    getCertificateDownload(certificate, index) {
      useApi().getCertificatePdf(certificate, index);
    },
    hasUsernameAndEmail() {
      if (this.$store.getters.getUsername && this.$store.getters.getEmail) {
        return true
      }
      if (sessionStorage.getItem('user_details')) {
        let userDetails = JSON.parse(sessionStorage.getItem('user_details'));
        this.$store.commit('setUsername', userDetails.username);
        this.$store.commit('setEmail', userDetails.email);
        return true
      }
      return false;
    },
    getIcon(index) {
      let iconUser = '';

      switch (index) {
        case 0:
          iconUser = 'user-ninja';
          break;
        case 1:
          iconUser = 'user-astronaut';
          break;
        case 2:
          iconUser = 'hat-cowboy';
          break;
        default:
          iconUser = 'user-tie';
      }

      return iconUser;
    },
    removeUserDetails() {
      this.$store.commit('clearUsername');
      this.$store.commit('clearEmail');
      sessionStorage.removeItem('user_details');
    }
  },
};
</script>

<style scoped lang="scss">

.body {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  .notification {
    @include lg{
      max-width: 80%;
    }

    .delete {
      @include lg{
        visibility: hidden;
        opacity: 0;
        transition: all .1s ease-in;
      }
    }

    &:hover {
      .delete {
        visibility: visible;
        opacity: 100%;
      }
    }
  }

  .requests {
    display: flex;
    flex-direction: column;

    @include lg {
      max-width: 80%;
    }

    &__item {
      display: flex;
      background: #FFFFFF;
      box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.03);
      border-radius: 10px;
      transition: box-shadow 0.3s ease-in-out;
      color: get-brand-color('primary', 'dark');
      margin: .5rem 0;

      &:hover {
        box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.05);
      }
    }

    .request {
      &__icon {
        background: get-brand-color('primary', 'dark');
        color: $white;
        border-radius: 10px 0 0 10px;
        font-size: 1.25rem;
        padding: 1rem 1.5rem;
      }
      &__details,
      &__body,
      &__footer {
        display: flex;
        align-items: center;
      }
      &__body {
        display: flex;
        align-items: center;
        padding: .5rem 0 .5rem 1rem;

        @include lg {
          padding: 0 .5rem 0 1.5rem;
        }

        p {
          font-size: 1.1rem;
          font-weight: 500;
        }

        &--details {
          color: get-gray-color('base');
          font-size: .9rem;
        }
      }
      &__details {
        color: get-gray-color('base');
      }
      &__footer {
        margin-left: auto;
        padding: 0 2rem;
      }
      &__loading {
        color: get-brand-color('primary', 'dark') !important;

        &:after {
          border-color: transparent transparent get-brand-color('primary', 'dark') get-brand-color('primary', 'dark') !important;
        }
      }
    }
  }

  &__controls {
    @include lg {
      margin-top: auto;
    }
    margin-top: 2rem;

    .field {
      display: flex;
      align-items: center;

      p {
        margin-left: 1rem;
      }
    }
  }
}
</style>
