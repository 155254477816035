<template>
  <Navbar :organization="organization" :pageTitle="pageTitle" @toggleIsMobile="toggleIsMobile" />
  <NavbarMobile v-if="showMobile" :organization-name="organization.name" />
  <div class="wrapper">
    <div>
      <NavigationSide :show-navigation="showSidebar" :organization="organization"/>
      <main class="main">
        <slot name="hero"/>
        <div class="content">
          <div class="container is-fluid dashboard">
            <section class="dashboard__header">
              <slot name="header"/>
            </section>
            <slot name="content"/>
            <template v-if="getCurrentRoute !== 'DashboardOrganizationCreate'">
              <ButtonPrevious route="Dashboard" class="mb-5" v-if="getCurrentRoute !== 'DashboardOrganizationCreate' || getCurrentRoute !== 'Dashboard'" />
            </template>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

import Navbar from '../components/nav/Navbar';
import NavigationSide from '../components/nav/NavigationSide';
import FooterSmall from '../app/Services/Components/Navigation/FooterSmall';
import Footer from '../app/Services/Components/Navigation/Footer';
import ButtonPrevious from '../components/ui/ButtonPrevious';
import NavbarMobile from '../components/nav/NavbarMobile';
import { useStore } from 'vuex';

/**
 * Dashboard base template
 */
export default {
  name: "BaseDashboardLayout",
  components: {
    NavbarMobile,
    ButtonPrevious,
    Footer,
    FooterSmall,
    NavigationSide,
    Navbar
  },
  props: {
    showSidebar: {
      default: true,
      type: Boolean
    },
    pageTitle: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      showMobile: false,
    }
  },
  computed: {
    getCurrentRoute() {
      return this.$route.name;
    }
  },
  methods: {
    toggleIsMobile() {
      this.showMobile = !this.showMobile;
    }
  },
  setup() {
    const store = useStore();
    // Get organization details
    const organization = store.getters.GET_USER_ORGANIZATION;

    // Super admin?
    const isSuperAdmin = true;

    return {
      organization
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  margin-top: $header-height;
  padding-top: 15px;
  position: relative;

  @include lg {
    margin-left: $sidebar-width;
    padding-top: 0;

    .content {
      padding: 2rem 2rem 0 2rem;
    }
  }
}

.dashboard {
  &__header {
    margin-bottom: 2rem;
  }

  &__intro {

  }
}
</style>
