<template>
  <div class="auth__header mb-5">
    <div class="logo is-size-mini is-centered">
      <router-link to="/">
        <img src="../../../assets/logo-full.svg" alt="MyKafka: Documenten voor verenigingen">
      </router-link>
    </div>
  </div>
  <div class="login-box">
    <main id="content">
      <div class="login-box__form is-centered is-size-mini mb-3">
        <div class="mb-5">
          <h1 class="title is-2">Log in</h1>
          <p class="subtext">Nog geen MyKafka account?
            <router-link to="register">Maak een account aan</router-link>
          </p>
        </div>
        <div class="notification is-danger is-light" v-if="authError">
          <p v-html="authError"></p>
        </div>
        <fieldset>
          <form novalidate @submit.prevent="submit()" id="login-form" method="post"
                autocomplete="on">
            <div class="field">
              <label class="label is-required" for="email">Email</label>
              <div class="control">
                <input type="text" class="input" id="email" v-model="userInput.email.input"
                       :class="(userInput.email.error)?'is-danger':''"/>
                <p class="help is-danger" v-if="userInput.email.error">
                  {{ userInput.email.error }}
                </p>
              </div>
            </div>
            <div class="field">
              <label class="label is-required is-pulled-left" for="password">Wachtwoord</label>
              <div class="is-pulled-right">
                <input type="checkbox" name="show-password" id="show-password" class="is-hidden"
                       v-model="showPassword">
                <label for="show-password" title="Verberg wachtwoord" class="show-password"
                       v-if="showPassword">
                  <font-awesome-icon icon="eye-slash" class="mr-1"/>
                  Verberg
                </label>
                <label for="show-password" title="Toon wachtwoord" class="show-password" v-else>
                  <font-awesome-icon icon="eye" class="mr-1"/>
                  Toon
                </label>
              </div>
              <div class="control">
                <input :type="showPassword ? 'text' : 'password'" class="input" id="password"
                       name="password"
                       maxlength="51" v-model="userInput.password.input"
                       :class="(userInput.password.error)?'is-danger':''"/>
                <p class="help is-danger" v-if="userInput.password.error">
                  {{ userInput.password.error }}</p>
              </div>
            </div>
            <div class="login-box__actions">
              <div class="field login-box__actions--submit">
                <button type="submit" class="button is-primary is-fullwidth"
                        :class="(isBusy)?'is-loading':''" value="log in">Log in
                </button>
              </div>
              <div class="field is-centered has-text-centered">
                <a v-on:click="forgotPassword">Wachtwoord vergeten?</a>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
    </main>
  </div>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { useStore } from 'vuex';
import { loginValidation } from '@/utils/validationHelper';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'LoginBox',
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let userInput = reactive({
      email: {
        input: '',
        error: '',
      },
      password: {
        input: '',
        error: '',
      },
    });
    let authError = ref('');

    store.commit('clearBusy');

    function submit() {
      // Check validation
      userInput = loginValidation(userInput);
      authError.value = '';

      if (userInput.email.input && userInput.password.input) {
        store.commit('setBusy');

        // User login
        store.dispatch('authenticate', userInput)
          .catch((error) => {
            authError.value = error;
          })
          .finally(() => {
            const user = store.getters.GET_USER;
            if (user) {
              store.dispatch('getOrganizationByUserId', user.id)
                .catch((error) => {
                  authError.value = error;
                })
              .finally(() => {
                const organization = store.getters.GET_USER_ORGANIZATION;

                if (organization) {
                  // User has an organization, redirect to dashboard
                  router.push({ name: 'Dashboard', params: { slug: organization.callSign ? organization.callSign : organization.slug } });
                } else {
                  // User doesn't have an organization, redirect to confirmation page
                  router.push({ name: 'Email confirm' });
                }

                store.commit('clearBusy');
              })
            }
            store.commit('clearBusy');
          });
      }
    }

    function forgotPassword() {
      // TODO implement reset password function
      window.location.href = 'mailto:info@mykafka.be';
    }

    return {
      userInput,
      submit,
      forgotPassword,
      authError
    };
  }
};
</script>

<style lang="scss" scoped>
.login-box {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  flex-grow: 1;

  &__actions {
    margin-top: 25px;

    &--submit {
      margin-bottom: 2rem !important;
    }
  }

  a {
    text-decoration: underline;
  }
}

.show-password {
  cursor: pointer;
  color: get-gray-color('base');

  &:hover {
    color: get-brand-color('primary', 'base');
  }

}

#content {
  width: 100%;
  align-self: center;
  margin-bottom: 50px;
  display: block;
}

.auth__header {
  img {
    width: 150px;
  }
}
</style>
