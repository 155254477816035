import { useApi } from '@/utils/useApi';
import { orderObjectArray } from '@/utils/utils';

const state = {
  isBusy: false,
  isBusyValidation: false,
  organization: {},
  formPosition: 0,
  username: '',
  email: '',
  isBusyByIndex: {},
  errorsByIndex: {},
  formValidationStatus: true,
  organizationName: '',
}

const mutations = {
  setBusy: (state) => state.isBusy = true,
  setBusyValidation: (state) => state.isBusyValidation = true,
  clearBusy: (state) => state.isBusy = false,
  clearBusyValidation: (state) => state.isBusyValidation = false,
  /**
   * Fetch organization details from API and save in store
   * @param state
   * @param organization
   */
  setOrganization: (state, organization) => {
    state.organization = organization;
  },
  clearOrganization: (state) => state.organization = '',
  clearFormPosition: (state) => state.formPosition = 0,
  setUsername: (state, username) => state.username = username,
  setEmail: (state, email) => state.email = email,
  clearUsername: (state) => state.username = '',
  clearEmail: (state) => state.email = '',
  setIsBusyByIndex: (state, data) => {
    state.isBusyByIndex[data['index']] = data['boolean'];
  },
  setErrorsByIndex: (state, data) => {
    state.errorsByIndex[data['index']] = data['errorString'];
  },
  setFormValidationStatus: (state, boolean) => {
    state.formValidationStatus = boolean;
  },
  clearFormValidationStatus: (state) => state.formValidationStatus = false,
  setOrganizationName: (state, data) => state.organizationName = data,
}

const actions = {
  fetchOrganizationDetails: ({ commit }, slug) => {

    commit("setBusy");
    let params =  { params: { slug: slug } }

    return useApi().get('Association/GetAssociationBySlug', params).then(
      result => {
        if (!result) {
          throw null;
        }

        // Order associationUnits by groupIndex
        result['associationUnits'] = orderObjectArray(result['associationUnits'], 'groupIndex');

        commit('setOrganization', result);
        return Promise.resolve(result);
      },
    );
  },
  checkFormValidation: ({commit}, data) => {
    commit("setBusyValidation");

    return useApi().postAuthCheck('Member/CheckIfMemberExists', data);
  }
}

const getters = {
  getOrganization: (state) => state.organization,
  getCurrentFormStage: (state) => state.formPosition,
  isOrganizationSelected: (state) => {
    return !!state.organization.slug;
  },
  getUsername: (state) => state.username,
  getEmail: (state) => state.email,
  getIsBusyByIndex: (state) => state.isBusyByIndex,
  getErrorsByIndex: (state) => state.errorsByIndex,
  getFormValidationStatus: (state) => state.formValidationStatus,
  getIsBusyValidation: (state) => state.isBusyValidation,
  getOrganizationName: (state) => state.organizationName,
  getIsBusy: (state) => state.isBusy,
}

const inputModule = {
  state,
  mutations,
  actions,
  getters
}

export default inputModule;
