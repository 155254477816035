<template>
  <BaseDashboardLayout :show-sidebar="false" page-title="Vereniging aanmaken">
    <template v-slot:content>
      <div class="body">
        <div class="mb-5">
          <h1 class="title is-3">Wie zijn jullie?</h1>
          <p class="subtext">
            Voor u kunt beginnen met het opladen van ledendata, hadden we graag wat meer te weten gekomen over jullie organisatie.  Gelieve onderstaande velden in te vullen zodat we weten wie jullie zijn en wat jullie doen.
            Velden met een * zijn verplicht.
          </p>
        </div>
        <form novalidate @submit.prevent="submit()" method="post" autocomplete="on" id="create-organization">
          <div class="notification is-danger is-light" v-if="errorMessage">
            <p v-html="errorMessage"></p>
          </div>

          <fieldset class="form-item">
            <h3 class="title is-5"><font-awesome-icon icon="campground" class="mr-1"/>Algemene info</h3>
            <div class="columns">
              <div class="column">
                <TextType label="Naam van uw organizatie" :error="userInput.name.error" v-model="userInput.name.input" :required="true"/>
              </div>
              <div class="column">
                <SelectType label="Type vereniging" :error="userInput.typeId.error" v-model="userInput.typeId.input" :required="true" :options="organizationTypes"/>
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <TextType label="Straatnaam" :error="userInput.street.error" v-model="userInput.street.input" :required="true"/>
              </div>
              <div class="column is-6">
                <TextType label="Huisnummer" :error="userInput.number.error" v-model="userInput.number.input" :required="true"/>
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <TextType label="Postcode" :error="userInput.postalCode.error" v-model="userInput.postalCode.input" :required="true"/>
              </div>
              <div class="column is-6">
                <TextType label="Gemeente" :error="userInput.city.error" v-model="userInput.city.input" :required="true"/>
              </div>
            </div>
          </fieldset>

          <fieldset class="form-item">
            <h3 class="title is-5">
              <font-awesome-icon icon="user-ninja" class="mr-1"/>
              Administratief verantwoordelijke
            </h3>
            <p class="subtext mb-5">
              Een vereniging zonder verantwoordelijke is als een schip zonder kapitein.
            </p>
            <div class="field">
              <label class="checkbox">
                <input type="checkbox" v-model="userInput.userIsResponsible.input">
                Ik <i class="has-text-grey">({{ user.firstName }})</i> ben de verantwoordelijke!
              </label>
            </div>
            <template v-if="!userInput.userIsResponsible.input">
              <div class="columns is-flex-direction-column">
                <div class="column">
                  <TextType label="Voornaam" :error="userInput.kafkaFirstName.error" v-model="userInput.kafkaFirstName.input" :required="true"/>
                </div>
                <div class="column">
                  <TextType label="Achternaam" :error="userInput.kafkaLastName.error" v-model="userInput.kafkaLastName.input" :required="true"/>
                </div>
                <div class="column">
                  <TextType label="Email" :error="userInput.kafkaMail.error" v-model="userInput.kafkaMail.input" :required="true"/>
                </div>
              </div>
            </template>
          </fieldset>

          <fieldset class="form-item">
            <h3 class="title is-5">
              <font-awesome-icon icon="table" class="mr-1"/>
              Groepseigen data
            </h3>
            <p class="subtext mb-5">Om attesten correct te genereren hebben we nog enkele gegevens nodig. <br>Als deze gevraagde data afwijkt van jullie groepswerking kunnen jullie ons hier over <a href="mailto:info@mykafka.be" target="_blank">contacteren</a>.</p>
            <div class="columns is-flex-direction-column">
              <div class="column">
                <TextType label="Bedrag lidmaatschap (&euro;)" :error="userInput.memberFee.error" v-model="userInput.memberFee.input" :required="true"/>
              </div>
<!--              <div class="column">-->
<!--                <TextType label="Vereniging/Ondernemingsnummer" :error="userInput.companyNumber.error" v-model="userInput.companyNumber.input"/>-->
<!--              </div>-->
              <div class="column">
                <TextType label="Bedrag verminderd lidmaatschap (&euro;)" :error="userInput.socialMemberFee.error" v-model="userInput.socialMemberFee.input"/>
              </div>
            </div>
          </fieldset>

          <button type="submit" class="button is-primary is-fullwidth mb-5" :class="(isBusy)?'is-loading':''" value="log in">Verzenden</button>
        </form>
      </div>
    </template>
  </BaseDashboardLayout>
</template>

<script>
import BaseDashboardLayout from '../layouts/BaseDashboardLayout';
import TextType from '../components/form/TextType';
import { reactive } from '@vue/reactivity';
import SelectType from '../components/form/SelectType';
import { getOrganizationTypes } from '@/helpers/associations';
import CheckboxType from '../components/form/CheckboxType';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { organizationCreateValidation } from '@/utils/validationHelper';

export default {
  name: 'CreateOrganizationPage',
  components: {
    CheckboxType,
    SelectType,
    TextType,
    BaseDashboardLayout
  },
  computed: {
    isBusy() {
      return this.$store.getters.GET_BUSY;
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = store.getters.GET_USER;
    const organizationTypes = getOrganizationTypes();
    const errorMessage = ref(null);

    let userInput = reactive({
      name: {
        input: '',
        error: ''
      },
      typeId: {
        input: '',
        error: ''
      },
      userIsResponsible: {
        input: true,
      },
      kafkaFirstName: {
        input: '',
        error: ''
      },
      kafkaLastName: {
        input: '',
        error: ''
      },
      kafkaMail: {
        input: '',
        error: ''
      },
      memberFeeIsSameForEachAssociation: {
        input: true,
        error: ''
      },
      memberFee: {
        input: 0,
        error: ''
      },
      socialMemberFee: {
        input: null,
        error: ''
      },
      companyNumber: {
        input: '',
        error: ''
      },
      street: {
        input: '',
        error: ''
      },
      number: {
        input: '',
        error: ''
      },
      postalCode: {
        input: '',
        error: ''
      },
      city: {
        input: '',
        error: ''
      },
    });

    // User already has organization, reroute to dashboard
    const organization = store.getters.GET_USER_ORGANIZATION;
    if (organization) {
      console.debug('organization found, reroute to dashboard');

      router.push({
        name: 'Dashboard',
        params: { slug: organization.slug }
      });
    }

    function submit() {
      store.commit('SET_BUSY', true);
      errorMessage.value = null;

      // If current user is responsible, set user as kafka admin
      if (userInput.userIsResponsible.input) {
        userInput.kafkaFirstName.input = user.firstName;
        userInput.kafkaLastName.input = user.lastName;
        userInput.kafkaMail.input = user.email;
      }

      // Check validation
      const validation = organizationCreateValidation(userInput);
      userInput = validation.input;

      if (validation.isValid) {
        store.dispatch('createAssociation', userInput)
          .then((response) => {
            store.commit('SET_BUSY', false);
            router.push({ name: 'Dashboard', params: { slug: response.callSign } });
          })
          .catch(
            (errors) => {
              console.debug(errors)
              errorMessage.value = errors.error;
              window.scrollTo(0, 0);
            }
          )
          .finally(function () {
            store.commit('SET_BUSY', false);
          });
      } else {
        errorMessage.value = 'Oeps, controleer je gegevens en probeer opnieuw.';
        store.commit('SET_BUSY', false);
        window.scrollTo(0, 0);
      }
    }

    return {
      userInput,
      submit,
      organizationTypes,
      user,
      errorMessage
    };
  }
};
</script>

<style scoped lang="scss">
.body {
  @include lg {
    margin-right: $sidebar-width;
  }
}
</style>
