<template>
  <div class="launch-panel">
    <div class="launch-panel__inner">
      <div class="launch-panel__button">
        <input type="checkbox" class="checkbox">
        <div class="launch-panel__button--toggle">
          <span class="launch-panel__button--icon">
            <RocketIcon class="icon"/>
          </span>
        </div>
        <div class="launch-panel__button--layer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import RocketIcon from '../../app/Shared/Icons/RocketIcon';

export default {
  name: 'LaunchPanel',
  components: { RocketIcon },
  setup() {
    const launched = ref(false);

    return { launched }
  }
};
</script>

<style scoped lang="scss">
.launch-panel {
  align-self: stretch;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__inner {
    margin: 1rem;
  }

  &__button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 30px;
    margin: -20px auto 0 auto;
    border-radius: 100px;

    &--toggle {
      z-index: 2;
    }

    &--icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      width: 30px;
      height: 30px;
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      padding: 9px 4px;
      background: get-gray-color('base');
      border-radius: 50%;
      transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    }

    &--layer {
      border-radius: 100px;
      width: 100%;
      background: get-brand-color('primary', 'light');
      transition: 0.3s ease all;
      z-index: 1;
    }

    &--toggle,
    &--layer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &--icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .checkbox
  {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }


  &__button .checkbox:active + .launch-panel__button--toggle>span
  {
    width: 46px;
    border-radius: 100px;
  }

  &__button .checkbox:checked:active + .launch-panel__button--toggle>span
  {
    margin-left: -26px;
  }

  &__button  .checkbox:checked + .launch-panel__button--toggle>span
  {
    content: 'NO';
    left: 42px;
    background-color: $success;
  }

  &__button  .checkbox:checked ~ .launch-panel__button--layer
  {
    background: get-brand-color('primary', 'light');
  }
}
</style>
