import { translate } from './translations';

/**
 * Handle API errors & return error message
 * @param response
 */
export const handleApiError = (response) => {
  switch (response.status) {
    case 500:
      return translate(500);
    default:
      if (response.data[0]['ErrorMessages']) {
        return {
          errorMessage: response.data[0]['ErrorMessages'],
          errorField: response.data[0]['PropertyName'].toLowerCase()
        }
      }

      return translate(response.data.error);
  }
}

/**
 * Login validation
 * @param input
 */
export const loginValidation = (input) => {
  input.email.error = emailValidation(input.email.input, true);
  input.password.error = requiredValidation(input.password.input);

  return input;
}

/**
 * Register validation
 * @param input
 */
export const registerValidation = (input) => {
  input.email.error = emailValidation(input.email.input, true);
  input.firstName.error = requiredValidation(input.firstName.input);
  input.lastName.error = requiredValidation(input.lastName.input);
  input.password.error = passwordValidation(input.password.input);

  return input;
}

/**
 * Create organization validation
 * @param input
 */
export const organizationCreateValidation = (input) => {
  input.name.error = requiredValidation(input.name.input);
  input.kafkaFirstName.error = requiredValidation(input.kafkaFirstName.input);
  input.kafkaLastName.error = requiredValidation(input.kafkaLastName.input);
  input.kafkaMail.error = requiredValidation(input.kafkaMail.input);
  input.memberFee.error = requiredValidation(input.memberFee.input);
  input.typeId.error = requiredValidation(input.typeId.input);
  input.street.error = requiredValidation(input.street.input);
  input.number.error = requiredValidation(input.number.input);
  input.postalCode.error = requiredValidation(input.postalCode.input);
  input.city.error = requiredValidation(input.city.input);

  input.memberFee.error = numericValidation(input.memberFee.input);
  input.postalCode.error = numericValidation(input.postalCode.input);

  if (input.socialMemberFee.input) {
    console.debug(input.socialMemberFee.input);
    input.socialMemberFee.error = numericValidation(input.socialMemberFee.input);
  }

  const isValid = !input.name.error && !input.kafkaFirstName.error && !input.kafkaLastName.error && !input.kafkaMail.error && !input.memberFee.error && !input.typeId.error && !input.street.error && !input.number.error && !input.postalCode.error && !input.city.error && !input.memberFee.error && !input.socialMemberFee.error;


  return {
    'input': input,
    'isValid': isValid
  };
}

export const createCertificate = (input) => {
  return {
    'input': input,
  }
}

/**
 * API error
 * TODO Deprecated, remove in future update
 */
export const getErrorFromAPI = (status, origin = '') => {
  if (origin === 'resend-confirm-mail' && status === 400) status = 500;

  switch (status) {
    case 404:
    case 402:
      return 'Oeps, er ging iets mis.';
    case 400:
      return 'Foutieve inloggegevens. Probeer het opnieuw of <a href="mailto:info@mykafka.be">neem contact op</a>.'
    default:
      return 'Oeps, er ging iets mis.';
  }
}

/**
 * Email validation
 * @param input
 * @param required
 */
const emailValidation = (input, required = false) => {
  // Check if empty
  if (required && input.length === 0) return translate('This field is required.');

  // Check valid email (Simple regex check)
  let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  if (!reg.test(input)) return translate('invalidEmail');

  return '';
}

/**
 * Phone number validation
 * @param input
 * @param required
 * @returns {string}
 */
const phoneNumberValidation = (input, required = false) => {
  // Check if empty
  if (required && input.length === 0) return translate('This field is required.');

  // Check valid phone number (Regex check)
  let reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (!reg.test(input) && required) return translate('invalidPhoneNumber');

  return '';
}

/**
 * Text area validation
 * @param input
 * @param required
 */
const textAreaValidation = (input, required = false) => {
  // Check if empty
  if (required && input.length === 0) return translate('This field is required.');

  // Check min max char count
  if (input.length > 500) return `${translate('maxCharLimit')} ${input.length}/500`;

  return '';
}

const passwordMatchValidation = (input, inputToMatch, required = false) => {
  // Check if empty
  if (required && input.length === 0) return translate('This field is required.');

  // Check if inputs match
  if (input !== inputToMatch) return noPasswordMatch;
}

/**
 * Password validation
 * - Min 8 char
 * - Min 1 capital letter
 * - Min 1 number
 * - Min 1 Special char
 * @param input
 */
const passwordValidation = (input) => {
  if (input.length === 0) return translate('This field is required.');

  if (input.length < 8) return translate('minCharLimit').replace('#VAR#', 8);

  let containsUppercase = false;
  let containsNumber = false;
  const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  for (let char of input) {
    if (char === char.toUpperCase() && char !== char.toLowerCase()) containsUppercase = true;
    if (!isNaN(char)) containsNumber = true;
  }

  if (!containsUppercase) return translate('mustContainCapital');
  if (!containsNumber) return translate('mustContainNumber');
  if (!specialChars.test(input)) return translate('mustContainSpecialChar');

  return '';
}

/**
 * Required field check
 * @param input
 * @returns {string}
 */
const requiredValidation = (input) => {
  if (input.length === 0) return translate('This field is required.');

  return '';
}

/**
 * Check if numeric
 * @param input
 */
const numericValidation = (input) => {
  const regex = /^\d+$/;

  if (!regex.test(input)) return 'Dit veld moet numeriek zijn.';

  return '';
}
