<template>
  <div class="content">
    <h1 id="cookies">Cookie policy</h1>
    <p>U kan het gebruik van cookies hier afzetten/aanzetten <input type="checkbox" class="ml-2" v-model="allowCookies" /></p>

    <h3>
      Waarom gebruikt MyKafka cookies?
    </h3>

    <ol>
      <li>
        MyKafka gebruikt cookies voor een aangename en vlotte surfervaring en om de inhoud van onze site beter af te
        stemmen op je voorkeuren.
      </li>
      <li>
        Het gebruik van cookies is veilig. Ze slaan geen persoonsgegevens op die je rechtstreeks kunnen identificeren
        (vb. naam, telefoonnummer, e-mailadres).
      </li>
    </ol>

    <h3>
      Wat is een cookie?
    </h3>

    <ol>
      <li>
        Een cookie is een klein tekstbestand dat de website die je bezoekt op je computer of je mobiel apparaat
        plaatst.
      </li>
      <li>
        De cookie bevat een unieke code waardoor de website je browser herkent:
        <ul>
          <li>
            gedurende je bezoek (tijdelijke cookie die verwijderd wordt bij het sluiten van je browser)
          </li>
          <li>
            of bij latere, herhaalde bezoeken (permanente cookie).
          </li>
        </ul>
      </li>
      <li>
        Een website kan alleen de cookies lezen die hij zelf geplaatst heeft. Hij heeft geen toegang tot andere
        informatie op je computer of je mobiel apparaat.
      </li>
    </ol>

    <h3>
      Welke cookies gebruikt MyKafka?
    </h3>

    <h5>Functionele en analytische cookies:</h5>

    <ul>
      <li>
        Functionele cookies zijn noodzakelijk om onze website goed te laten werken (vb. navigeren tussen de
        verschillende delen van de website of formulieren invullen).
      </li>
      <li>
        Analytische cookies helpen om de website aan te passen in functie van een optimaal gebruik van de
        website.
      </li>
    </ul>

    <h5>
      Overzicht van de cookies die we gebruiken:
    </h5>

    <ul>
      <li>
        Session storage cookies: gebruiken we om uw data invoer te vergemakkelijken en verbeteren. We slagen deze
        op voor de duur van de gebruikerssessie.
      </li>
      <li>
        o _gat, _ga, _gid, _gcl_au: we meten het gebruikersbezoek via Google Analytics op geanonimiseerde wijze om
        onze informatie af te stemmen op jullie behoeften. (permanente cookies met een geldigheid van maximaal 2
        jaar).
      </li>
    </ul>

    <h3>Hoe beheer je cookies?</h3>
    <p>
      Via je browserinstellingen kun je de installatie van cookies weigeren. Je kunt ook op elk moment de
      geïnstalleerde cookies van je computer of je mobiel apparaat verwijderen. Dan kan het wel dat sommige delen van onze site niet (goed) werken.
    </p>

    <p>Hoe doe je dat:</p>
    <ul>
      <li><a href="https://support.apple.com/kb/ph19214?locale=en_US">Safari</a></li>
      <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">Google Chrome</a></li>
      <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a></li>
      <li><a href="https://support.microsoft.com/nl-nl/windows/cookies-verwijderen-en-beheren-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft edge</a></li>
      <li><a href="https://support.microsoft.com/en-us/help/260971/description-of-cookies">Internet Explorer</a></li>
    </ul>

    <h3>Mogelijke wijzigingen van ons cookiebeleid</h3>

    <p>
      Je vindt op deze pagina altijd de meest recente versie van ons cookiebeleid.
    </p>
    <p>
      Soms zullen we dat beleid moeten aanpassen. Bijvoorbeeld wanneer de regelgeving of onze website verandert. We
      behouden ons het recht voor om de inhoud van deze verklaring altijd en zonder waarschuwing vooraf te
      wijzigen.
    </p>
  </div>
</template>
<script>

import { inject } from 'vue';
import useCookies from '@/utils/useCookies';

export default {
  name: 'InfoCookiePolicy',
  setup() {
    const gtag = inject('gtag');
    const { allowCookies } = useCookies(gtag);

    return { allowCookies }
  }
};

</script>
<style lang='scss' scoped>
h1 {
  text-decoration: underline;
  padding-bottom: 1rem;
}
</style>
