<template>
  <nav class="navbar is-fixed-top" v-bind:class="{'navbar--scrolled': scrollPos > 10, 'is-absolute': !isScrollable}" :style="backgroundStyle">
    <div class="navbar-brand">
      <router-link to="/" class="logo">
        <img src="../../../../assets/logo-full.svg" alt="MyKafka: Documenten voor verenigingen">
      </router-link>
    </div>
    <div class="navbar-end navbar-menu" v-if="!hideMenu">
      <div class="navbar-item" v-if="!user">
        <router-link to="/login" class="button is-primary is-inverted is-rounded">
          Login
        </router-link>
      </div>
      <div class="navbar-item" v-if="!user">
        <router-link to="/register" class="button is-primary is-inverted is-rounded">
          Registreren
        </router-link>
      </div>
      <div class="navbar-item" v-if="organization && user">
        <router-link :to="{ name: 'Dashboard', params: { slug: organization.slug } }" class="button is-primary is-inverted is-rounded">
          Dashboard
        </router-link>
      </div>
      <div class="navbar-item">
        <router-link to="/" class="button is-primary is-rounded">
          Bestaande verenigingen
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { useStore } from 'vuex';

export default {
  name: 'Navigation',
  props: {
    backgroundColor: {
      type: String,
    },
    hideMenu: {
      default: false,
      type: Boolean,
    },
    isScrollable: {
      default: true,
      type: Boolean,
    }
  },
  computed: {
    backgroundStyle() {
      return this.backgroundColor ? 'background-color: ' + this.backgroundColor : '$white';
    }
  },
  data() {
    return {
      scrollPos: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPos = window.scrollY;
    },
  },
  mounted() {
    if (this.scrollable) {
      window.addEventListener('scroll', this.updateScroll);
    }
  },
  setup() {
    const store = useStore();
    const user = store.getters.GET_USER;
    const organization = store.getters.GET_USER_ORGANIZATION;

    return {
      user,
      organization
    }
  }
};

</script>
<style lang='scss' scoped>

// 1. Navbar
// ---------
.navbar {
  z-index: 100 !important;
  transition: all .3s ease-in-out;

  @include from($desktop) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  // State: Fixed navbar scroll effect
  &--scrolled {
    height: 4rem;
    min-height: 4rem;
    background-color: #ffffff;
    box-shadow: 60px 40px 60px 0 rgba(19, 23, 32, 0.1);
  }
  .logo{
    display: flex;
    align-items: center;

    img{
      width: 150px;
    }
  }

  &.is-absolute {
    position: absolute !important;
  }
}

</style>
