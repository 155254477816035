import FrontEndRoute from '../app/Frontend/router';
import DashboardRoute from './modules/dashboard';
import AuthenticationRoute from '../app/Authentication/router';
import ServicesRoute from '/src/app/Services/router';
import { createWebHistory, createRouter } from "vue-router";
import store from '../store';

const routes = [
  ...FrontEndRoute,
  ...ServicesRoute,
  ...DashboardRoute,
  ...AuthenticationRoute
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPos) {
    window.scrollTo(0, 0)
  }
});

/**
 * Store prev route in store
 * Call guard before every route change
 * Add guard to route with:
 *  meta: {
 *    isRole: true,
 *  }
 */
router.beforeEach((to, from, next) => {
  /**
   * Cache previous route in store
   */
  if (from.name) {
    store.commit('SET_PREVIOUS_ROUTE', from.name);
  }

  /**
   * ROLE_USER
   */
  if (to.matched.some(record => record.meta['ROLE_USER'])) {
    const user = store.getters.GET_USER;
    if (!user) {
      router.push({ name: 'Login' })
    }
  }

  if (to.matched.some(record => record.meta['ACTIVE_ORGANIZATION'])) {
    const organization = store.getters.GET_USER_ORGANIZATION;
    if (!organization) {
      router.push({ name: 'DashboardOrganizationCreate' });
    }
  }

  next();
})

/**
 * Meta tags
 */
router.beforeEach((to, from, next) => {
  // Look for closest route with title (if current route is nested and only parent route has a title attribute, the parent route title will be shown)
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router;
