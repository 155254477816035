<template>
  <div class="wrapper is-fullheight">
    <section class="wrapper__authentication">
      <div class="column is-4-desktop is-6 auth">
        <LoginBox v-if="$route.name === 'Login'"/>
        <RegisterBox v-if="$route.name === 'Register'"/>
        <ConfirmationBox v-if="$route.name === 'Email confirm'"/>
        <FooterSmall />
      </div>
      <div class="column is-hidden-mobile canvas is-flex-grow-1"></div>
    </section>
  </div>
</template>
<script>
/**
 * Authentication layout for login/register
 */
import FooterSmall from '@/app/Services/Components/Navigation/FooterSmall';
import LoginBox from './Components/LoginBox';
import RegisterBox from './Components/RegisterBox';
import ConfirmationBox from './Components/ConfirmationBox';

export default {
  name: "AuthenticationLayout",
  components: { RegisterBox, LoginBox, ConfirmationBox, FooterSmall },
};
</script>

<style scoped lang="scss">
.wrapper {
  &__authentication {
    flex-wrap: wrap;
    background: $white;
    display: flex;
    min-height: 100vh;
  }
}

.auth {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__logo {
    margin: 15px;
    width: 150px;
  }
}

.canvas {
  background: get-brand-color('primary', 'base');
}
</style>
