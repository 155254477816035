import dayjs from 'dayjs';

export const getOrganizationIconById = (typeId) => {
  switch (typeId) {
    case 1:
      return 'campground';
    case 2:
      return 'volleyball-ball';
    case 3:
      return 'guitar';
    case 4:
      return 'user-friends';
  }
}

export const orderObjectArray = (array, property) => {
  function orderFunction(a, b) {
    if (a[property] < b[property]){
      return -1;
    }
    if (a[property] > b[property]){
      return 1;
    }
    return 0;
  }

  return array.sort(orderFunction);
}

export const formatDate = (dateString) => {
  let dateParts = dateString.split('/');

  if (typeof dateParts === 'string') {
    dateParts = dateString.split('-');
  }

  const dateObject = new Date(+dateParts[2], dateParts[1] - 1, dateParts[0]);
  const date = dayjs(dateObject);

  return date.format('MM-DD-YYYY');
}

export const readableDate = (dateString) => {
  if (dateString === null || dateString === undefined) return '';

  return new Date(dateString).toLocaleDateString();
}

export const dateToFormat = (dateString, format) => {
  const dateObject = new Date(dateString);
  const date = dayjs(dateObject);

  return date.format(format);
}
