<template>
  <svg width="50" height="50" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.8 13C19.873 17.564 15.838 21 11 21C5.477 21 1 16.523 1 11C1 5.81505 4.947 1.55105 10 1.05005" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.5 9C5.36739 9 5.24021 8.94732 5.14645 8.85355C5.05268 8.75979 5 8.63261 5 8.5C5 8.36739 5.05268 8.24021 5.14645 8.14645C5.24021 8.05268 5.36739 8 5.5 8C5.63261 8 5.75979 8.05268 5.85355 8.14645C5.94732 8.24021 6 8.36739 6 8.5C6 8.63261 5.94732 8.75979 5.85355 8.85355C5.75979 8.94732 5.63261 9 5.5 9Z" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.5 3C19.3674 3 19.2402 2.94732 19.1464 2.85355C19.0527 2.75979 19 2.63261 19 2.5C19 2.36739 19.0527 2.24021 19.1464 2.14645C19.2402 2.05268 19.3674 2 19.5 2C19.6326 2 19.7598 2.05268 19.8536 2.14645C19.9473 2.24021 20 2.36739 20 2.5C20 2.63261 19.9473 2.75979 19.8536 2.85355C19.7598 2.94732 19.6326 3 19.5 3Z" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 18C10.7348 18 10.4804 17.8946 10.2929 17.7071C10.1054 17.5196 10 17.2652 10 17C10 16.7348 10.1054 16.4804 10.2929 16.2929C10.4804 16.1054 10.7348 16 11 16C11.2652 16 11.5196 16.1054 11.7071 16.2929C11.8946 16.4804 12 16.7348 12 17C12 17.2652 11.8946 17.5196 11.7071 17.7071C11.5196 17.8946 11.2652 18 11 18Z" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 14.01L6.01 13.999" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 14.01L16.01 13.999" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 11.01L10.01 10.999" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20 8.01002L20.01 7.99902" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 5.01002L16.01 4.99902" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.99996 1C9.49996 2.5 10.5 4 12.085 4C9.99996 7.5 12 11 17 10.5C17 13 19.5 13.5 20.7 13.014" stroke="#043388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'CookieIcon',
};
</script>

