<template>
  <div class="content">
    <h1 id="algemene-voorwaarden">Algemene Voorwaarden</h1>

    <h3>1. Uitgave en Hosting</h3>
    <p>
      De website www.MyKafka.be (hierna: &quot;Website&quot;) is gecreëerd en wordt beheerd door  MyKafka feitelijke vereniging (hierna &quot;MyKafka&quot;), met maatschappelijke zetel Paul Gilsonlaan 33 te Edegem.
    </p>

    <h3>2. Algemeen</h3>
    <ol>
      <li>Toegang tot en gebruik van de Website worden geregeld door de huidige gebruiksvoorwaarden (hierna &quot;Voorwaarden&quot;), de privacyverklaring  alsook door de toepasselijke wet- en regelgeving. Toegang tot en gebruik van de Website veronderstelt de volledige aanvaarding van de Voorwaarden door de Gebruiker, zonder enig voorbehoud en bij uitsluiting van alle andere voorwaarden.</li>
      <li>MyKafka behoudt zich het recht voor om de Voorwaarden, de toegang tot de Website en diens inhoud te allen tijde en zonder voorafgaande verwittiging te wijzigen. Deze wijzigingen zijn tegenstelbaar aan de Gebruiker bij elke toegang tot de Website. MyKafka beveelt de Gebruikers dan ook aan om de Voorwaarden regelmatig te raadplegen.</li>
      <li>&quot;Gebruiker&quot; in deze Voorwaarden en de privacyverklaring, is elke persoon die de Website of aan de daarop aangeboden diensten gebruikt.</li>
      <li>Indien de Gebruiker contact wenst op te nemen met MyKafka betreffende het gebruik van de Website, kan deze <a href="https://www.mykafka/contact">hier</a> klikken of een email te sturen naar <a href="mailto:Contact@MyKafka.be">Contact@MyKafka.be</a>.</li>
      <li>Het feit dat MyKafka zich op een gegeven moment niet beroept op een bepaling van de Voorwaarden, zal niet aanschouwd kunnen worden als een verzaking om zich later te beroepen op die bepaling.</li>
    </ol>

    <h3>3. Inhoud en informatie</h3>
    <ol>
      <li>Deze Website is bedoeld als interface tussen de Gebruiker en de vereniging waarvan hij deel uitmaakt met als uitsluitende bedoeling administratieve documenten ten behoeve van de Gebruiker te verstrekken in verband met zijn lidmaatschap van de vereniging. De informatie en gegevens verstrekt via de Website zijn gebaseerd op de gegevens verstrekt door de vereniging.</li>
      <li>Gelet op het voorgaande wijst MyKafka dan ook elke aansprakelijkheid van de hand in geval van eventuele fouten in de door de vereniging verstrekte gegevens.</li>
      <li>De Gebruikers zijn zelf volledig en uitsluitend verantwoordelijk voor ieder gebruik dat zij van de via de Website verkregen informatie en verbinden zich ertoe MyKafka te vrijwaren voor iedere vordering van derden in verband met dit gebruik.</li>
    </ol>

    <h3>4. Intellectuele Eigendom</h3>
    <ol>
      <li>De Website en alle daarmee verbonden rechten, merken, logo&#39;s, design, beelden, animaties, video&#39;s, tekst en andere tekens en inhoud op de Website zijn de exclusieve eigendom van MyKafka. Deze elementen betreffen creaties die beschermd zijn door intellectuele eigendomsrechten en meer bepaald door het auteursrecht, tekeningen- en modellenrecht en merkenrecht. Zij mogen bijgevolg noch geheel, noch gedeeltelijk worden gereproduceerd, gebruikt of afgebeeld, zonder de voorafgaande, schriftelijke toestemming van MyKafka. Gerechtelijke procedures kunnen worden ingezet. Elke niet-toegelaten reproductie van deze elementen houdt namaak in en kan strafrechtelijk vervolgd worden.</li>
      <li>Afdrukken van pagina&#39;s is enkel toegelaten voor privégebruik.</li>
    </ol>

    <h3>5. Hyperlinks</h3>

    <h5>A. Hyperlinks naar de Website</h5>
    <ol>
      <li>Het aanmaken van een hyperlink naar de homepage van de Website, bij uitsluiting van elke andere URL, is slechts toegelaten voor zover zulks geen schade toebrengt aan MyKafka en behoudens tegenbericht van MyKafka. Het aanmaken van hyperlinks naar pagina&#39;s binnen de Website is verboden, behoudens uitdrukkelijk en voorafgaand akkoord van MyKafka.</li>
      <li>MyKafka kan in geen geval aansprakelijk worden gesteld voor de inhoud van websites waarop hyperlinks of gelijk welke andere links naar de Website worden geplaatst.</li>
    </ol>

    <h5>B. Hyperlinks op de Website</h5>
    <ol>
      <li>De Website kan een of meerdere links bevatten naar andere websites. Deze links worden louter informatief op de Website geplaatst en MyKafka biedt geen enkele garantie. De aanwezigheid van links houdt geen enkel akkoord of goedkeuring in vanwege MyKafka met betrekking tot de inhoud of gebruik van deze andere websites.</li>
    </ol>

    <h3>6. Beperking van aansprakelijkheid</h3>
    <ol>
      <li>De Gebruikers gebruiken de Website op hun verantwoordelijkheid. MyKafka kan niet aansprakelijk gesteld worden voor schade die voortvloeit uit het rechtstreeks of onrechtstreeks gebruik van de Website door de Gebruikers in overtreding met deze Voorwaarden. MyKafka wijst dienaangaande elke aansprakelijkheid van de hand en biedt geen enkele garantie omtrent de op de Website aangeboden diensten.
        De Gebruiker verbindt zich ertoe de Website niet te gebruiken op een wijze die schade zou kunnen berokkenen aan derden of aan MyKafka, meer bepaald door invoeging van valse of onterende informatie.</li>
      <li>MyKafka kan evenmin de voortdurende, foutloze en ononderbroken beschikbaarheid van de Website en diens functies garanderen, noch de onmiddellijke verbetering van fouten, noch de volledige afwezigheid van virussen of enig ander schadelijk element op de Website en op de infrastructuur waar de Website op wordt aangeboden.
        Technische problemen zoals onderbrekingen op het netwerk, programmeerfouten, hardware problemen, etc. kunnen tijdelijke restricties noodzaken. MyKafka behoudt zich het recht voor om de beschikbaarheid van de Website te allen tijde te onderbreken voor onderhoud en updates.
        MyKafka kan niet aansprakelijk worden gesteld voor elke verlies of schade (direct, indirect, materieel of immaterieel) geleden door deze tijdelijke restricties, noch in verband met het gebruik van de Website of de onmogelijkheid om deze te gebruiken.</li>
      <li>De Gebruikers zijn verantwoordelijk voor het actualiseren van hun hardware en software, alsook de toegang tot het internet. De Gebruikers dienen hun apparatuur te beschermen tegen elke vorm van aanval of besmetting door virussen en malware en/of pogingen tot hacking. MyKafka wijst dienaangaande alle aansprakelijkheid van de hand.</li>
    </ol>

    <h3>7. Nietigheid</h3>
    <ol>
      <li>De nietigheid, verval of onuitvoerbaarheid van geheel of een deel van de bepalingen van deze Voorwaarden leidt in geen geval tot de nietigheid van het geheel van de Voorwaarden. De geheel of gedeeltelijk nietige, vervallen of onuitvoerbare bepaling zal als ongeschreven worden beschouwd. MyKafka zal deze bepaling vervangen door een andere bepaling die in de mate van het mogelijke hetzelfde resultaat beoogt.</li>
    </ol>

    <h3>8. Toepasselijk recht en Bevoegde rechtbanken</h3>
    <ol>
      <li>
        De geldigheid, interpretatie en uitvoering van deze Voorwaarden worden geregeld door Belgisch recht en zullen worden voorgelegd aan de bevoegde rechtbanken te Antwerpen.
        Elk geschil met betrekking tot de Website en het gebruik daarvan wordt uitsluitend geregeld door het Belgisch recht. Enkel de rechtbanken te Antwerpen zijn bevoegd in geval van een geschil.
      </li>
    </ol>
  </div>
</template>
<script>

export default {
  name: 'InfoTermsOfService',
};

</script>
<style lang='scss' scoped>
h1 {
  text-decoration: underline;
  padding-bottom: 1rem;
}
</style>
