<template>
  <BaseDashboardLayout page-title="Certificaat instellingen">
    <template v-slot:hero>
      <HeroWrapper size="" type="light">
        <template v-slot:content>
          <h3 class="title is-3">
            Upload je organisatie handtekening en zegel
          </h3>
          <p class="subtitle">
            Een attest is pas geldig als de handtekening van een verantwoordelijke bevat en voorzien is van een stempel. Je kan hier beide uploaden.
          </p>
        </template>
      </HeroWrapper>
    </template>
    <template v-slot:content>
      <div class="notification is-success is-light" v-if="successMessage">
        <p v-html="successMessage"></p>
      </div>
      <section class="certificate-settings mb-5">
          <div class="notification is-danger is-light" v-if="errorMessage">
            <p v-html="errorMessage"></p>
          </div>
          <div class="columns">
            <div class="column">
              <div class="certificate-settings__box">
                <h3 class="title is-5">
                  Handtekening
                </h3>
                <div class="file has-name is-primary member-import__input">
                  <label class="file-label">
                    <input class="file-input" type="file" name="signature" @change="handleFileUpload($event, 'signature')" ref="signatureFile">
                    <span class="file-cta">
                      <span class="file-icon">
                        <font-awesome-icon icon="upload" class="option__text--icon"/>
                      </span>
                      <span class="file-label">
                        Bestand
                      </span>
                    </span>
                    <span class="file-name">
                      {{ fileNames.signatureFileName ? fileNames.signatureFileName : '...' }}
                    </span>
                  </label>
                  <button v-if="isBusy && isLoading['signature']" class="button button__spinner is-outlined is-primary is-loading">Loading</button>
                </div>
                <button class="button is-primary mt-5" @click="handleDownload('signature')">Download</button>
              </div>
            </div>
            <div class="column">
              <div class="certificate-settings__box">
                <h3 class="title is-5">
                  Stempel
                </h3>
                <div class="file has-name is-primary member-import__input">
                  <label class="file-label">
                    <input class="file-input" type="file" name="signature" @change="handleFileUpload($event, 'stamp')" ref="signatureFile">
                    <span class="file-cta">
                    <span class="file-icon">
                      <font-awesome-icon icon="upload" class="option__text--icon"/>
                    </span>
                    <span class="file-label">
                      Bestand
                    </span>
                  </span>
                    <span class="file-name">
                      {{ fileNames.stampFileName ? fileNames.stampFileName : '...' }}
                  </span>
                  </label>
                  <button v-if="isBusy && isLoading['stamp']" class="button button__spinner is-outlined is-primary is-loading">Loading</button>
                </div>
                <button class="button is-primary mt-5" @click="handleDownload('stamp')">Download</button>
              </div>
            </div>
          </div>
      </section>
    </template>
  </BaseDashboardLayout>
</template>

<script>
import BaseDashboardLayout from '../layouts/BaseDashboardLayout';
import HeroWrapper from '../components/ui/HeroWrapper';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { reactive } from '@vue/reactivity';

export default {
  name: 'DashboardCertificateSettings',
  components: {
    HeroWrapper,
    BaseDashboardLayout,
  },
  data() {
    return {};
  },
  computed: {
    isBusy() {
      return this.$store.getters.GET_BUSY;
    }
  },
  setup() {
    const store = useStore();
    const errorMessage = ref('');
    const successMessage = ref('');
    const fileNames = reactive({ stampFileName: '', signatureFileName: '', });
    const isLoading = reactive({ stamp: false, signature: false, });
    const organization = store.getters.GET_USER_ORGANIZATION;

    const handleFileUpload = (event, type) => {
      const file = event.target.files[0];
      const formData = new FormData();
      const endpoint = (type === 'signature') ? 'updateAssociationSignature' : 'updateAssociationStamp';
      successMessage.value = '';
      store.commit('SET_BUSY', true);
      isLoading[type] = true;

      // formData.append("filename", file.name);
      formData.append("content", file);

      store.dispatch(endpoint, { data: formData, id: organization.id })
        .then(() => {
          successMessage.value = 'Gelukt, je bestand is goed aangekomen!';
        })
        .catch((error) => {
          errorMessage.value = error;
        })
        .finally(() => {
          isLoading[type] = false;
          store.commit('SET_BUSY', false);
        })
    }

    const handleDownload = (type) => {
      const endpoint = (type === 'signature') ? 'downloadAssociationSignature' : 'downloadAssociationStamp';

      store.dispatch(endpoint, organization.id)
        .catch((error) => {
          console.debug(error);
          errorMessage.value = error;
        });
    }

    return {
      errorMessage,
      fileNames,
      isLoading,
      successMessage,
      handleFileUpload,
      handleDownload
    };
  }
};
</script>

<style   lang="scss">
.certificate-settings {
  .columns > .column:first-child{
    background: $success-light;
  }

  .columns > .column:last-child{
    background: $warning-light;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
    padding: 25px;
  }
}
</style>
