<template>
  <div class="content__steps">
    <div v-bind:class="(activeStep === 1)?'content__steps--item active':'content__steps--item clickable'" @click="goToRoute('OrganizationSelect')">
      <div class="amount">1</div>
      <p class="is-hidden-mobile">Organizatie selecteren</p>
      <p class="is-hidden-desktop" v-if="activeStep === 1">Organizatie</p>
    </div>
    <div v-bind:class="(activeStep === 2)?'content__steps--item active':'content__steps--item'" v-if="activeStep !==3">
      <div class="amount">2</div>
      <p class="is-hidden-mobile">Aanvragen aanmaken</p>
      <p class="is-hidden-desktop" v-if="activeStep === 2">Aanvragen</p>
    </div>
    <div class="content__steps--item clickable" v-else @click="goStepBack()">
      <div class="amount">2</div>
      <p class="is-hidden-mobile">Aanvragen aanmaken</p>
      <p class="is-hidden-desktop" v-if="activeStep === 2">Aanvragen</p>
    </div>
    <div v-bind:class="(activeStep === 3)?'content__steps--item active':'content__steps--item'">
      <div class="amount">3</div>
      <p class="is-hidden-mobile">Attesten downloaden</p>
      <p class="is-hidden-desktop" v-if="activeStep === 3">Downloaden</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormSteps',
  emits: ['goBack'],
  props: [
    'activeStep',
  ],
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route })
    },
    goStepBack() {
      this.$emit('goBack');
    }
  }
};

</script>
<style lang='scss' scoped>
.content__steps {
  display: flex;
  padding: $global-padding $global-padding $global-padding 0;
  margin-bottom: 20px;

  @include lg {
    margin-bottom: 45px;
  }

  &--item.active {
    .amount {
      background: get-brand-color('primary', 'base');
      color: #fff;
    }

    p {
      color: get-brand-color('primary', 'dark');
      display: block;
    }
  }

  &--item {
    display: flex;
    padding: 0 1.5rem;
    border-right: 0.7px solid #E5E8F2;

    .amount {
      border-radius: 0.9em;
      text-align: center;
      background: get-gray-color('base');
      color: get-gray-color('light');
      font-weight: 600;
      line-height: 1.8em;
      width: 1.8em;

    }
    p {
      display: none;
      align-self: center;
      margin-left: $global-padding;
      color: get-gray-color('base');

      @include lg {
        display: block;
      }

    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border: none;
    }
  }
  .clickable {
    cursor: pointer;
  }
}
</style>
