<template>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link :to="{ name: 'Dashboard', params: { slug: organization.slug } }" class="navbar-item" v-if="organization">
        <img src="../../assets/logo-full.svg" alt="MyKafka: Documenten voor verenigingen">
      </router-link>
      <router-link :to="{ name: 'Home' }" class="navbar-item" v-else>
        <img src="../../assets/logo-full.svg" alt="MyKafka: Documenten voor verenigingen">
      </router-link>
      <a role="button" class="navbar-burger burger" :class="(isMobile) ? 'is-active' : ''" aria-label="menu" aria-expanded="false" data-target="navbar-main" v-on:click="toggleMobile">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbar-main" class="navbar-menu">
      <div class="navbar-start">
        <h3 class="title is-4 has-text-weight-light">{{ pageTitle }}</h3>
      </div>
      <div class="navbar-end" v-if="organization">
        <div class="navbar-item">
          <LaunchPanel />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LaunchPanel from '../ui/LaunchPanel';
import NavbarMobile from './NavbarMobile';

export default {
  name: 'Navbar',
  components: { NavbarMobile, LaunchPanel },
  props: {
    pageTitle: {
      type: String,
      default: ''
    },
    organization: {
      type: [Object, Boolean],
      default: {}
    }
  },
  data() {
    return {
      isMobile: false,
    }
  },
  methods: {
    toggleMobile() {
      this.isMobile = !this.isMobile;
      this.$emit('toggleIsMobile');
    }
  }
};
</script>

<style scoped lang="scss">
.navbar {
  &-brand {
    @include lg {
      width: $sidebar-width;
      border-right: 1px solid darken(get-gray-color('light'), 10%);
    }

    .navbar-item {
      width: calc(250px - 2.5rem);
      max-width: inherit;
      padding-left: 1.25rem;
    }

    img {
      max-height: 2.5rem;
    }
  }

  &-menu {
    padding-left: 3rem;
    border-bottom: 1px solid darken(get-gray-color('light'), 10%);
  }

  &-start {
    display: flex;
    align-items: center;
  }

  &-burger:hover {
    background: none;
  }
}
</style>
