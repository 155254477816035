<template>
  <div class="modal is-active">
    <div class="modal-background" v-on:click="$emit('closeModal')"></div>
    <div class="modal-card modal__body">
      <article class="message is-warning">
        <div class="message-body">
          <p class="title is-5">Opgelet!</p>
          <p>
            Bij het veranderen van een organizatie verlies je alle ingevulde aanvragen. Ben je zeker dat je van organizatie wilt veranderen?
          </p>
          <div class="field is-grouped mt-5">
            <button type="submit" class="button is-success" value="Submit" v-on:click="$emit('confirmOrganizationChange')">Doorgaan</button>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfirmDeleteData',
  emits: ['closeModal', 'confirmOrganizationChange'],
};
</script>
