<template>
  <svg width="117" height="109" viewBox="0 0 117 109" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
    <path class="svg--path" d="M103.995 0.666656C84.69 0.666656 58.806 12.925 41.418 34H27C16.587 34 10.62 41.2 6.507 48.8083L0.989998 59H27L40.5 71.5L54 84V108.075L65.007 102.975C73.224 99.175 81 93.6417 81 84V70.65C103.761 54.55 117 30.575 117 12.7083V0.666656H103.995ZM81 25.6667C83.3869 25.6667 85.6761 26.5446 87.364 28.1074C89.0518 29.6702 90 31.7899 90 34C90 36.2101 89.0518 38.3297 87.364 39.8925C85.6761 41.4553 83.3869 42.3333 81 42.3333C78.6131 42.3333 76.3239 41.4553 74.636 39.8925C72.9482 38.3297 72 36.2101 72 34C72 31.7899 72.9482 29.6702 74.636 28.1074C76.3239 26.5446 78.6131 25.6667 81 25.6667ZM22.5 75.6667L18 79.8333C11.502 85.85 9 100.667 9 100.667C9 100.667 24.282 99.0167 31.5 92.3333L36 88.1667L22.5 75.6667Z" fill="#ffffff"/>
  </svg>
</template>

<script>
export default {
  name: 'RocketIcon'
};
</script>
