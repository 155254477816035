<template>
  <svg width="68" height="45" viewBox="0 0 68 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="15.5455" y="7.36365" width="52.3636" height="37.6364" rx="10" fill="#E5E8F2"/>
    <rect x="0.818176" width="52.3636" height="37.6364" rx="10" fill="#A5AAB5"/>
    <path d="M27.9947 17.2222H32.6334V19.9321H27.9947V25.2178H25.1139V19.9321H20.4508V17.2222H25.1139V12.2783H27.9947V17.2222Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'RequestIcon.vue',
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
</style>
