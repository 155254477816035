<template>
  <footer class="footer">
    <p class="footer__legal">
      ©{{ new Date().getFullYear() }} MyKafka.
      <router-link to='/info/data-processing-agreement' target="_blank">Verwerkingsovereenkomst</router-link>,
      <router-link to='/info/privacy' target="_blank">Privacybeleid</router-link>,
      <router-link to='/info/terms-of-use' target="_blank">Algemene voorwaarden</router-link>
      en
      <router-link to='/info/cookies' target="_blank">Cookie-policy</router-link>.
    </p>
  </footer>
</template>

<script>
export default {
  name: 'FooterSmall'
};
</script>

<style lang="scss" scoped>
.footer {
  background: none;
  padding: 0 .5rem;
  font-size: .9rem;
  &__legal{
    color: get-gray-color('base');

    a {
      text-decoration: underline;
      color: get-gray-color('base');

      &:hover {
        color: get-brand-color('primary', 'base');
      }
    }
    & a:hover {

    }
  }
}
</style>
