<template>
  <div class="cookie-banner box">
    <div class="cookie-banner__icon">
      <CookieIcon />
    </div>
    <p class="cookie-banner__info">
      We gebruiken externe cookies voor het optimalizeren van onze werking en het perfectionaliseren uw ervaring.
    </p>
    <div class="cookie-banner__buttons field is-grouped">
      <button @click="approveCookies()" class="button is-primary mr-1">OK</button>
      <router-link to='/info/cookies' class="button is-outlined is-primary">
        Cookie-policy
      </router-link>
    </div>
  </div>
</template>
<script>
import CookieIcon from '@/app/Shared/Icons/CookieIcon';

export default {
  name: 'CookieBanner',
  emits: ['approveCookies'],
  components: { CookieIcon },
  methods: {
    approveCookies() {
      this.$emit('approveCookies');
    }
  }
};
</script>

<style lang="scss" scoped>
.cookie-banner {
  box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: fixed;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -15%);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
  z-index: 9999;
  @include lg {
    max-width: 800px;
    flex-direction: row;
  }

  &__icon {
    margin: 1rem;
    svg {
      height: 100%;
    }
  }

  &__info {
    color: #031b4e;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 1rem 3rem 1rem;
    text-align: center;

    @include lg {
      text-align: left;
      margin: 0 3rem 0 1rem;
    }
  }
}
</style>
