<template>
  <div class="text-container">
    <div class="text-container__title">
      <slot name="title"/>
    </div>
    <div class="text-container__body">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextContainer',
};
</script>

<style scoped lang="scss">
.text-container {
  &__title,
  &__body {
    margin-bottom: 2rem;
  }
}
</style>
