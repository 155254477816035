<template>
  <BaseDashboardLayout page-title="Leden import">
    <template v-slot:hero>
      <HeroWrapper size="" type="light">
        <template v-slot:content>
          <h3 class="title is-3">
            Update je ledenlijst via .csv
          </h3>
          <p class="subtitle">
            Om jullie ledendata goed te kunnen inlezen in MyKafka van we jullie gebruik te maken van een CSVfile (comma-separated values). Dit type file kan je simpel maken vanuit iedere excel.
          </p>
          <p class="subtitle">
            Download uw groepsdata uit de applicatie naar excel en converteer het naar CSV. <a
            href="https://support.microsoft.com/en-us/office/save-a-workbook-to-text-format-txt-or-csv-3e9a9d6c-70da-4255-aa28-fcacf1f081e6" target="_blank" rel="nofollow">Hier</a> vindt u een handleiding rond Excels omzetten naar CSV.
          </p>
          <p class="subtitle">
            Let op: Elke CSV moet minimaal zeven kolommen bevatten.  Na de upload zal immers gevraagd worden om aan te geven welke kolom de Tak, Voornaam, Achternaam, Geboortedatum, Lidgeld betaald, Bedrag verminderd lidmaatschap & Kamp deelname bevat.
            Het kan dus zijn dat u de export uit uw ledenbeheer-applicatie moet aanvullen met bijkomende data.
          </p>
          <p class="subtitle">
            Nadat de file opgeladen is vragen we om de velden te mappen. Dit doe je om per veld (wat overeen komt met een kolom in jullie excel) te benoemen met waarde (tweede kolom). Een preview van hoe wij da data opnemen kan je zien onder punt 3.
          </p>
          <p class="subtitle">
            Indien alles juist gemapt is klik je bij punt 4 op "Uploaden".
          </p>
        </template>
      </HeroWrapper>
    </template>
    <template v-slot:content>
      <section class="import-info">
        <div class="notification mb-5">
          Gelieve datums in volgend formaat door te geven: dag/maand/jaar (20/09/2022)
        </div>
      </section>
      <section class="member-import mb-5">
        <div class="notification is-danger is-light" v-if="errorMessage">
          <p v-html="errorMessage"></p>
        </div>
        <div class="notification is-success is-light" v-if="successMessage">
          <p v-html="successMessage"></p>
        </div>
        <vue-csv-import v-model="csvFile" :fields="csvHeaders" :text="csvText">
          <h3 class="title is-5">
            1. Upload je bestand
          </h3>
          <div class="file has-name is-primary member-import__input">
            <label class="file-label">
              <vue-csv-input class="file-input" @change="getFilename($event)"></vue-csv-input>
              <span class="file-cta">
                <span class="file-icon">
                  <font-awesome-icon icon="upload" class="option__text--icon"/>
                </span>
                <span class="file-label">
                  Bestand
                </span>
              </span>
              <span class="file-name">
                {{ csvFilename }}
              </span>
            </label>
          </div>

          <vue-csv-errors
            class="member-import__errors notification is-danger is-light is-block"></vue-csv-errors>

          <div class="member-import__map">
            <h3 class="title is-5" v-if="showMappingText">
              2. Match volgende velden met de juiste kolommen
            </h3>
            <vue-csv-map class="table is-striped is-bordered" :select-attributes="mapSelectAttributes"></vue-csv-map>
          </div>

          <div class="member-import__example" v-if="csvFile">
            <h3 class="title is-5" v-if="showMappingText">
              3. Preview
            </h3>
            <table class="table is-striped is-bordered">
              <thead>
              <tr>
                <th v-for="(value, key) in csvFile[1]">
                  {{ getHeaderName(key) }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="data in csvFile.slice(1,4)">
                <td v-for="(row, key) in data">
                  <template v-if="key === 'campFeePaid' || key === 'memberFeePaid'">
                    <span v-if="handleBooleans(row)">&check;</span>
                  </template>
                  <template v-else>
                    {{ row }}
                  </template>
                </td>
              </tr>
              <tr>
                <td :colspan="Object.keys(csvHeaders).length" class="has-text-centered">
                  ...
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="member-import__submit" v-if="csvFile">
            <h3 class="title is-5" v-if="showMappingText">
              4. Correcte preview?
            </h3>
            <button class="button is-primary" :class="isBusy ? 'is-loading' : ''" v-on:click="submit(csvFile)">Uploaden</button>
          </div>
        </vue-csv-import>
      </section>
    </template>
  </BaseDashboardLayout>
</template>

<script>
import BaseDashboardLayout from '../layouts/BaseDashboardLayout';
import HeroWrapper from '../components/ui/HeroWrapper';
import { VueCsvErrors, VueCsvImport, VueCsvInput, VueCsvMap } from 'vue-csv-import';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { formatDate } from '@/utils/utils';

export default {
  name: 'DashboardMemberImport',
  components: {
    HeroWrapper,
    BaseDashboardLayout,
    VueCsvImport,
    VueCsvInput,
    VueCsvMap,
    VueCsvErrors
  },
  computed: {
    getColSpan() {
      return this.csvHeaders.length;
    }
  },
  methods: {
    getFilename(event) {
      const fileData = event.target.files[0];

      this.csvFilename = fileData ? fileData.name : '';
      this.showMappingText = true;
    },
  },
  data() {
    return {
      csvFile: null,
      csvFilename: '...',
      mapSelectAttributes: {
        class: 'map-select',
      },
      showMappingText: false
    };
  },
  setup() {
    const store = useStore();
    const csvHeaders = {
      associationFederationUnit: {
        required: true,
        label: 'Functies / Tak'
      },
      firstName: {
        required: true,
        label: 'Voornaam'
      },
      lastName: {
        required: true,
        label: 'Achternaam'
      },
      birthDate: {
        required: true,
        label: 'Geboortedatum'
      },
      memberFeePaid: {
        required: true,
        label: 'Lidgeld betaald'
      },
      campFeePaid: {
        required: true,
        label: 'Kampgeld betaald'
      },
      socialFee: {
        required: true,
        label: 'Bedrag verminderd lidmaatschap'
      },
    };
    const csvText = {
      errors: {
        fileRequired: 'Geen bestand gevonden.',
        invalidMimeType: 'Dit type bestand is niet toegestaan.'
      },
      toggleHeaders: 'Bestand heeft headers',
      submitBtn: 'Uploaden',
      fieldColumn: 'Veld',
      csvColumn: 'Waarde'
    };
    const errorMessage = ref(null);
    const successMessage = ref(null);
    const isBusy = ref(false);

    function getHeaderName(key) {
      return csvHeaders[key]['label'];
    }

    function handleBooleans(value) {
      if (typeof value === 'boolean') return value;
      if (typeof value === 'number' && value !== 0) return true;
      if (typeof value === 'string') {
        if (value.toLowerCase() === 'true' || value.toLowerCase() === 'ja' || value.toLowerCase() === 'x') {
          return true;
        }

        if (parseInt(value) && parseInt(value) > 0) {
          return true;
        }
      }
      return false;
    }

    function submit(csvFile) {
      isBusy.value = true;
      errorMessage.value = null;
      const organization = store.getters.GET_USER_ORGANIZATION;
      const validationErrors = {
        birthDate: []
      };
      const payload = [];

      // Sanitize json
      for (const [key, row] of Object.entries(csvFile)) {
        // Skip headers & empty rows
        if (row['firstName'] === '' || key === '0') {
          // delete csvFile[key];
          continue;
        }

        // Handle booleans
        if (typeof row['socialFee'] === 'string') {
          row['socialFee'] = handleBooleans(row['socialFee']);
        }
        if (typeof row['campFeePaid'] === 'string') {
          row['campFeePaid'] = handleBooleans(row['campFeePaid']);
        }
        if (typeof row['memberFeePaid'] === 'string') {
          row['memberFeePaid'] = handleBooleans(row['memberFeePaid']);
        }

        // Handle date
        csvFile[key]['birthDate'] = formatDate(csvFile[key]['birthDate'])
        if (csvFile[key]['birthDate'] === 'Invalid Date') {
          validationErrors.birthDate.push(parseInt(key) + 1);
        }

        payload.push(row);
      }

      if (validationErrors.birthDate.length > 0) {
        errorMessage.value = 'Geen geldige geboortedatum gevonden voor volgende rij(en): ' + validationErrors.birthDate.toString();
        isBusy.value = false;
        window.scrollTo(0, 0);
        return null;
      }

      const data = {
        'id': organization.id,
        'data': payload
      };

      store.dispatch('importMembers', data)
        .then((response) => {
          if (response) {
            successMessage.value = 'Gelukt, je import is goed aangekomen!';
            window.scrollTo(0, 0);
          }
        })
        .catch(
          (errorString) => {
            console.debug(errorString);
            errorMessage.value = errorString;
            window.scrollTo(0, 0);
          }
        )
        .finally(function () {
          isBusy.value = false;
        });
    }

    return {
      csvHeaders,
      csvText,
      errorMessage,
      successMessage,
      isBusy,
      handleBooleans,
      submit,
      getHeaderName
    };
  }
};
</script>

<style scoped lang="scss">
.member-import {
  &__input,
  &__map,
  &__example,
  &__errors {
    margin-bottom: 3rem;
  }

  &__example {
    .table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}

.member-import /deep/ .map-select {
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #363636;
  height: 2.5em;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  position: relative;
  font-size: 1rem;
}
</style>
