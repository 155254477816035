<template>
  <div class="wrapper">
    <div class="main">
      <div class="container columns">
        <div class="column is-12-mobile">
          <div class="body">
            <router-view :persons="testPersons" />
            <a href="/" class="button mt-2 is-white"><font-awesome-icon icon="arrow-left" class="mr-2" />Terug naar startpagina.</a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/app/Services/Components/Navigation/Footer.vue';
import Navigation from '@/app/Services/Components/Navigation/Navigation.vue';

/**
 * Layout services
 */
export default {
  name: 'ServicesLayout',
  components: {
    Footer,
    Navigation
  },
};
</script>

<style scoped lang="scss">

.wrapper {
  padding-top: 5rem;

  .container {
    margin-top: 4rem;
  }

  .footer {
    align-self: flex-end;
    justify-self: flex-end;
    width: 100%;
    margin-top: auto;
  }
}
</style>