<template>
  <div class="field">
    <label class="checkbox">
      <input
        type="checkbox"
        v-bind="$attrs"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxType',
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      default: false
    },
  }
};
</script>

<style scoped>

</style>
