import { getOGMetaTags } from '../../utils/metaTags';
import AuthenticationLayout from './AuthenticationLayout';
import Homepage from '../Services/Homepage';
import store from '@/store';
import { clearLocalStorageVariable } from '@/helpers/localStorageHandler';

export default [
  {
    path: '/login',
    component: AuthenticationLayout,
    name: 'Login',
    meta: getOGMetaTags('Mykafka - Login', null),
  },
  {
    path: '/register',
    component: AuthenticationLayout,
    name: 'Register',
    meta: getOGMetaTags('Mykafka - Aanmelden', 'Start nog vandaag met het digitaliseren van je vereniging. Schrijf je vrijblijvend in op onze wachtlijst en wij nemen zo snel mogelijk contact met je op.'),
  },
  {
    path: '/account/confirm',
    component: AuthenticationLayout,
    name: 'Email confirm',
    meta: getOGMetaTags('Mykafka - Bevestigen', 'Start nog vandaag met het digitaliseren van je vereniging. Schrijf je vrijblijvend in op onze wachtlijst en wij nemen zo snel mogelijk contact met je op.'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Homepage,
    redirect: (to, from, next) => {
      store.commit('CLEAR_USER');
      clearLocalStorageVariable('user');
      clearLocalStorageVariable('organization');
      return { name: 'Home' }
    }
  }
];
